import React from "react";
import HkvBadge from "../assets/images/HKP Badge.png";
export const UIDErrorPage = () => {
  return (
    <div className="productfakeAlert">
      <div className="fakeAlertSubDIV">
        <div className="fakealDiv">
          <img src={HkvBadge} alt="Fake Alert" />
        </div>
        <div className="fakealDiv2">
          <h3>
            Currently this chip is not associated- Kindly connect this with a
            product from HastKala product page.
          </h3>
        </div>
      </div>
    </div>
  );
};
