import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import Loader from "../../common/Components/Loader/Loader";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
import { Link } from "react-router-dom";
const LoginForm = (props: any) => {
  const {
    handleOnChange,
    Errors,
    value,
    handleSubmit,
    showSignUpForm,
    loading,
    handleChangedChecked,
    remember,
    response,
  } = props;
  const [show, setShow]: any = useState(false);
  const handleSetShow = (value: any) => {
    setShow(value);
  };
  return (
    <>
      {loading ? (
        <div className="LoaderDiv1">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="parentForm">
            <Link to="/home">
              <div className="MainHeading">
                <span className="sp1">Hastkala</span>{" "}
                <span className="sp2">Paramanak</span>
                <p>Supporting the makers, Protecting the buyers</p>
              </div>
            </Link>

            <h4>Welcome back 👋</h4>
            <div className="enterEmail">
              Enter your email or phone and password to login.
            </div>
            {response === "User does not   Exist!" ? (
              <>
                <div className="responseMsg">
                  Incorrect email or phone number or User does not exist's
                </div>
              </>
            ) : response === "Authentication failed!" ? (
              <>
                <div className="responseMsg">Incorrect password</div>
              </>
            ) : (
              <>
                <div className="responseMsgSucces">{response}</div>
              </>
            )}
            <label className="labelName">
              Email Or Phone<span>*</span>
            </label>
            <br />
            <input
              placeholder="Enter Email Or Phone"
              type="text"
              id="emaillogin"
              name="emaillogin"
              className="inputField"
              value={value?.email}
              onChange={(e: any) =>
                handleOnChange({
                  name: "email",
                  value: e.target.value,
                })
              }
            />
            <br />
            {Errors.email && !value.email ? (
              <p className="RequiredField">{Errors?.email} </p>
            ) : (
              ""
            )}
            <br />
            <label className="labelName">
              Password<span>*</span>
            </label>
            <br />
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                placeholder="Enter Password"
                type={show === true ? "text" : "password"}
                id="pswd"
                value={value?.password}
                name="pswd"
                className="inputField"
                onChange={(e: any) =>
                  handleOnChange({
                    name: "password",
                    value: e.target.value,
                  })
                }
              />
              <div className="icon">
                {show ? (
                  <HideIcon onClick={() => handleSetShow(false)} />
                ) : (
                  <ShowIcon onClick={() => handleSetShow(true)} />
                )}
              </div>
            </div>
            {Errors.password && !value.password ? (
              <p className="RequiredField">{Errors?.password} </p>
            ) : (
              ""
            )}
            <div className="mt-2 d-flex justify-content-between">
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  checked={remember === true || remember === "true"}
                  onChange={(e: any) => {
                    handleChangedChecked(e.target.checked);
                  }}
                />
                <Label check>Remember me</Label>
              </FormGroup>
              <span className="forgetPswd cursor-pointer">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_DEV_URL}/forget-password`}
                >
                  Forget password
                </a>
              </span>
            </div>
            <Button className="loginBtn" onClick={() => handleSubmit()}>
              {loading ? "Proccessing" : "Login"}
            </Button>
            <div className="RegDiv">
              <span onClick={() => showSignUpForm()}>
                {/* Want to Create New account? Please Sign up */}
              </span>
              {/* <span className="forgetPswd">Forgot Password?</span>
                <span>
                <Button className="signUp"
                onClick={()=>{
                  history.push("/registerUser")
                  window.scrollTo(0,0)
                }}
                >
                  Register
                </Button>
                </span> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginForm;
