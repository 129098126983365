export const types = {
  CREATE_PRODUCTS: "CREATE_PRODUCTS",
  CREATE_PRODUCTS_SUCCESS: "CREATE_PRODUCTS_SUCCESS",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_BY_ID: "GET_PRODUCTS_BY_ID",
  GET_PRODUCTS_BY_ID_SUCCESS: "GET_PRODUCTS_BY_ID_SUCCESS",
  UPDATE_PRODUCTS: "UPDATE_PRODUCTS",
  UPDATE_PRODUCTS_SUCCESS: "UPDATE_PRODUCTS_SUCCESS",
  DELETE_PRODUCTS: "DELETE_PRODUCTS",
  DELETE_PRODUCTS_SUCCESS: "DELETE_PRODUCTS_SUCCESS",
  PRODUCT_VERIFY_OTP: "PRODUCT_VERIFY_OTP",
  PRODUCT_VERIFY_OTP_SUCCESS: "PRODUCT_VERIFY_OTP_SUCCESS",
  SEND_OTP: "SEND_OTP",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  PRODUCT_KYC: "PRODUCT_KYC",
  PRODUCT_KYC_SUCCESS: "PRODUCT_KYC_SUCCESS",
  PRODUCT_RESEND_OTP: "PRODUCT_RESEND_OTP",
  PRODUCT_RESEND_OTP_SUCCESS: "PRODUCT_RESEND_OTP_SUCCESS",
  CREATE_PRODUCT_VARIANT: "CREATE_PRODUCT_VARIANT",
  CREATE_PRODUCT_VARIANT_SUCCESS: "CREATE_PRODUCT_VARIANT_SUCCESS",
  CHANGE_PRODUCT_STATUS: "CHANGE_PRODUCT_STATUS",
  CHANGE_PRODUCT_STATUS_SUCCESS: "CHANGE_PRODUCT_STATUS_SUCCESS",
  GET_PRODUCTS_BY_ID_PRODUCT_CERTIFICATION:
    "GET_PRODUCTS_BY_ID_PRODUCT_CERTIFICATION",
  GET_PRODUCTS_BY_ID_SUCCESS_PRODUCT_CERTIFICATION:
    "GET_PRODUCTS_BY_ID_SUCCESS_PRODUCT_CERTIFICATION",
  GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN: "GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN",
  GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN_SUCCESS:
    "GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN_SUCCESS",
  COLLECT_FAKE_ALERT_DETAILS: "COLLECT_FAKE_ALERT_DETAILS",
  COLLECT_FAKE_ALERT_DETAILS_SUCCESS: "COLLECT_FAKE_ALERT_DETAILS_SUCCESS",
  TRANSFERR_PRODUCT: "TRANSFERR_PRODUCT",
  TRANSFERR_PRODUCT_SUCCESS: "TRANSFERR_PRODUCT_SUCCESS",
  ADD_NFC_TAG: "ADD_NFC_TAG",
  ADD_NFC_TAG_SUCCESS: "ADD_NFC_TAG_SUCCESS",
  UNDO_TRANSFERED_PRODUCT: "UNDO_TRANSFERED_PRODUCT",
  UNDO_TRANSFERED_PRODUCT_SUCCESS: "UNDO_TRANSFERED_PRODUCT_SUCCESS",
};
