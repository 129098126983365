import { types } from "./actionTypes";
const initialState = {
  getUsersDetails: null,
  updateUsersDetails: null,
  createUsersDetails: null,
  deleteUsersDetails: null,
  getRolesDetails:null,
};
const usersReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      state = {
        ...state,
        getUsersDetails: action.payload,
      };
      return state;
      case types.GET_ROLES_SUCCESS:
        state = {
          ...state,
          getRolesDetails: action.payload,
        };
        return state;
    case types.CREATE_USERS_SUCCESS:
      state = {
        ...state,
        createUsersDetails: action.payload,
      };
      return state;
    case types.UPDATE_USERS_SUCCESS:
      state = {
        ...state,
        updateUsersDetails: action.payload,
      };
      return state;
    case types.DELETE_USERS_SUCCESS:
      state = {
        ...state,
        deleteUsersDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default usersReducer;
