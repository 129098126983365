export const types = {
  CREATE_CATEGORY:"CREATE_CATEGORY",
  CREATE_CATEGORY_SUCCESS:"CREATE_CATEGORY_SUCCESS",
  GET_CATEGORY:"GET_CATEGORY",
  GET_CATEGORY_SUCCESS:"GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FILTERS:"GET_CATEGORY_FILTERS",
  GET_CATEGORY_FILTERS_SUCCESS:"GET_CATEGORY_FILTERS_SUCCESS",
  UPDATE_CATEGORY:"UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS:"UPDATE_CATEGORY_SUCCESS",
  DELETE_CATEGORY:"DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS:"DELETE_CATEGORY_SUCCESS"
    
}