import React from "react";

const UsersIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13.5989C0.0343838 13.427 0.0687677 13.2493 0.108882 13.0774C0.544411 11.2952 2.06876 10.0344 3.9083 10.0058C5.97133 9.97711 8.03436 9.97137 10.0974 10.0058C12.2865 10.0459 13.9828 11.7994 14.0057 13.9886C14.0171 14.9742 14.0114 15.9656 14.0057 16.9513C14.0057 17.5645 13.5988 17.9771 12.9914 17.9943C12.6246 18.0057 12.2636 17.9943 11.8968 17.9943C8.34954 17.9943 4.80801 17.9885 1.26074 18C0.641832 18 0.206303 17.7994 0 17.1977C0 16.0057 0 14.8023 0 13.5989ZM11.9885 15.9943C11.9942 15.9427 12 15.9026 12 15.8625C12 15.2206 12.0114 14.5731 11.9942 13.9312C11.9713 12.8768 11.1174 12.0172 10.0687 12.0115C8.02863 12 5.98852 12 3.94841 12.0115C2.91117 12.0172 2.06303 12.8424 2.01719 13.8797C1.98853 14.5502 2.00572 15.2264 2.00572 15.8969C2.00572 15.9312 2.01719 15.9599 2.02865 15.9943C5.35242 15.9943 8.66473 15.9943 11.9885 15.9943Z"
        fill="#C59C6C"
      />
      <path
        d="M7.01407 1.38255e-05C9.2261 0.0114751 11.0083 1.80517 11.0026 4.01719C10.9969 6.21203 9.19745 8.00572 7.00261 8.00572C4.78485 8.00572 2.99689 6.20057 3.00836 3.97135C3.01409 1.77078 4.80778 -0.00571682 7.01407 1.38255e-05ZM8.99688 3.99427C8.99115 2.88253 8.09143 1.98855 6.98542 2.00001C5.89087 2.00574 4.99689 2.91118 4.99689 4.00573C5.00262 5.11175 5.90233 6.00573 7.01407 5.99999C8.12009 5.99426 9.00261 5.10028 8.99688 3.99427Z"
        fill="#C59C6C"
      />
    </svg>
  );
};

export default UsersIcon;
