import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Card } from "@mui/material";
import Loader from "../../common/Components/Loader/Loader";
//store
import { getDashboard } from "../../Store/dashboard/actions";
import { getUsers } from "../../Store/users/actions";
import ClientIcon from "../../common/Components/Icons/ClientIcon";
import UsersRolesPieChart from "./UsersRolesPieChart";
var usersDataArry: any;
const Dashboard = (props: any) => {
  const [loading, setLoading]: any = useState(false);

  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    setLoading(true);
    props.getDashboard(() => {
      setLoading(false);
    });
  };
  var dashboardData: any;

  if (props.dashboardDetails !== undefined) {
    dashboardData = props.dashboardDetails?.data;

    usersDataArry = [
      {
        name: "Super Admin",
        value: dashboardData?.superAdmin,
      },
      {
        name: "Admin",
        value: dashboardData?.admin,
      },
      {
        name: "Organization Admin",
        value: dashboardData?.organizationAdmin,
      },
      {
        name: "Organization Manager",
        value: dashboardData?.organizationManager,
      },
      {
        name: "NAQSEBAND",
        value: dashboardData?.naqseband,
      },
      {
        name: "Stain Master",
        value: dashboardData?.stainMaster,
      },
      {
        name: "Refugar",
        value: dashboardData?.rafugar,
      },
      {
        name: "Tassel",
        value: dashboardData?.tassel,
      },
      {
        name: "Dyer",
        value: dashboardData?.dyer,
      },
      {
        name: "Weaver",
        value: dashboardData?.weaver,
      },
    ];
  }
  return (
    <>
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="dashboardP">
          <Grid container className="" spacing={2}>
            <Grid item xs={12} md={6} lg={8}>
              <Grid container className="" spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card className="Card21">
                    <div className="cardHead">
                      Products :{" "}
                      {dashboardData?.draftProductCount +
                        dashboardData?.publishedProductCount +
                        dashboardData?.associatedProductCount}
                    </div>
                    <div className="cardText2">
                      <div className="smallCards">
                        <div className="d1">
                          <div className="dDraft">Drafted</div>
                          <div className="cardText21">
                            <div className="Number">
                              {dashboardData?.draftProductCount}
                            </div>
                            <div>
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="15" cy="15" r="15" fill="#EAD6C0" />
                                <path
                                  d="M11.3153 22C10.2993 22 9.28819 22 8.27222 22C8.02431 22 8 21.9757 8 21.7278C8 19.6861 8 17.6445 8 15.6028C8 15.3743 8.03403 15.3354 8.2625 15.3354C10.309 15.3354 12.3556 15.3354 14.4069 15.3354C14.6257 15.3354 14.6646 15.3792 14.6646 15.6077C14.6646 17.6493 14.6646 19.691 14.6646 21.7327C14.6646 21.9611 14.6257 21.9952 14.4021 21.9952C13.3667 22 12.341 22 11.3153 22ZM12.5403 19.5209C12.4333 19.516 12.3604 19.5063 12.2875 19.5063C11.7431 19.5063 11.1937 19.5063 10.6493 19.5063C9.99306 19.5111 10.0806 19.4334 10.0757 20.0945C10.0757 20.1723 10.1097 20.2452 10.1292 20.3181C10.9458 20.3181 11.7236 20.3181 12.5306 20.3181C12.6035 20.0507 12.5889 19.7979 12.5403 19.5209Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M21.9952 18.675C21.9952 19.6958 21.9952 20.7167 21.9952 21.7375C21.9952 21.9708 21.966 22 21.7327 22C19.691 22 17.6493 22 15.6077 22C15.3695 22 15.3354 21.966 15.3354 21.7278C15.3354 19.691 15.3354 17.659 15.3354 15.6222C15.3354 15.3743 15.3695 15.3403 15.6223 15.3403C17.6493 15.3403 19.6813 15.3403 21.7084 15.3403C21.9709 15.3403 21.9952 15.3694 21.9952 15.6368C21.9952 16.6479 21.9952 17.6639 21.9952 18.675ZM19.8611 20.3229C19.8806 20.2549 19.9049 20.1868 19.9098 20.1236C19.9195 20.0021 19.9146 19.8805 19.9098 19.759C19.9098 19.5403 19.8757 19.5062 19.6521 19.5062C18.991 19.5062 18.3299 19.5062 17.6688 19.5062C17.6007 19.5062 17.5278 19.5208 17.4354 19.5305C17.4014 19.8028 17.3723 20.0604 17.4598 20.318C18.2716 20.3229 19.0542 20.3229 19.8611 20.3229Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M18.3103 14.6208C18.2228 14.6354 18.1499 14.6597 18.077 14.6597C16.0256 14.6597 13.9694 14.6597 11.918 14.6597C11.845 14.6597 11.7721 14.6354 11.6895 14.6208C11.6846 14.5236 11.6749 14.4507 11.6749 14.3826C11.6749 12.3458 11.6749 10.3139 11.6749 8.27708C11.67 8.01944 11.6895 8 11.952 8C13.9791 8 16.011 8 18.0381 8C18.3006 8 18.32 8.01944 18.32 8.27708C18.32 10.3139 18.32 12.3458 18.32 14.3826C18.32 14.4507 18.3152 14.5139 18.3103 14.6208ZM13.7506 12.2097C13.7506 12.4431 13.7506 12.6278 13.7506 12.8174C13.7506 12.9389 13.8138 12.9972 13.9305 12.9972C14.6402 12.9972 15.3499 13.0021 16.0645 12.9924C16.1228 12.9924 16.2249 12.9049 16.2346 12.8514C16.2541 12.6472 16.2444 12.4431 16.2444 12.2097C16.1569 12.1903 16.0839 12.166 16.011 12.1611C15.3353 12.1562 14.6645 12.1562 13.9888 12.1611C13.911 12.1611 13.8381 12.1903 13.7506 12.2097Z"
                                  fill="#C59C6C"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="d2">
                          <div className="dDraft1">Published</div>
                          <div className="cardText21">
                            <div className="Number">
                              {" "}
                              {dashboardData?.publishedProductCount}
                            </div>
                            <div>
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="15" cy="15" r="15" fill="#EAD6C0" />
                                <path
                                  d="M11.3153 22C10.2993 22 9.28819 22 8.27222 22C8.02431 22 8 21.9757 8 21.7278C8 19.6861 8 17.6445 8 15.6028C8 15.3743 8.03403 15.3354 8.2625 15.3354C10.309 15.3354 12.3556 15.3354 14.4069 15.3354C14.6257 15.3354 14.6646 15.3792 14.6646 15.6077C14.6646 17.6493 14.6646 19.691 14.6646 21.7327C14.6646 21.9611 14.6257 21.9952 14.4021 21.9952C13.3667 22 12.341 22 11.3153 22ZM12.5403 19.5209C12.4333 19.516 12.3604 19.5063 12.2875 19.5063C11.7431 19.5063 11.1937 19.5063 10.6493 19.5063C9.99306 19.5111 10.0806 19.4334 10.0757 20.0945C10.0757 20.1723 10.1097 20.2452 10.1292 20.3181C10.9458 20.3181 11.7236 20.3181 12.5306 20.3181C12.6035 20.0507 12.5889 19.7979 12.5403 19.5209Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M21.9952 18.675C21.9952 19.6958 21.9952 20.7167 21.9952 21.7375C21.9952 21.9708 21.966 22 21.7327 22C19.691 22 17.6493 22 15.6077 22C15.3695 22 15.3354 21.966 15.3354 21.7278C15.3354 19.691 15.3354 17.659 15.3354 15.6222C15.3354 15.3743 15.3695 15.3403 15.6223 15.3403C17.6493 15.3403 19.6813 15.3403 21.7084 15.3403C21.9709 15.3403 21.9952 15.3694 21.9952 15.6368C21.9952 16.6479 21.9952 17.6639 21.9952 18.675ZM19.8611 20.3229C19.8806 20.2549 19.9049 20.1868 19.9098 20.1236C19.9195 20.0021 19.9146 19.8805 19.9098 19.759C19.9098 19.5403 19.8757 19.5062 19.6521 19.5062C18.991 19.5062 18.3299 19.5062 17.6688 19.5062C17.6007 19.5062 17.5278 19.5208 17.4354 19.5305C17.4014 19.8028 17.3723 20.0604 17.4598 20.318C18.2716 20.3229 19.0542 20.3229 19.8611 20.3229Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M18.3103 14.6208C18.2228 14.6354 18.1499 14.6597 18.077 14.6597C16.0256 14.6597 13.9694 14.6597 11.918 14.6597C11.845 14.6597 11.7721 14.6354 11.6895 14.6208C11.6846 14.5236 11.6749 14.4507 11.6749 14.3826C11.6749 12.3458 11.6749 10.3139 11.6749 8.27708C11.67 8.01944 11.6895 8 11.952 8C13.9791 8 16.011 8 18.0381 8C18.3006 8 18.32 8.01944 18.32 8.27708C18.32 10.3139 18.32 12.3458 18.32 14.3826C18.32 14.4507 18.3152 14.5139 18.3103 14.6208ZM13.7506 12.2097C13.7506 12.4431 13.7506 12.6278 13.7506 12.8174C13.7506 12.9389 13.8138 12.9972 13.9305 12.9972C14.6402 12.9972 15.3499 13.0021 16.0645 12.9924C16.1228 12.9924 16.2249 12.9049 16.2346 12.8514C16.2541 12.6472 16.2444 12.4431 16.2444 12.2097C16.1569 12.1903 16.0839 12.166 16.011 12.1611C15.3353 12.1562 14.6645 12.1562 13.9888 12.1611C13.911 12.1611 13.8381 12.1903 13.7506 12.2097Z"
                                  fill="#C59C6C"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="d2">
                          <div className="dDraft1">Transfered In</div>
                          <div className="cardText21">
                            <div className="Number">
                              {" "}
                              {dashboardData?.associatedProductCount}
                            </div>
                            <div>
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="15" cy="15" r="15" fill="#EAD6C0" />
                                <path
                                  d="M11.3153 22C10.2993 22 9.28819 22 8.27222 22C8.02431 22 8 21.9757 8 21.7278C8 19.6861 8 17.6445 8 15.6028C8 15.3743 8.03403 15.3354 8.2625 15.3354C10.309 15.3354 12.3556 15.3354 14.4069 15.3354C14.6257 15.3354 14.6646 15.3792 14.6646 15.6077C14.6646 17.6493 14.6646 19.691 14.6646 21.7327C14.6646 21.9611 14.6257 21.9952 14.4021 21.9952C13.3667 22 12.341 22 11.3153 22ZM12.5403 19.5209C12.4333 19.516 12.3604 19.5063 12.2875 19.5063C11.7431 19.5063 11.1937 19.5063 10.6493 19.5063C9.99306 19.5111 10.0806 19.4334 10.0757 20.0945C10.0757 20.1723 10.1097 20.2452 10.1292 20.3181C10.9458 20.3181 11.7236 20.3181 12.5306 20.3181C12.6035 20.0507 12.5889 19.7979 12.5403 19.5209Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M21.9952 18.675C21.9952 19.6958 21.9952 20.7167 21.9952 21.7375C21.9952 21.9708 21.966 22 21.7327 22C19.691 22 17.6493 22 15.6077 22C15.3695 22 15.3354 21.966 15.3354 21.7278C15.3354 19.691 15.3354 17.659 15.3354 15.6222C15.3354 15.3743 15.3695 15.3403 15.6223 15.3403C17.6493 15.3403 19.6813 15.3403 21.7084 15.3403C21.9709 15.3403 21.9952 15.3694 21.9952 15.6368C21.9952 16.6479 21.9952 17.6639 21.9952 18.675ZM19.8611 20.3229C19.8806 20.2549 19.9049 20.1868 19.9098 20.1236C19.9195 20.0021 19.9146 19.8805 19.9098 19.759C19.9098 19.5403 19.8757 19.5062 19.6521 19.5062C18.991 19.5062 18.3299 19.5062 17.6688 19.5062C17.6007 19.5062 17.5278 19.5208 17.4354 19.5305C17.4014 19.8028 17.3723 20.0604 17.4598 20.318C18.2716 20.3229 19.0542 20.3229 19.8611 20.3229Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M18.3103 14.6208C18.2228 14.6354 18.1499 14.6597 18.077 14.6597C16.0256 14.6597 13.9694 14.6597 11.918 14.6597C11.845 14.6597 11.7721 14.6354 11.6895 14.6208C11.6846 14.5236 11.6749 14.4507 11.6749 14.3826C11.6749 12.3458 11.6749 10.3139 11.6749 8.27708C11.67 8.01944 11.6895 8 11.952 8C13.9791 8 16.011 8 18.0381 8C18.3006 8 18.32 8.01944 18.32 8.27708C18.32 10.3139 18.32 12.3458 18.32 14.3826C18.32 14.4507 18.3152 14.5139 18.3103 14.6208ZM13.7506 12.2097C13.7506 12.4431 13.7506 12.6278 13.7506 12.8174C13.7506 12.9389 13.8138 12.9972 13.9305 12.9972C14.6402 12.9972 15.3499 13.0021 16.0645 12.9924C16.1228 12.9924 16.2249 12.9049 16.2346 12.8514C16.2541 12.6472 16.2444 12.4431 16.2444 12.2097C16.1569 12.1903 16.0839 12.166 16.011 12.1611C15.3353 12.1562 14.6645 12.1562 13.9888 12.1611C13.911 12.1611 13.8381 12.1903 13.7506 12.2097Z"
                                  fill="#C59C6C"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="d2">
                          <div className="dDraft1">Transfered Out</div>
                          <div className="cardText21">
                            <div className="Number">
                              {" "}
                              {dashboardData?.associatedProductCount}
                            </div>
                            <div>
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="15" cy="15" r="15" fill="#EAD6C0" />
                                <path
                                  d="M11.3153 22C10.2993 22 9.28819 22 8.27222 22C8.02431 22 8 21.9757 8 21.7278C8 19.6861 8 17.6445 8 15.6028C8 15.3743 8.03403 15.3354 8.2625 15.3354C10.309 15.3354 12.3556 15.3354 14.4069 15.3354C14.6257 15.3354 14.6646 15.3792 14.6646 15.6077C14.6646 17.6493 14.6646 19.691 14.6646 21.7327C14.6646 21.9611 14.6257 21.9952 14.4021 21.9952C13.3667 22 12.341 22 11.3153 22ZM12.5403 19.5209C12.4333 19.516 12.3604 19.5063 12.2875 19.5063C11.7431 19.5063 11.1937 19.5063 10.6493 19.5063C9.99306 19.5111 10.0806 19.4334 10.0757 20.0945C10.0757 20.1723 10.1097 20.2452 10.1292 20.3181C10.9458 20.3181 11.7236 20.3181 12.5306 20.3181C12.6035 20.0507 12.5889 19.7979 12.5403 19.5209Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M21.9952 18.675C21.9952 19.6958 21.9952 20.7167 21.9952 21.7375C21.9952 21.9708 21.966 22 21.7327 22C19.691 22 17.6493 22 15.6077 22C15.3695 22 15.3354 21.966 15.3354 21.7278C15.3354 19.691 15.3354 17.659 15.3354 15.6222C15.3354 15.3743 15.3695 15.3403 15.6223 15.3403C17.6493 15.3403 19.6813 15.3403 21.7084 15.3403C21.9709 15.3403 21.9952 15.3694 21.9952 15.6368C21.9952 16.6479 21.9952 17.6639 21.9952 18.675ZM19.8611 20.3229C19.8806 20.2549 19.9049 20.1868 19.9098 20.1236C19.9195 20.0021 19.9146 19.8805 19.9098 19.759C19.9098 19.5403 19.8757 19.5062 19.6521 19.5062C18.991 19.5062 18.3299 19.5062 17.6688 19.5062C17.6007 19.5062 17.5278 19.5208 17.4354 19.5305C17.4014 19.8028 17.3723 20.0604 17.4598 20.318C18.2716 20.3229 19.0542 20.3229 19.8611 20.3229Z"
                                  fill="#C59C6C"
                                />
                                <path
                                  d="M18.3103 14.6208C18.2228 14.6354 18.1499 14.6597 18.077 14.6597C16.0256 14.6597 13.9694 14.6597 11.918 14.6597C11.845 14.6597 11.7721 14.6354 11.6895 14.6208C11.6846 14.5236 11.6749 14.4507 11.6749 14.3826C11.6749 12.3458 11.6749 10.3139 11.6749 8.27708C11.67 8.01944 11.6895 8 11.952 8C13.9791 8 16.011 8 18.0381 8C18.3006 8 18.32 8.01944 18.32 8.27708C18.32 10.3139 18.32 12.3458 18.32 14.3826C18.32 14.4507 18.3152 14.5139 18.3103 14.6208ZM13.7506 12.2097C13.7506 12.4431 13.7506 12.6278 13.7506 12.8174C13.7506 12.9389 13.8138 12.9972 13.9305 12.9972C14.6402 12.9972 15.3499 13.0021 16.0645 12.9924C16.1228 12.9924 16.2249 12.9049 16.2346 12.8514C16.2541 12.6472 16.2444 12.4431 16.2444 12.2097C16.1569 12.1903 16.0839 12.166 16.011 12.1611C15.3353 12.1562 14.6645 12.1562 13.9888 12.1611C13.911 12.1611 13.8381 12.1903 13.7506 12.2097Z"
                                  fill="#C59C6C"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  {/* <Card className="Card1">
                    <div className="cardHead">
                      Products :{" "}
                      {dashboardData?.draftProductCount +
                        dashboardData?.publishedProductCount}
                    </div>
                    <div className="cardText2">
                      <div className="smallCards">
                        <div className="d1">
                          <div className="dDraft">Drafted</div>
                          <p>{dashboardData?.draftProductCount}</p>
                        </div>
                        <div className="d2">
                          <div className="dDraft1">Published</div>
                          <p>{dashboardData?.publishedProductCount}</p>
                        </div>
                      </div>
                      <div>
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="15" cy="15" r="15" fill="#EAD6C0" />
                          <path
                            d="M11.3153 22C10.2993 22 9.28819 22 8.27222 22C8.02431 22 8 21.9757 8 21.7278C8 19.6861 8 17.6445 8 15.6028C8 15.3743 8.03403 15.3354 8.2625 15.3354C10.309 15.3354 12.3556 15.3354 14.4069 15.3354C14.6257 15.3354 14.6646 15.3792 14.6646 15.6077C14.6646 17.6493 14.6646 19.691 14.6646 21.7327C14.6646 21.9611 14.6257 21.9952 14.4021 21.9952C13.3667 22 12.341 22 11.3153 22ZM12.5403 19.5209C12.4333 19.516 12.3604 19.5063 12.2875 19.5063C11.7431 19.5063 11.1937 19.5063 10.6493 19.5063C9.99306 19.5111 10.0806 19.4334 10.0757 20.0945C10.0757 20.1723 10.1097 20.2452 10.1292 20.3181C10.9458 20.3181 11.7236 20.3181 12.5306 20.3181C12.6035 20.0507 12.5889 19.7979 12.5403 19.5209Z"
                            fill="#C59C6C"
                          />
                          <path
                            d="M21.9952 18.675C21.9952 19.6958 21.9952 20.7167 21.9952 21.7375C21.9952 21.9708 21.966 22 21.7327 22C19.691 22 17.6493 22 15.6077 22C15.3695 22 15.3354 21.966 15.3354 21.7278C15.3354 19.691 15.3354 17.659 15.3354 15.6222C15.3354 15.3743 15.3695 15.3403 15.6223 15.3403C17.6493 15.3403 19.6813 15.3403 21.7084 15.3403C21.9709 15.3403 21.9952 15.3694 21.9952 15.6368C21.9952 16.6479 21.9952 17.6639 21.9952 18.675ZM19.8611 20.3229C19.8806 20.2549 19.9049 20.1868 19.9098 20.1236C19.9195 20.0021 19.9146 19.8805 19.9098 19.759C19.9098 19.5403 19.8757 19.5062 19.6521 19.5062C18.991 19.5062 18.3299 19.5062 17.6688 19.5062C17.6007 19.5062 17.5278 19.5208 17.4354 19.5305C17.4014 19.8028 17.3723 20.0604 17.4598 20.318C18.2716 20.3229 19.0542 20.3229 19.8611 20.3229Z"
                            fill="#C59C6C"
                          />
                          <path
                            d="M18.3103 14.6208C18.2228 14.6354 18.1499 14.6597 18.077 14.6597C16.0256 14.6597 13.9694 14.6597 11.918 14.6597C11.845 14.6597 11.7721 14.6354 11.6895 14.6208C11.6846 14.5236 11.6749 14.4507 11.6749 14.3826C11.6749 12.3458 11.6749 10.3139 11.6749 8.27708C11.67 8.01944 11.6895 8 11.952 8C13.9791 8 16.011 8 18.0381 8C18.3006 8 18.32 8.01944 18.32 8.27708C18.32 10.3139 18.32 12.3458 18.32 14.3826C18.32 14.4507 18.3152 14.5139 18.3103 14.6208ZM13.7506 12.2097C13.7506 12.4431 13.7506 12.6278 13.7506 12.8174C13.7506 12.9389 13.8138 12.9972 13.9305 12.9972C14.6402 12.9972 15.3499 13.0021 16.0645 12.9924C16.1228 12.9924 16.2249 12.9049 16.2346 12.8514C16.2541 12.6472 16.2444 12.4431 16.2444 12.2097C16.1569 12.1903 16.0839 12.166 16.011 12.1611C15.3353 12.1562 14.6645 12.1562 13.9888 12.1611C13.911 12.1611 13.8381 12.1903 13.7506 12.2097Z"
                            fill="#C59C6C"
                          />
                        </svg>
                      </div>
                    </div>
                  </Card> */}
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}>
                  <Card className="Card1">
                    <div className="cardHead">Clients</div>
                    <div className="cardText">People collaborated with us </div>
                    <div className="cardText2">
                      <div className="Number">500</div>
                      <div>
                        <ClientIcon />
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Card className="Card1">
                    <div className="cardHead">Buyers</div>
                    <div className="cardText">People who buy our products</div>
                    <div className="cardText2">
                      <div className="Number">23,456</div>
                      <div>
                        <ClientIcon />
                      </div>
                    </div>
                  </Card>
                </Grid> */}
                <Grid item xs={12} md={12} lg={5}>
                  <Card className="Card21">
                    <div className="cardHead">
                      Product Certification User's Visiting Count
                    </div>
                    <div className="cardText2">
                      <div className="smallCards">
                        <div className="d1">
                          <div className="dDraft">New User's</div>
                          <div className="cardText21">
                            <div className="Number">
                              {dashboardData?.newUserCount}
                            </div>
                            <div>
                              <ClientIcon />
                            </div>
                          </div>
                        </div>
                        <div className="d2">
                          <div className="dDraft1">Old User's</div>
                          <div className="cardText21">
                            <div className="Number">
                              {" "}
                              {dashboardData?.oldUserCount}
                            </div>
                            <div>
                              <ClientIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Grid container className="" spacing={2}>
                <Grid item xs={12}>
                  <Card className="CardPie">
                    <div className="cardHeader">
                      Users :{dashboardData?.totalUsers}
                      {/* {authUser?.users?.role === "ORGANIZATION_MANAGER" ||
                    authUser?.users?.role === "ORGANIZATION_ADMIN"
                      ? `Users : ${totCnt}`
                      : "Users Products Upload Status"} */}
                    </div>
                    <div className=" pt-3">
                      <UsersRolesPieChart
                        {...props}
                        usersDataArry={usersDataArry}
                      />
                      {/* {usersDataArry.map((ele: any, i: any) => (
                      <>
                        <Row className="ProgressRow">
                          <Col xs={3}>
                            <span>{ele?.name}</span>
                          </Col>
                          <Col xs={7}>
                            <Progress
                              className="progress"
                              barStyle={{
                                borderRadius: "20px",
                                backgroundColor: "#404B3B",
                              }}
                              value={ele?.value}
                            />
                          </Col>
                          <Col xs={2}>
                            <span>{ele?.value}</span>
                          </Col>
                        </Row>
                        <Row className="ProgressRow1">
                          <Col xs={12} className="Pcol">
                            <span>{ele?.name}</span>
                            <span>{ele?.value}</span>
                          </Col>
                          <Col xs={12}>
                            <Progress
                              className="progress"
                              barStyle={{
                                borderRadius: "20px",
                                backgroundColor: "#404B3B",
                              }}
                              value={ele?.value}
                            />
                          </Col>
                        </Row>
                      </>
                    ))} */}
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    userDetails: state?.usersReducer?.getUsersDetails,
    dashboardDetails: state?.dashboardReducer?.getDashboardDetails,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  getDashboard,
})(Dashboard);
