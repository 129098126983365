import React from 'react'
import { Container, Grid, Card } from "@mui/material";
import ImpactStackHolderCardDetails from './ImpactStackHolderCardDetails';
var polimakerData = [{
  value: 38,
  currency: "₹",
  starts: "0",
  end: "Tn",
  content: "Counterfeit sold worldwide"
}, {
  value: 2.6,
  starts: "0.0",
  currency: "₹",
  end: "Tn",
  content: "Counterfeit sold in India"
}, {
  value: 800,
  starts: "0",
  currency: "",
  end: "Bn",
  content: "Counterfeit sold worldwide"
}, {
  value: 120,
  starts: "0",
  currency: "",
  end: "Bn",
  content: "Counterfeits sold in handlooms"
},]
var artisianData = [{
  value: "35.3 ",
  starts: "0",
  currency: "",
  end: "Lac",
  content: "Weavers"
}, {
  value: "24.7",
  starts: "0",
  currency: "",
  end: "Lac",
  content: "Women weavers"
}]
var endUserData = [{
  value: "100",
  starts: "0",
  currency: "",
  end: "%",
  content: "Buyers wants a authenticity certificate"
}, {
  value: "95",
  starts: "0",
  currency: "",
  end: "%",
  content: "Sellers don’t give authenticity undertaking"
}]
const ImpactStackHolders = (props:any) => {
  return (
    <div id="Impact" className='impact-stack-holders'>
      <Container>
        <div className="header">
          <h2 className="header-title">Impact on stakeholders</h2>
        </div>
        <Grid container spacing={2} className='parent-grid-card-div'>

          <Grid item xs={12} md={6}>
            <Card className='policy-card'>
              <h3 >
                Policy makers
              </h3>
              <ImpactStackHolderCardDetails {...props} data={polimakerData} />
              {/* <Grid container spacing={4}>
                {polimakerData.map((ele, i) => (
                  <Grid item xs={6}>
                    <div className='Polymaker-Number'>{ele.value}</div>
                    <p className='Polymaker-content'>{ele?.content}</p>
                  </Grid>
                ))}
              </Grid> */}
              <span className='Trillion'>Tn = Trillion and Bn = Billlion </span>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Card className='artisian'>
                  <h3 >
                    Artisans
                  </h3>
                  <ImpactStackHolderCardDetails {...props} data={artisianData} />

                  {/* <Grid container spacing={4}>
                    {artisianData.map((ele, i) => (
                      <Grid item xs={6}>
                        <div className='Polymaker-Number'>{ele.value}</div>
                        <p className='Polymaker-content'>{ele?.content}</p>
                      </Grid>
                    ))}
                  </Grid> */}
                </Card>
              </Grid>
              <Grid item xs={12} >
                <Card className='end-user'>
                  <h3 >
                    End users
                  </h3>
                  <ImpactStackHolderCardDetails {...props} data={endUserData} />

                  {/* <Grid container spacing={4}>
                    {endUserData.map((ele, i) => (
                      <Grid item xs={6}>
                        <div className='Polymaker-Number'>{ele.value}</div>
                        <p className='Polymaker-content'>{ele?.content}</p>
                      </Grid>
                    ))}
                  </Grid> */}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </div>
  )
}

export default ImpactStackHolders