import React from "react";
import { Container, Grid } from "@mui/material";
import BenifitsLeftImageCard from "./BenifitsLeftImageCard";
import BenifitsRightImageCard from "./BenifitsRightImageCard";
import MobileBenifitsStockholders from "./MobileBenifitsStockholders";

var benifitsOfStackholderDetails = [{
  image: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Consumer-1.webp",
  title: "Consumer",
  points: [{
    content: "Authentic Products"
  }, {
    content: "Transparency and Trust"
  }, {
    content: "Enhanced Resale Value"
  }, {
    content: "Protection against Counterfeit Products"
  }]
}, {
  image: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Designer-1.webp",
  title: "Protecting Designers",
  points: [{
    content: "Streamlined Order Management"
  }, {
    content: "Protection of Exclusive Designs"
  }, {
    content: "Enhanced Authenticity for Customers"
  }]
}, {
  image: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Artisan-1.webp",
  title: "Artisan",
  points: [{
    content: "Centralised Product Information"
  }, {
    content: "Increased Visibility and Promotion"
  }, {
    content: "Market Access and Reach"
  }, {
    content: "Support and Networking"
  }]
}, {
  image: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Policy-makers-1.webp",
  title: "Policy Makers",
  points: [{
    content: "Accurate Product Count"
  }, {
    content: "Fraud Reduction"
  }, {
    content: "Data for Policy Making"
  }, {
    content: "Informed Decision-Making"
  }]
},]
const BenifitsOfStackHolders = (props) => {
  return <div id="Benefits" className="benifits-stackholders">
    <Container>
      <h2 className="header-title">Benefits to stakeholders</h2>
      <div className="desktop-display-stackholder">
        {
          benifitsOfStackholderDetails.map((ele, i) => (
            <Grid container spacing={2} key={i}>
              <Grid item xs={0} md={2} ></Grid>

              <Grid item xs={12} md={8} key={i}>
                {
                  (i == 0 || i % 2 == 0) ?

                    <BenifitsLeftImageCard {...props} data={ele} /> :
                    <BenifitsRightImageCard {...props} data={ele} />
                }
              </Grid>
            </Grid>
          ))
        }
      </div>
      <div className="mobile-display-stackholder">
        {
          benifitsOfStackholderDetails.map((ele, i) => (
            <React.Fragment key={i}>
              <MobileBenifitsStockholders  {...props} data={ele} />
            </React.Fragment>
          ))
        }
      </div>



    </Container></div>;
};

export default BenifitsOfStackHolders;
