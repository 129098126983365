import React from 'react'

const ImageClose = (props:any) => {
    const {close,i,item}=props
  return (
    <svg
    onClick={() =>close(i,item)
    }
    className="close"
    width="20"
    height="20"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="17"
      height="17"
      rx="4"
      fill="#262D22"
    />
    <path
      d="M12 5L5 12"
      stroke="#C59C6C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5L12 12"
      stroke="#C59C6C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default ImageClose