import React from "react";
import { Container, Grid } from "@mui/material";
import mail from "../assets/icons/icons8-mail-50.png";
import phone from "../assets/icons/icons8-phone-50.png";
import location from "../assets/icons/icons8-location-50.png";
import insta from "../assets/icons/icons8-instagram-50.png"
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <Container className="footer-container">
        <Grid container spacing={2} >
          <Grid item xs={12} md={6} lg={4}>
            <ul>
              <li>
                <Link to="/">
                <div className="footer-hastakala-logo">
                  <span className="sp1">Hastkala</span>{" "}
                  <span className="sp2">Paramanak</span>
                  <p>Supporting the makers, Protecting the buyers</p>
                  </div>
                  </Link>
              </li>
              <li>
                <div>
                  <div className="pt-2 content">
                    HastKala Pramanak is an end to end solutions for objective
                    verification of handmade products leveraging blockchain and
                    artificial intelligence
                  </div>
                </div>
              </li>
            </ul>


          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <ul>
              <li>Quick links</li>
              <li>Impact</li>
              <li>Benifits</li>
              <li>About Us</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <ul>
              <li>Important links</li>
              <li><Link to="/terms-and-conditions" target="_blank" >Terms of services</Link></li>
              <li><Link to="/privacy-policy" target="_blank">Privacy Policy</Link></li>
              {/* <li>Help Centre</li> */}
              <li><a href="https://wa.link/5edo3n" target="_blank">Support</a></li>
            </ul>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ul className="contact-us">
              <li>Contact Us</li>
              <li><img src={mail}  width={20} height={20} alt="mail"/>kunal@hastkalapramanak.in</li>
              <li><img src={phone}  width={20} height={20} alt="mail"/>+91 8935017347</li>
              <li><img src={location}  width={20} height={20} alt="mail"/>19th Main Rd, Vanganahalli, Sector 4, HSR Layout, Bengaluru, Karnataka 560102</li>
              <li><a href="https://www.instagram.com/hastkalapramanak/" target="_blank"><img src={insta}  width={20} height={20} alt="mail"/>Instagram</a></li>
            </ul>
          </Grid>
        </Grid>
     

      </Container>
              <div className="allrights">© 2024 HastkalaPramanak. All rights reserved.</div>

    </div>
  );
};

export default Footer;
