export const types = {
  CREATE_SUB_CATEGORY:"CREATE_SUB_CATEGORY",
  CREATE_SUB_CATEGORY_SUCCESS:"CREATE_SUB_CATEGORY_SUCCESS",
  GET_SUB_CATEGORY:"GET_SUB_CATEGORY",
  GET_SUB_CATEGORY_SUCCESS:"GET_SUB_CATEGORY_SUCCESS",
  GET_SUB_CATEGORY_FILETRS:"GET_SUB_CATEGORY_FILETRS",
  GET_SUB_CATEGORY_FILETRS_SUCCESS:"GET_SUB_CATEGORY_FILETRS_SUCCESS",
  UPDATE_SUB_CATEGORY:"UPDATE_SUB_CATEGORY",
  UPDATE_SUB_CATEGORY_SUCCESS:"UPDATE_SUB_CATEGORY_SUCCESS",
  DELETE_SUB_CATEGORY:"DELETE_SUB_CATEGORY",
  DELETE_SUB_CATEGORY_SUCCESS:"DELETE_SUB_CATEGORY_SUCCESS"
    
}