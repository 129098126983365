import React from 'react'

const DashboardIcon = () => {
  return (
    <svg
    width="25"
    height="25"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.08258 8.16801C3.46473 8.16801 2.84178 8.20885 2.22904 8.15781C0.962704 8.05572 0.0282723 7.02467 0.0129538 5.74862C-0.00236479 4.64611 -0.00236479 3.5436 0.0129538 2.43598C0.0333785 1.08337 1.08525 0.0319013 2.4435 0.0114845C3.54643 -0.00382816 4.64937 -0.00382816 5.75741 0.0114845C7.11565 0.0319013 8.16242 1.08337 8.18285 2.44109C8.19816 3.5436 8.20327 4.64611 8.18285 5.75372C8.15731 7.12675 7.09012 8.16801 5.71656 8.18333C5.1753 8.18843 4.63405 8.18333 4.08769 8.18333C4.08258 8.18333 4.08258 8.17822 4.08258 8.16801ZM6.54376 4.12037C6.54376 3.57933 6.54887 3.03828 6.54376 2.49213C6.53865 1.99702 6.19654 1.64994 5.69613 1.64994C4.62894 1.64483 3.56686 1.64994 2.49966 1.64994C1.98905 1.64994 1.64183 1.99192 1.64183 2.50234C1.63672 3.56912 1.63672 4.6308 1.64183 5.69758C1.64183 6.20289 1.98905 6.54998 2.49966 6.54998C3.55664 6.54998 4.61873 6.54998 5.67571 6.54998C6.19654 6.54998 6.53865 6.208 6.54376 5.68737C6.54887 5.16674 6.54376 4.64611 6.54376 4.12037Z"
      fill="#C59C6C"
    />
    <path
      d="M13.9119 0.0318939C14.5246 0.0318939 15.1425 -0.00893978 15.7501 0.0421023C17.0369 0.149291 17.9662 1.16503 17.9866 2.45129C18.002 3.5538 18.0071 4.65631 17.9866 5.76392C17.9662 7.12675 16.9041 8.17311 15.5357 8.18843C14.4532 8.19863 13.3655 8.19863 12.283 8.18843C10.8941 8.17311 9.82695 7.10633 9.81674 5.71799C9.80653 4.641 9.80653 3.56401 9.81674 2.48192C9.82695 1.09357 10.8941 0.0318939 12.2881 0.0165813C12.8294 0.0114771 13.3706 0.0165813 13.917 0.0165813C13.9119 0.0216855 13.9119 0.0267897 13.9119 0.0318939ZM16.3475 4.12037C16.3475 3.57932 16.3527 3.03827 16.3475 2.49212C16.3424 1.99702 16.0003 1.64993 15.4999 1.64993C14.4327 1.64482 13.3706 1.64993 12.3035 1.64993C11.7928 1.64993 11.4456 1.99191 11.4456 2.50233C11.4405 3.56911 11.4405 4.63079 11.4456 5.69757C11.4456 6.20289 11.7928 6.54997 12.3035 6.54997C13.3604 6.54997 14.4225 6.54997 15.4795 6.54997C16.0003 6.54997 16.3424 6.20799 16.3475 5.68736C16.3527 5.16673 16.3475 4.6461 16.3475 4.12037Z"
      fill="#C59C6C"
    />
    <path
      d="M4.10282 9.832C4.72067 9.832 5.34362 9.79117 5.95636 9.84221C7.2278 9.9494 8.15713 10.9651 8.17755 12.2463C8.19287 13.3488 8.19287 14.4513 8.17755 15.5589C8.15713 16.9218 7.10015 17.9732 5.73169 17.9885C4.63897 18.0038 3.55135 18.0038 2.45863 17.9885C1.07485 17.9732 0.0178716 16.9064 0.00765927 15.5232C-0.00255309 14.4462 -0.00255309 13.3692 0.00765927 12.2871C0.0178716 10.8937 1.08506 9.832 2.47395 9.81669C3.0152 9.81159 3.55646 9.81669 4.10282 9.81669C4.10282 9.8218 4.10282 9.8269 4.10282 9.832ZM4.0875 16.3501C4.61854 16.3501 5.14448 16.3501 5.67552 16.3501C6.19635 16.3501 6.53847 16.0081 6.54357 15.4875C6.54357 14.4309 6.54357 13.3692 6.54357 12.3127C6.54357 11.792 6.20146 11.45 5.67552 11.45C4.62365 11.45 3.57177 11.45 2.51479 11.45C1.97865 11.45 1.64164 11.7869 1.63653 12.3229C1.63653 13.3743 1.63653 14.4258 1.63653 15.4824C1.63653 16.0132 1.97865 16.3501 2.50969 16.3552C3.04073 16.3552 3.56667 16.3501 4.0875 16.3501Z"
      fill="#C59C6C"
    />
    <path
      d="M17.9713 13.9102C17.9713 14.5227 18.0121 15.1403 17.9611 15.7477C17.8538 17.034 16.8377 17.963 15.5509 17.9834C14.448 17.9987 13.3451 18.0038 12.237 17.9834C10.8635 17.963 9.8167 16.8911 9.80649 15.5181C9.79628 14.4411 9.79628 13.3641 9.80649 12.282C9.8167 10.8936 10.8839 9.82686 12.2779 9.81665C13.3553 9.80644 14.4327 9.80644 15.5152 9.81665C16.9041 9.82686 17.9662 10.8936 17.9815 12.2871C17.9866 12.8281 17.9815 13.3692 17.9815 13.9153C17.9815 13.9102 17.9764 13.9102 17.9713 13.9102ZM13.917 11.45C13.3757 11.45 12.8345 11.4449 12.2881 11.45C11.7928 11.4551 11.4456 11.7971 11.4456 12.2973C11.4405 13.3641 11.4456 14.4257 11.4456 15.4925C11.4456 16.003 11.7877 16.35 12.2983 16.35C13.3655 16.3551 14.4276 16.3551 15.4948 16.35C16.0003 16.35 16.3475 16.003 16.3475 15.4925C16.3475 14.436 16.3475 13.3743 16.3475 12.3177C16.3475 11.7971 16.0054 11.4551 15.4846 11.45C14.9637 11.45 14.4378 11.45 13.917 11.45Z"
      fill="#C59C6C"
    />
  </svg>
  )
}

export default DashboardIcon