import React from "react";
import { Container, Grid } from "@mui/material";
import TimeLineComponents from "./TimeLineComponents";
import { Button } from "reactstrap";
const HowToUse = (props) => {
  return (<div className="how-to-use">
    <Container>
      <div className="header">
        <h2 className="header-title">How to Use</h2>
      </div>
      <TimeLineComponents />
    </Container>
    <div className="see-demo">
          <Button >See demo video</Button>
    </div>

  </div>);
};

export default HowToUse;
