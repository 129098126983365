let nowDate: any = new Date();
let expiryDate = new Date(new Date().setHours(new Date().getHours() + 6));
let expiryDate2 = new Date(Date.now() + 6 * (60 * 60 * 1000));
let getMinDate: any = new Date(
  new Date().setMinutes(new Date().getMinutes() + 1)
);
let nowDateMilSec: any = nowDate.valueOf();
let sixHoursExpiryMilliSec = expiryDate2.valueOf();
let sixMinExpiryMilliSec: any = getMinDate.valueOf();

export {
  nowDateMilSec,
  sixHoursExpiryMilliSec,
  getMinDate,
  sixMinExpiryMilliSec,
};
