import React from "react";

const OrganizationIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4444 17V2.77778C13.4444 2.30628 13.2571 1.8541 12.9237 1.5207C12.5903 1.1873 12.1382 1 11.6667 1H8.11111C7.63962 1 7.18743 1.1873 6.85403 1.5207C6.52063 1.8541 6.33333 2.30628 6.33333 2.77778V17M2.77778 4.55556H17C17.9818 4.55556 18.7778 5.35149 18.7778 6.33333V15.2222C18.7778 16.2041 17.9818 17 17 17H2.77778C1.79594 17 1 16.2041 1 15.2222V6.33333C1 5.35149 1.79594 4.55556 2.77778 4.55556Z"
        stroke="#C59C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrganizationIcon;
