import React, { useState } from "react";
import Select from "react-select";
import i18n from "./i18n";

let LanguageSwitcher = () => {
  const languageOptions = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "te",
      label: "Telugu",
    },
    {
      value: "hi",
      label: "Hindi",
    },
    // Add more options as needed
  ];

  const defaultOption = {
    value: "en",
    label: "English",
  };
  let [selectedOption, setSelectedOption] = useState(defaultOption); // Initialize with null or provide a default option
  const handleLanguageChange = (e: any) => {
    const selectedLanguage = e?.value;
    setSelectedOption(e);
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <div className="LangDiv">
      <div className="d1"></div>
      <div className="d1"></div>
      <div className="divText">Please Select Your Comfortable Language</div>
      <div className="formDivSub">
        <Select
          className="form-control-xs"
          classNamePrefix="react-select"
          placeholder="Select"
          autoFocus={false}
          name="language"
          id="language-select"
          value={selectedOption}
          options={languageOptions}
          defaultValue={selectedOption} // Set the default value here
          onChange={(e: any) => {
            handleLanguageChange(e);
          }} // Update the state when the selection changes
        />
      </div>
    </div>
  );
};

export default LanguageSwitcher;
