import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import { postApi, getApi, deleteApi, putApi } from "../../helpers/auth_helpers";

// workers
function* workerGetApplication({callback }: any) {

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/application/applicationDetails" ;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_APPLICATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateApplication({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/application/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_APPLICATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateApplication({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/application/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_APPLICATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteApplication({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/application/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    yield put({
      type: types.DELETE_APPLICATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchGetApplication() {
  yield takeEvery(types.GET_APPLICATION, workerGetApplication);
}
export function* watchCreateApplication() {
  yield takeEvery(types.CREATE_APPLICATION, workerCreateApplication);
}
export function* watchUpdateApplication() {
  yield takeEvery(types.UPDATE_APPLICATION, workerUpdateApplication);
}
export function* watchDeleteApplication() {
  yield takeEvery(types.DELETE_APPLICATION, workerDeleteApplication);
}

function* ApplicationSaga() {
  yield all([fork(watchGetApplication)]);
  yield all([fork(watchCreateApplication)]);
  yield all([fork(watchUpdateApplication)]);
  yield all([fork(watchDeleteApplication)]);
}
export default ApplicationSaga;
