import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
const SignUpForm = (props: any) => {
  const { handleOnChange, Errors, value, handleSubmit, showLoginForm } = props;
  const [show, setShow]: any = useState(false);
  const handleSetShow = (value: any) => {
    setShow(value);
  };
  return (
    <div>
      <div className="parentForm">
        <Row>
          <Col xs={12} md={6}>
            <label className="labelName">
              Name<span>*</span>
            </label>
            <br />
            <input
              placeholder="Enter Name"
              type="text"
              id="name"
              name="name"
              className="inputField"
              onChange={(e: any) =>
                handleOnChange({
                  name: "name",
                  value: e.target.value,
                })
              }
            />
            <br />
          </Col>
          <Col xs={12} md={6}>
            <label className="labelName">
              Phone<span>*</span>
            </label>
            <br />
            <input
              placeholder="Enter Phone No."
              type="text"
              id="phoneSignUp"
              name="phoneSignUp"
              className="inputField"
              onChange={(e: any) =>
                handleOnChange({
                  name: "phone",
                  value: e.target.value,
                })
              }
            />
            <br />
          </Col>
        </Row>
        <label className="labelName">
          Email Id<span>*</span>
        </label>
        <br />
        <input
          placeholder="Email Or Phone"
          type="text"
          id="emaillogin"
          name="emaillogin"
          className="inputField"
          onChange={(e: any) =>
            handleOnChange({
              name: "email",
              value: e.target.value,
            })
          }
        />
        {Errors.email && !value.email ? (
          <p className="RequiredField">{Errors?.email} </p>
        ) : (
          ""
        )}
        <br />
        <label className="labelName">
          Password<span>*</span>
        </label>
        <br />
        <div
          style={{
            position: "relative",
          }}
        >
          {" "}
          <input
            placeholder="Enter Password"
            type={show === true ? "text" : "password"}
            id="pswd"
            name="pswd"
            className="inputField"
            onChange={(e: any) =>
              handleOnChange({
                name: "password",
                value: e.target.value,
              })
            }
          />
          <div className="icon">
            {show ? (
              <HideIcon onClick={() => handleSetShow(false)} />
            ) : (
              <ShowIcon onClick={() => handleSetShow(true)} />
            )}
          </div>
        </div>

        {Errors.password && !value.password ? (
          <p className="RequiredField">{Errors?.password} </p>
        ) : (
          ""
        )}
        <Button
          className="loginBtn"
          // onClick={() => handleSubmit()}
        >
          Login
        </Button>
        <div className="RegDiv">
          <span onClick={() => showLoginForm()}>
            Already have account? Please Sign in
          </span>
          {/* <span className="forgetPswd">Forgot Password?</span>
   <span>
   <Button className="signUp"
   onClick={()=>{
     history.push("/registerUser")
     window.scrollTo(0,0)
   }}
   >
     Register
   </Button>
   </span> */}
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
