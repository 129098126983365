import React from 'react'
import { Grid } from "@mui/material";
const BenifitsLeftImageCard = (props) => {
    const { data  } = props
    return (
        <div className='benifits-left-div'>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <img
                        src={data?.image}
                        with="100%"
                        height="295px"
                        alt=""></img>
                </Grid>
                <Grid item xs={12} sm={6} className='left-part'>
                    <h2>{data?.title}</h2>
                    <ul className="description-points">
                        {
                            data?.points.map((ele, i) => (<li  key={i}><span>{ele?.content}</span></li>))
                        }

                    </ul>
                </Grid>
            </Grid>
        </div>
    )
}

export default BenifitsLeftImageCard