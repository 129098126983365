import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import ProductImagePreView from "../ProductImagePreView";
import Loader from "../../../common/Components/Loader/Loader";
import { Toggle } from "rsuite";
import moment from "moment";
import { connect } from "react-redux";

//icons
import QRDesignIcon from "../../../common/Components/Icons/QRDesignIcon";
import QrWeaveIcon from "../../../common/Components/Icons/QrWeaveIcon";
import QRFinishingIcon from "../../../common/Components/Icons/QRFinishingIcon";

import BannerBackground from "../../../assets/images/Banner Background.png";
import Badge from "../../../assets/images/Badge.png";
import BranerLogo from "../../../assets/images/QRBanner.png";
import LanguageSwitcher from "../../../language/LanguageTranslator";
import { useTranslation } from "react-i18next";
// actions
import CarouselForProduct from "./CarouselForProduct";
import CommonCard from "./CommonCard";
import {
  getProductByIdProductCertification,
  getProductByIdAfterKycToken,
} from "../../../Store/products/actions";
import ProductCertificationForDesignAgency from "../certification-page/ProductCertificationForDesignAgency";
import { getMinDate, nowDateMilSec } from "../DateTime";
const QRCodeProduct = (props: any) => {
  const { t } = useTranslation();

  const { loading } = props;
  const location: any = useLocation();
  const history: any = useHistory();
  let productVerficationCode = localStorage.getItem("productVerificationToken");
  const ProductId: any = location?.pathname.split("/")[3];
  const [Loading, setLoading]: any = useState(false);
  const [openModal, setOpenModal]: any = useState(false);
  const [preview, setPreview]: any = useState("");
  const [selectedLogoImage, setSelectedLogoImage]: any = useState("");
  const [isOpen, setIsOpen]: any = useState({
    design: false,
    weaving: false,
    dying: false,
    finishing: false,
    mainLogo: false,
  });
  var form: any = {
    productUniqueId: "",
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    productName: "",
    designer: "",
    designerMedia: [],
    weaver: "",
    weaverMedia: [],
    dyer: "",
    dyerMedia: [],
    finisher: "",
    finisherMedia: [],
    profileURL: "",
    designingDate: "",
    dyingDate: "",
    weavingDate: "",
    finishingDate: "",
    yarnType: "",
    workingHours: "",
    color: "",
    // new
    SrNo: "",
    weavingCenter: "Varanasi",
    typeOfSareeByDesign: "",
    productionTimeInMonths: "",
    sellPrice:"",
    costPrice: "",
    designNum: "",
  };
  const handleSetSelectedImage = (image: any) => {
    setSelectedLogoImage(image);
  };
  useEffect(() => {
    if (productVerficationCode?.length && ProductId) {
      getAllDataFromGetProducts();
    }
  }, [ProductId]);
  useEffect(() => {
    if (location.pathname.split("/")[1] !== "pre-viewProduct") {
      if (
        productVerficationCode === undefined ||
        productVerficationCode === null
      ) {
        history.push(`/product-verifing/${ProductId}`);
      }
    }
  }, [ProductId]);
  useEffect(() => {
    if (location.pathname.split("/")[1] !== "pre-viewProduct") {
      if (nowDateMilSec > location?.pathname.split("/")[2]) {
        history.push(`/product-link-expired`);
      }
    }
  });

  const getAllDataFromGetProducts = () => {
    setLoading(true);
    props.getProductByIdAfterKycToken(
      {
        offset: 0,
        limit: 10,
        ProductId: ProductId,
        // ProductsName:ProductName
      },
      (res: any) => {
        setLoading(false);
        // localStorage.setItem("productRes", JSON.stringify(res));
        if (
          res.status === 401 ||
          res.message === "UnAuthorized!" ||
          res.message === "Unauthorized!"
        ) {
          history.push(`/product-verifing/${ProductId}`);
          localStorage.removeItem("productVerificationToken");
        }
      }
    );
  };
  const closeModal = () => {
    setOpenModal(false);
    setPreview("");
    setIsOpen({
      design: false,
      weaving: false,
      dying: false,
      finishing: false,
      mainLogo: false,
    });
  };
  let editDetails: any = [];
  if (location.pathname.split("/")[1] === "pre-viewProduct") {
    editDetails = props.editDetails;
  } else {
    if ((props.ViewProductDetails || props.ViewProductDetails1) !== undefined) {
      editDetails =
        props.ViewProductDetails?.data?.docs[0] ||
        props.ViewProductDetails1?.data?.docs[0];
    }
  }

  if (editDetails !== undefined) {
    let designingMedia = editDetails?.designingDetails?.media.map(
      (ele: any) => {
        if (ele?.show === "1") {
          return ele?.mediaURL;
        }
      }
    );
    let weavingMedia = editDetails?.weavingDetails?.media.map((ele: any) => {
      if (ele?.show === "1") {
        return ele?.mediaURL;
      }
    });
    let dyingMedia = editDetails?.dyingDetails?.media.map((ele: any) => {
      if (ele?.show === "1") {
        return ele?.mediaURL;
      }
    });
    let finishingMedia = editDetails?.finishingDetails?.media.map(
      (ele: any) => {
        if (ele?.show === "1") {
          return ele?.mediaURL;
        }
      }
    );

    form = {
      org: editDetails?.productOwner?.length ? editDetails?.productOwner : "",
      color: editDetails?.color,
      productUniqueId: editDetails?.productUniqueId,
      orgLogo:
        editDetails?.productownerLogo !== null
          ? editDetails?.productownerLogo
          : "",
      artform: editDetails?.artform?.name,
      type: editDetails?.type?.name,
      category: editDetails?.category?.name,
      subCategory: editDetails?.subcategory?.name,
      weaveType: editDetails?.weavetype?.name,
      productName: editDetails?.name,
      designer: editDetails?.designingDetails?.karigar?.name,
      designerMedia: designingMedia?.filter((ele: any) => ele !== undefined),
      designUploadBy: editDetails?.designingDetails?.media[0]?.uploadBy,
      weavingUploadBy: editDetails?.weavingDetails?.media[0]?.uploadBy,

      dyingUploadBy: editDetails?.dyingDetails?.media[0]?.uploadBy,

      finishingUploadBy: editDetails?.finishingDetails?.media[0]?.uploadBy,

      designingDate: editDetails?.designingDetails?.media?.length
        ? moment(editDetails?.designingDetails?.media[0]?.createdAt).format(
          "DD MMM YYYY"
        ) +
        "-" +
        moment(
          editDetails?.designingDetails?.media[
            editDetails?.designingDetails?.media?.length - 1
          ]?.createdAt
        ).format("DD MMM YYYY")
        : "",
      designUploadDate: editDetails?.designingDetails?.media?.length
        ? moment(
          editDetails?.designingDetails?.media[0]?.uploadBy?.date
        ).format("DD MMM YYYY")
        : "",
      weavingUploadDate: editDetails?.weavingDetails?.media?.length
        ? moment(editDetails?.weavingDetails?.media[0]?.uploadBy?.date).format(
          "DD MMM YYYY"
        )
        : "",
      dyingUploadDate: editDetails?.dyingDetails?.media?.length
        ? moment(editDetails?.dyingDetails?.media[0]?.uploadBy?.date).format(
          "DD MMM YYYY"
        )
        : "",
      finishingUploadDate: editDetails?.finishingDetails?.media?.length
        ? moment(
          editDetails?.finishingDetails?.media[0]?.uploadBy?.date
        ).format("DD MMM YYYY")
        : "",
      weaver: editDetails?.weavingDetails?.karigar?.name,
      weaverMedia: weavingMedia?.filter((ele: any) => ele !== undefined),
      weavingDate: editDetails?.weavingDetails?.media?.length
        ? moment(editDetails?.weavingDetails?.media[0]?.createdAt).format(
          "DD MMM YYYY"
        ) +
        "-" +
        moment(
          editDetails?.weavingDetails?.media[
            editDetails?.weavingDetails?.media?.length - 1
          ]?.createdAt
        ).format("DD MMM YYYY")
        : "",
      dyer: editDetails?.dyingDetails?.karigar?.name,
      dyerMedia: dyingMedia?.filter((ele: any) => ele !== undefined),
      dyingDate: editDetails?.dyingDetails?.media?.length
        ? moment(editDetails?.dyingDetails?.media[0]?.createdAt).format(
          "DD MMM YYYY"
        ) +
        "-" +
        moment(
          editDetails?.dyingDetails?.media[
            editDetails?.dyingDetails?.media?.length - 1
          ]?.createdAt
        ).format("DD MMM YYYY")
        : "",
      finisher: editDetails?.finishingDetails?.karigar?.name,
      finisherMedia: finishingMedia?.filter((ele: any) => ele !== undefined),
      finishingDate: editDetails?.finishingDetails?.media?.length
        ? moment(editDetails?.finishingDetails?.media[0]?.createdAt).format(
          "DD MMM YYYY"
        ) +
        "-" +
        moment(
          editDetails?.finishingDetails?.media[
            editDetails?.finishingDetails?.media?.length - 1
          ]?.createdAt
        ).format("DD MMM YYYY")
        : "",
      // profileURL: editDetails?.thumbnail,
      profileURL: editDetails?.thumbnailDetails?.media?.length
        ? editDetails?.thumbnailDetails?.media.map((ele: any) => {
          return {
            mediaUrl: ele?.mediaURL,
            show: ele?.show === "1" ? 1 : 0,
            pin: ele?.pin === "1" ? 1 : 0,
          };
        })
        : editDetails?.thumbnailDetails === null &&
          editDetails?.thumbnail?.length > 0
          ? [
            {
              mediaUrl: editDetails?.thumbnail,
              show: 1,
              pin: 1,
            },
          ]
          : [],
      //new
      nameOfBrand: editDetails?.brand,
      nameOfWeaver: editDetails?.weaver,
      handloomMark: editDetails?.handloom,
      silkMark: editDetails?.silk,
      zariUsed: { label: editDetails?.zari, value: editDetails?.purity },
      weightOfSaree: editDetails?.sareeWeight ,
      weightOfZariUsed: editDetails?.zariWeight,
      purity: editDetails?.purity,
      madeInIndia: editDetails?.madeIn === "true" ? true : false,
      yarnType: editDetails?.yarnType,
      workingHours: editDetails?.workingHours,
      loomNum: editDetails?.loomNum,
      SrNo: editDetails?.srNo,
      weavingCenter: editDetails?.weavingCenter || form?.weavingCenter,
      typeOfSareeByDesign: editDetails?.typeOfSareeByDesign,
      productionTimeInMonths: editDetails?.productionTimeInMonths,
      sellPrice: editDetails?.sellPrice,
      costPrice: editDetails?.costPrice,
      designNum: editDetails?.designNum,
    };
  }
  let storeVariable: any = [];
  // if (form?.SrNo?.length > 0) {
  //   storeVariable.push([t("SrNo"), form?.SrNo]);
  // }
  if (form?.productName?.length > 0) {
    storeVariable.push([t("Product"), form?.productName]);
  }
  if (form?.typeOfSareeByDesign?.length > 0) {
    storeVariable.push([
      t("Types_of_sarees_by_design"),
      form?.typeOfSareeByDesign,
    ]);
  }
  if (form?.artform?.length > 0) {
    storeVariable.push([t("Artform"), form?.artform]);
  }
  if (form?.type?.length > 0) {
    storeVariable.push([t("Type"), form?.type]);
  }
  if (form?.category?.length > 0) {
    storeVariable.push([t("Category"), form?.category]);
  }
  if (form?.subCategory?.length > 0) {
    storeVariable.push([t("Sub-Category"), form?.subCategory]);
  }
  if (form?.weaveType?.length > 0) {
    storeVariable.push([t("Weave_Type"), form?.weaveType]);
  }
  if (form?.weavingCenter?.length > 0) {
    storeVariable.push([t("Weaving_Center"), form?.weavingCenter]);
  }
  if (form?.nameOfBrand?.length > 0) {
    storeVariable.push([t("Name_of_Brand"), form?.nameOfBrand]);
  }
  if (form?.nameOfWeaver?.length > 0) {
    storeVariable.push([t("Name_of_weaver"), "Shriman  " + form?.nameOfWeaver]);
  }
  if (form?.weightOfSaree?.length > 0) {
    storeVariable.push([t("Weight_of_Saree"), form?.weightOfSaree]);
  }
  if (form?.weightOfZariUsed?.length > 0) {
    storeVariable.push([t("Weight_of_Zari_used"), form?.weightOfZariUsed]);
  }
  if (form?.zariUsed?.length > 0) {
    storeVariable.push([t("Zari_Used"), form?.zariUsed]);
  }
  if (form?.purity?.length > 0) {
    storeVariable.push([t("Purity_of_Zari"), form?.purity]);
  }
  if (form?.handloomMark?.length > 0) {
    storeVariable.push([t("Handloom_Mark"), form?.handloomMark]);
  }
  if (form?.silkMark?.length > 0) {
    storeVariable.push([t("Silk_Mark"), form?.silkMark]);
  }
  if (form?.madeInIndia === true) {
    storeVariable.push([t("Made_in_India"), form?.madeInIndia]);
  }
  if (form?.yarnType?.length > 0) {
    storeVariable.push([t("Yarn_Type"), form?.yarnType]);
  }
  if (form?.productionTimeInMonths?.length > 0) {
    storeVariable.push([
      t("Time_Taken_in_months"),
      form?.productionTimeInMonths,
    ]);
  }
  if (form?.workingHours?.length > 0) {
    storeVariable.push([t("Working_Hours"), form?.workingHours]);
  }
   if (form?.sellPrice?.length > 0) {
    storeVariable.push(["Selling Price", form?.sellPrice]);
  }
  if (form?.costPrice?.length > 0) {
    storeVariable.push(["Cost Price", form?.costPrice]);
  }
  if (form?.designNum?.length > 0) {
    storeVariable.push(["Design Number", form?.designNum]);
  }
  // if (form?.loomNum?.length > 0) {
  //   storeVariable.push([t("Loom_Num"), form?.loomNum]);
  // }
  let pinImages: any = [];
  let unpinImages: any = [];
  let logoImages: any = [];
  if (form?.profileURL?.length > 0) {
    form.profileURL.map((ele: any) => {
      if (ele?.pin === 1 && ele?.show === 1) {
        pinImages.push(ele?.mediaUrl);
      } else if (ele?.show === 1) {
        unpinImages.push(ele?.mediaUrl);
      }
    });
    logoImages = [...pinImages, ...unpinImages];
  }
    console.log("form",form,logoImages)

  const handleOpen = (data: any) => {
    if (data?.name === t("Designing")) {
      setIsOpen({
        design: true,
      });
    }
    if (data?.name === t("Weaving")) {
      setIsOpen({
        weaving: true,
      });
    }
    if (data?.name === t("Dying")) {
      setIsOpen({
        dying: true,
      });
    }
    if (data?.name === t("Finishing")) {
      setIsOpen({
        finishing: true,
      });
    }
    if (data?.name === "mainLogo") {
      setIsOpen({
        mainLogo: true,
      });
    }
  };
  let ProductJourneyArry: any = [];
  if (form?.designer?.length > 0 || form?.designerMedia?.length) {
    ProductJourneyArry.push({
      media: form?.designerMedia,
      karigar: form?.designer,
      name: t("Designing"),
      photoProcess: t("Photos_of_Designing_Process"),
      karigarBy: t("Designed_by"),
      handleOpen: handleOpen,
      uploadedBy: form?.designUploadBy,
      icon: <QRDesignIcon />,
      svg: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.43976 8.82741C-0.144783 8.43067 -0.144783 7.56933 0.43976 7.17259L10.4186 0.399647C11.0825 -0.0510104 11.9802 0.424599 11.9802 1.22706L11.9802 14.7729C11.9802 15.5754 11.0825 16.051 10.4186 15.6004L0.43976 8.82741Z"
            fill="#1A1A1A"
          />
        </svg>
      ),
    });
  }
  if (form?.dyer?.length || form?.dyerMedia?.length) {
    ProductJourneyArry.push({
      media: form?.dyerMedia,
      karigar: form?.dyer,
      name: t("Dying"),
      photoProcess: t("Photos_of_Dying_Process"),
      karigarBy: t("Karigar"),
      handleOpen: handleOpen,
      uploadedBy: form?.dyingUploadBy,
      icon: <QrWeaveIcon />,
      svg: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.43976 8.82741C-0.144783 8.43067 -0.144783 7.56933 0.43976 7.17259L10.4186 0.399647C11.0825 -0.0510104 11.9802 0.424599 11.9802 1.22706L11.9802 14.7729C11.9802 15.5754 11.0825 16.051 10.4186 15.6004L0.43976 8.82741Z"
            fill="#1A1A1A"
          />
        </svg>
      ),
    });
  }
  if (form?.weaver?.length || form?.weaverMedia?.length) {
    ProductJourneyArry.push({
      media: form?.weaverMedia,
      karigar: form?.weaver,
      name: t("Weaving"),
      photoProcess: t("Photos_of_Weaving_Process"),
      karigarBy: t("Karigar"),
      handleOpen: handleOpen,
      uploadedBy: form?.weavingUploadBy,
      icon: <QrWeaveIcon />,
      svg: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.43976 8.82741C-0.144783 8.43067 -0.144783 7.56933 0.43976 7.17259L10.4186 0.399647C11.0825 -0.0510104 11.9802 0.424599 11.9802 1.22706L11.9802 14.7729C11.9802 15.5754 11.0825 16.051 10.4186 15.6004L0.43976 8.82741Z"
            fill="#1A1A1A"
          />
        </svg>
      ),
    });
  }
  if (form?.finisher?.length || form?.finisherMedia?.length) {
    ProductJourneyArry.push({
      media: form?.finisherMedia,
      karigar: form?.finisher,
      name: t("Finishing"),
      photoProcess: t("Photos_of_Finalization_Process"),
      karigarBy: t("Karigar"),
      handleOpen: handleOpen,
      uploadedBy: form?.finishingUploadBy,
      icon: <QRFinishingIcon />,
      svg: (
        <svg
          className="leftArrow"
          width="20"
          height="20"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5612 7.17259C12.1458 7.56933 12.1458 8.43067 11.5612 8.82741L1.58241 15.6004C0.91844 16.051 0.020817 15.5754 0.020817 14.7729L0.0208176 1.22706C0.0208177 0.424601 0.918442 -0.0510087 1.58241 0.399648L11.5612 7.17259Z"
            fill="#1A1A1A"
          />
        </svg>
      ),
    });
  }
  return (
    <>
      {isOpen.mainLogo && (
        <ProductImagePreView
          {...props}
          openModal={isOpen.mainLogo}
          closeModal={closeModal}
          images={[
            selectedLogoImage,
            ...logoImages.filter((ele: any) => !(ele === selectedLogoImage)),
          ]}
        />
      )}
      {isOpen.design && (
        <ProductImagePreView
          {...props}
          openModal={isOpen.design}
          closeModal={closeModal}
          images={form?.designerMedia}
        />
      )}
      {isOpen.weaving && (
        <ProductImagePreView
          {...props}
          openModal={isOpen.weaving}
          closeModal={closeModal}
          images={form?.weaverMedia}
        />
      )}
      {isOpen.dying && (
        <ProductImagePreView
          {...props}
          openModal={isOpen.dying}
          closeModal={closeModal}
          images={form?.dyerMedia}
        />
      )}
      {isOpen.finishing && (
        <ProductImagePreView
          {...props}
          openModal={isOpen.finishing}
          closeModal={closeModal}
          images={form?.finisherMedia}
        />
      )}
      {Loading || loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <>
          <div className="BannerDiv">
            <div className="lines">
              <div className="line1"></div>
              <div className="line2"></div>
            </div>
            <div className="lines1">
              <div className="line1"></div>
              <div className="line2"></div>
            </div>
            <img
              className="BannerImage"
              src={BannerBackground}
              alt="Banner"
              width={"100%"}
            />
            <div className="BannerContent">
              <div className="cerDiv">
                <div className="certificationAuthenticity">
                  Certificate of Authenticity <br />
                </div>
              </div>
              <div className="cerDiv1">
                <div className="certificationAuthenticityDes">
                  {/* <p>
                    This certificate is to certify that the{" "}
                    <span className="TextDec">{form?.productName} </span> you
                    have purchased is a genuine and authentic product created
                    with the <span className="TextDec">{form?.org}</span>
                  </p> */}
                </div>
              </div>
              <div className="Banner1">
                <div>
                  {form?.orgLogo?.length > 0 ? (
                    <img src={form?.orgLogo} alt="logo" className="leftImage" />
                  ) : (
                    <div className="orgName">{form?.org}</div>
                  )}
                </div>
                <div className="line"></div>
                <div>
                  <img src={Badge} alt="logo" className="rightImage" />
                </div>
              </div>
              <div className="Banner2">
                <p className="Floral text-capitalize">
                  {form?.productName}

                  {/* ‘Virasat’ – Floral Kadhwa Red Banarasi Katan Silk Jangla Saree */}
                </p>
              </div>
              <div className="Banner2">
                <div className="cerDiv1">
                  <div className="certificationAuthenticityDes">
                    <p>
                      This certificate is to certify that the{" "}
                      <span className="TextDec">{form?.productName} </span> you
                      are viewing is a genuine and authentic handmade product
                      created by <span className="TextDec">{form?.org}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Banner2">
                <div className="Verified">
                  <div>
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M36.2016 44.5033C34.1235 44.0961 32.5831 44.9687 31.2752 46.5247C30.7084 47.2082 30.0545 47.819 29.3715 48.3861C26.654 50.6693 22.7448 50.5093 20.2016 48.0371C19.3733 47.2228 18.545 46.3938 17.7312 45.5649C17.0191 44.8378 16.1617 44.4888 15.1444 44.4888C13.8946 44.4888 12.6303 44.5324 11.3951 44.4015C8.09628 44.0234 5.55314 41.144 5.49501 37.8138C5.48048 36.7959 5.48048 35.7779 5.49501 34.7599C5.49501 33.8001 5.16077 32.9858 4.49228 32.3168C3.69301 31.5024 2.86467 30.7026 2.0654 29.8882C-0.681195 27.0961 -0.695728 22.8933 2.0654 20.1158C2.85014 19.3159 3.64941 18.5161 4.44869 17.7308C5.16077 17.0328 5.49501 16.1893 5.50954 15.1859C5.50954 13.9207 5.46594 12.641 5.61127 11.3758C5.97457 8.11829 8.86649 5.57337 12.1653 5.50066C13.1826 5.47157 14.1998 5.48611 15.2171 5.50066C16.2053 5.5152 17.0191 5.16618 17.7166 4.46815C18.5304 3.65377 19.3297 2.8394 20.1435 2.03957C22.8901 -0.679856 27.119 -0.679856 29.8801 2.03957C30.6794 2.82486 31.4787 3.62469 32.2634 4.43906C32.9755 5.16618 33.8329 5.5152 34.8501 5.5152C36.0999 5.5152 37.3642 5.47157 38.5995 5.60245C41.8983 5.98055 44.4269 8.8454 44.4995 12.1902C44.5286 13.2081 44.5141 14.2261 44.4995 15.2441C44.4995 16.2039 44.8338 17.0182 45.5023 17.6872C46.3161 18.5161 47.1589 19.3305 47.9728 20.1594C50.6758 22.9079 50.6758 27.1106 47.9728 29.8591C47.1735 30.6735 46.3597 31.4879 45.5459 32.2877C44.8338 32.9858 44.4995 33.8292 44.485 34.8326C44.485 36.0833 44.5431 37.3485 44.3978 38.5846C44.0054 41.9584 41.1281 44.4742 37.7275 44.5179C37.2189 44.5179 36.7103 44.5033 36.2016 44.5033ZM9.30246 24.9293C9.30246 33.771 16.307 40.4751 24.5177 40.6932C33.7893 40.9404 40.4886 33.6111 40.6776 25.4528C40.881 16.2039 33.5858 9.45618 25.3606 9.29622C16.3361 9.12171 9.33152 16.3784 9.30246 24.9293Z"
                        fill="#8C673E"
                      />
                      <path
                        d="M14.1162 28.142L15.001 17.8167L29.4459 13.8341L37.1107 18.8492V28.142L29.4459 36.4022L19.4226 34.9272L14.1162 28.142Z"
                        fill="#8C673E"
                      />
                      <path
                        d="M9.29688 24.9292C9.32594 16.3783 16.3305 9.12167 25.355 9.29617C33.5802 9.45614 40.8754 16.2038 40.672 25.4528C40.4831 33.6111 33.7837 40.9259 24.5121 40.6932C16.3014 40.4751 9.29688 33.771 9.29688 24.9292ZM22.7537 27.3287C21.5476 26.0926 20.5012 25.0165 19.4404 23.9549C18.612 23.126 17.5657 23.0824 16.8391 23.8095C16.098 24.5366 16.1561 25.5837 16.9844 26.4126C18.3505 27.7941 19.731 29.1611 21.097 30.5281C22.2451 31.6769 23.1025 31.6915 24.2505 30.5426C27.1134 27.6778 29.9762 24.8129 32.8391 21.9481C33.0135 21.7735 33.2024 21.5845 33.3477 21.3809C34.0162 20.4647 33.6238 19.1559 32.5775 18.7633C31.7928 18.4724 31.1388 18.6906 30.5575 19.2868C28.1016 21.759 25.6311 24.2167 23.1752 26.6889C23.0008 26.8488 22.9136 27.067 22.7537 27.3287Z"
                        fill="#F5F5F5"
                      />
                    </svg>
                  </div>
                  <div className="d1">
                    Product Verified by Hastakala Pramanak
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Card className="CardViewProductHeader">
            <LanguageSwitcher />
            <div className="tableProductHeader">{t("Product_Details")}</div>
            <Row className="mt-3">
              <Col xs={12} md={4}>
                {logoImages?.length ? (
                  <>
                    {" "}
                    <CarouselForProduct
                      images={logoImages}
                      handleOpen={handleOpen}
                      handleSetSelectedImage={handleSetSelectedImage}
                    />
                  </>
                ) : (
                  <div className="NoPreview">{t("No_Preview_Available")}</div>
                )}
              </Col>
              <Col xs={12} md={8} className="heightPro">
                {storeVariable.map((ele: any, i: any) =>
                  i % 2 === 0 || i === 0 ? (
                    // ele[0] === t("Handloom_Mark") ||
                    ele[0] === t("Made_in_India") ? (
                      // ele[0] === t("Silk_Mark")
                      <Row className="RowTextPro">
                        <Col xs={6} md={4} className="ColTextPro">
                          <div className="Text">{ele[0]}:</div>
                        </Col>
                        <Col xs={6} md={6} className="ColTextPro1">
                          <div className="Text">
                            <Toggle
                              size="lg"
                              defaultChecked={true}
                              checked={ele[1]}
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="RowTextPro">
                        <Col xs={6} md={4} className="ColTextPro">
                          <div className="Text">{ele[0]}:</div>
                        </Col>
                        <Col xs={6} md={6} className="ColTextPro1">
                          <div className="Text">{ele[1]}</div>
                        </Col>
                      </Row>
                    )
                  ) : // ele[0] === t("Handloom_Mark") ||
                    ele[0] === t("Made_in_India") ? (
                      // ||
                      // ele[0] === t("Silk_Mark")
                      <Row className="RowTextPro1">
                        <Col xs={6} md={4} className="ColTextPro">
                          <div className="Text">{ele[0]}:</div>
                        </Col>
                        <Col xs={6} md={6} className="ColTextPro1">
                          <div className="Text">
                            <Toggle
                              size="lg"
                              defaultChecked={true}
                              checked={ele[1]}
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="RowTextPro1">
                        <Col xs={6} md={4} className="ColTextPro">
                          <div className="Text">{ele[0]}:</div>
                        </Col>
                        <Col xs={6} md={6} className="ColTextPro1">
                          <div className="Text">{ele[1]}</div>
                        </Col>
                      </Row>
                    )
                )}
              </Col>
            </Row>
            <div className="ProductJourneyPart">
              <div className="ProductJourneyText">{t("Product_Journey")}</div>
              {ProductJourneyArry?.length > 0 &&
                ProductJourneyArry?.map((ele: any, i: any) => {
                  if (i % 2 === 0 || i === 0) {
                    return (
                      <Row className="Row" key={i}>
                        <Col xs={2} sm={2} md={6} className="verticleLine">
                          <span className="float-end Icon1">{ele?.icon}</span>
                        </Col>
                        <Col xs={10} sm={10} md={6}>
                          <CommonCard
                            media={ele?.media}
                            karigar={ele?.karigar}
                            name={ele?.name}
                            photoProcess={ele?.photoProcess}
                            karigarBy={ele?.karigarBy}
                            handleOpen={ele?.handleOpen}
                            uploadedBy={ele?.uploadedBy}
                            svg={<svg
                              width="20"
                              height="20"
                              viewBox="0 0 12 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.43976 8.82741C-0.144783 8.43067 -0.144783 7.56933 0.43976 7.17259L10.4186 0.399647C11.0825 -0.0510104 11.9802 0.424599 11.9802 1.22706L11.9802 14.7729C11.9802 15.5754 11.0825 16.051 10.4186 15.6004L0.43976 8.82741Z"
                                fill="#1A1A1A"
                              />
                            </svg>}
                          />
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row className="Row " key={i}>
                        <Col xs={2} sm={2} md={6} className="verticleLine ">
                          <span className="float-end Icon1">{ele?.icon}</span>
                          <div className="leftCard mobileLeftCard">
                            <CommonCard
                              media={ele?.media}
                              karigar={ele?.karigar}
                              name={ele?.name}
                              photoProcess={ele?.photoProcess}
                              karigarBy={ele?.karigarBy}
                              handleOpen={ele?.handleOpen}
                              uploadedBy={ele?.uploadedBy}
                              svg={
                                <svg
                                  className="leftArrow"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 12 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.5612 7.17259C12.1458 7.56933 12.1458 8.43067 11.5612 8.82741L1.58241 15.6004C0.91844 16.051 0.020817 15.5754 0.020817 14.7729L0.0208176 1.22706C0.0208177 0.424601 0.918442 -0.0510087 1.58241 0.399648L11.5612 7.17259Z"
                                    fill="#1A1A1A"
                                  />
                                </svg>
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={10} sm={10} md={6}>
                          <div className=" mobileLeftCard1">
                            <CommonCard
                              media={ele?.media}
                              karigar={ele?.karigar}
                              name={ele?.name}
                              photoProcess={ele?.photoProcess}
                              karigarBy={ele?.karigarBy}
                              handleOpen={ele?.handleOpen}
                              uploadedBy={ele?.uploadedBy}
                              svg={
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 12 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.43976 8.82741C-0.144783 8.43067 -0.144783 7.56933 0.43976 7.17259L10.4186 0.399647C11.0825 -0.0510104 11.9802 0.424599 11.9802 1.22706L11.9802 14.7729C11.9802 15.5754 11.0825 16.051 10.4186 15.6004L0.43976 8.82741Z"
                                    fill="#1A1A1A"
                                  />
                                </svg>
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  }
                })}
            </div>
          </Card>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    ViewProductDetails: state?.productReducer?.productCertificationDetails,
    ViewProductDetails1: state?.productReducer?.productDetailAfterToken,
  };
};
export default connect(mapStateToProps, {
  getProductByIdAfterKycToken,
})(QRCodeProduct);
