import React from 'react'
import { Card } from "@mui/material"
const HastakalaCard = (props:any) => {
    const { data } = props;
    return (
        <>
            <Card className='hastakala-card'>
                <img src={data?.icon} width={"74px"} height="44px" alt="Enhanced visibility" />
                <div className='mt-1 content'>
                    {data?.content}
                </div>
            </Card>
        </>
    )
}

export default HastakalaCard