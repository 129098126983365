import React from 'react'
import { Grid } from "@mui/material";

const MobileBenifitsStockholders = (props) => {
    const { data} = props
    return (
        <div className='mobile-benifits-div'>
            <h2>{data?.title}</h2>

            <Grid container>
                <Grid item xs={12}>
                    <img
                        src={data?.image}
                        with="100%"
                        height="295px"
                        alt=""></img>
                </Grid>
                <Grid item xs={12} className='bottom-part'>
                    <ul className="description-points">
                        {
                            data?.points.map((ele, i) => (<li  key={i}><span>{ele?.content}</span></li>))
                        }

                    </ul>
                </Grid>
            </Grid>
        </div>
    )
}

export default MobileBenifitsStockholders