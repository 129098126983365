import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Slider from "react-slick";
import ReactPlayer from "react-player/file";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ProductImagePreView = (props: any) => {
  const { openModal, closeModal, images }: any = props;
  var settings: any = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const zoomIn = () => {
    var pic = document.getElementById("pic") as HTMLElement;
    var width = pic.clientWidth;
    var height = pic.clientHeight;
    pic.style.width = width + 100 + "px";
    pic.style.height = height + 50 + "px";
  };
  const zoomOut = () => {
    var pic = document.getElementById("pic") as HTMLElement;
    var width = pic.clientWidth;
    var height = pic.clientHeight;
    pic.style.width = width - 50 + "px";
    pic.style.height = height - 50 + "px";
  };
  let zoomDiv: any;
  if (props?.name === "editProduct") {
    if (images?.length) {
      images.map((item: any, i: any) => {
        if (
          item?.type === "image/png" ||
          item?.type === "image/jpg" ||
          item?.type === "image/jpeg" || item?.type === "image/bmp"
        ) {
          zoomDiv = true;
        }
      });
    }
  } else {
    if (images?.length) {
      images.map((fileUrl: any, i: any) => {
        if (
          fileUrl.toLowerCase().endsWith(".jpg") ||
          fileUrl.toLowerCase().endsWith(".jpeg") ||
          fileUrl.toLowerCase().endsWith(".png") || fileUrl.toLowerCase().endsWith(".bmp")
        ) {
          zoomDiv = true;
        }
      });
    }
  }
  return (
    <div className="ModalDiaLOgue">
      <Modal
        isOpen={openModal}
        toggle={closeModal}
        size="lg"
        className="QRModalPopUp"
      >
        <ModalHeader>
          {zoomDiv === true ? (
            <div className="ZoomDiv">
              <svg
                onClick={() => zoomIn()}
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.77779 12.5556C9.96877 12.5556 12.5556 9.96877 12.5556 6.77779C12.5556 3.5868 9.96877 1 6.77779 1C3.5868 1 1 3.5868 1 6.77779C1 9.96877 3.5868 12.5556 6.77779 12.5556Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0001 13.9996L10.8584 10.8579"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.77734 4.61096V8.9443"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.61133 6.7782H8.94467"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span></span>
              <svg
                onClick={() => zoomOut()}
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.77779 12.5556C9.96877 12.5556 12.5556 9.96877 12.5556 6.77779C12.5556 3.5868 9.96877 1 6.77779 1C3.5868 1 1 3.5868 1 6.77779C1 9.96877 3.5868 12.5556 6.77779 12.5556Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0001 13.9996L10.8584 10.8579"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.61133 6.7782H8.94467"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div></div>
          )}

          <div className="Heading">
            <svg
              onClick={() => closeModal()}
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="4" fill="#262D22" />
              <path
                d="M22 12L12 22"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12L22 22"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <Slider {...props} {...settings}>
            {props?.name === "editProduct"
              ? images?.length > 0
                ? images.map((item: any) => (
                  <>
                    <div className="imageDiv">
                      {(item?.type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        item.type === "application/msword" ||
                        item?.type === "application/pdf") && (
                          <>
                            <iframe
                              title={`${(new Date() as any) / 1000}`}
                              src={`https://docs.google.com/viewer?url=${item?.blobImage}&embedded=true`}
                              frameBorder="0"
                            ></iframe>
                          </>
                        )}
                      {(item?.type === "image/png" ||
                        item?.type === "image/jpg" ||
                        item?.type === "image/jpeg" || item?.type === "image/bmp") && (
                          <>
                            {" "}
                            <img
                              id="pic"
                              src={item?.blobImage}
                              alt="Designing"
                            />
                          </>
                        )}
                      {(item?.type === "video/mp4" ||
                        item?.type === "video/webm" ||
                        item?.type === "video/ogg") && (
                          <>
                            {/* <video {...props} controls muted>
                              <source
                                {...props}
                                src={item?.blobImage}
                                type="video/mp4"
                              />
                          </video> */}
                          <ReactPlayer
                            url={item?.blobImage}
                            playing={false}
                            controls={true}
                            muted={true}
                          />
                          </>
                        )}
                    </div>
                  </>
                ))
                : ""
              : images?.length > 0
                ? images.map((fileUrl: any) => (
                  <>
                    <div className="imageDiv">
                      {(fileUrl.toLowerCase().endsWith(".docx") ||
                        fileUrl.toLowerCase().endsWith(".doc") ||
                        fileUrl.toLowerCase().endsWith(".pdf")) && (
                          <>
                            <iframe
                              title={`${(new Date() as any) / 1000}`}
                              src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
                              frameBorder="0"
                            ></iframe>
                          </>
                        )}
                      {(fileUrl.toLowerCase().endsWith(".jpg") ||
                        fileUrl.toLowerCase().endsWith(".jpeg") ||
                        fileUrl.toLowerCase().endsWith(".png") || fileUrl.toLowerCase().endsWith(".bmp")) && (
                          <>
                            {" "}
                            <img
                              id="pic"
                              src={fileUrl}
                              alt="Designing"
                              width="auto"
                            />
                          </>
                        )}
                      {(fileUrl.toLowerCase().endsWith(".mp4") ||
                        fileUrl.toLowerCase().endsWith(".webm") ||
                        fileUrl.toLowerCase().endsWith(".ogg")) && (
                          <>
                            {/* <video controls>
                              <source src={fileUrl} type="video/mp4" />
                            </video> */}
                          <ReactPlayer
                             playing={false}
                             controls={true}
                            url={fileUrl}
                            muted={true}
                            height={400}
                            // light={true}
                            // playsinline={true}
                          />
                          </>
                        )}
                    </div>
                  </>
                ))
                : ""}
          </Slider>
          {/* <FilePreviewViewProduct
            fileUrl={preview}
            height={500}
            width={"100%"}
          /> */}
        </ModalBody>
        {/* <ModalFooter>
          <div className="ZoomDiv">
            <svg
              onClick={() => zoomIn()}
              width="25"
              height="25"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.77779 12.5556C9.96877 12.5556 12.5556 9.96877 12.5556 6.77779C12.5556 3.5868 9.96877 1 6.77779 1C3.5868 1 1 3.5868 1 6.77779C1 9.96877 3.5868 12.5556 6.77779 12.5556Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0001 13.9996L10.8584 10.8579"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.77734 4.61096V8.9443"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.61133 6.7782H8.94467"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span></span>
            <svg
              onClick={() => zoomOut()}
              width="25"
              height="25"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.77779 12.5556C9.96877 12.5556 12.5556 9.96877 12.5556 6.77779C12.5556 3.5868 9.96877 1 6.77779 1C3.5868 1 1 3.5868 1 6.77779C1 9.96877 3.5868 12.5556 6.77779 12.5556Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0001 13.9996L10.8584 10.8579"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.61133 6.7782H8.94467"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalFooter> */}
      </Modal>
    </div>
  );
};

export default ProductImagePreView;
