import React, { useState, useEffect } from "react";
import { Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";

//components
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import {
  createCategory,
  updateCategory,
  getCategoryFilter,
} from "../../Store/actions";
import { getTypes } from "../../Store/type/actions";
import { getArtforms } from "../../Store/actions";
let artformOptions: any = [];
let typeOptions: any = [];
var formDetails: any = [];

const AddOrEditCategoryForm = (props: any) => {
  const [loading, setLoading]: any = React.useState(false);
  const [artformName, setArtformName]: any = useState("");
  const [filterName, setFilterName]: any = useState("");
  const [filterValue, setFilterValue]: any = useState("");

  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          artform: {
            label: props?.editData?.artform?.name,
            value: props.editData?.artform?._id,
          },
          type: {
            label: props.editData?.type?.name,
            value: props.editData?.type?._id,
          },
          category: props.editData?.name,
        }
      : props?.form?.type
      ? {
          artform: {
            label: props?.form?.artform?.label,
            value: props?.form?.artform?.value,
          },
          type: {
            label: props?.form?.type?.label,
            value: props?.form?.type?.value,
          },
          category: "",
        }
      : {
          type: "",
          category: "",
          artform: "",
        }
  );
  const [Error, setError]: any = React.useState({
    type: "",
    category: "",
    artform: "",
  });
  useEffect(() => {
    getArtformData();
  }, []);
  useEffect(() => {
    if (form?.artform?.value && !form?.type?.value && !form?.category) {
      getTypesData();
    }
  }, [form]);
  // useEffect(() => {
  //   getArtformData();
  // }, [artformName]);
  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: artformName || data?.value,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        typeName: filterName || data?.value,
        artFormId: form?.artform?.value || data?.artform,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (form?.artform === "") {
      error = "Required";
      err = {
        ...err,
        artform: "ArtForm is Required!",
      };
    }
    if (form?.type === "") {
      error = "Required";
      err = {
        ...err,
        type: "Type is Required!",
      };
    }
    if (form?.category === "") {
      error = "Required";
      err = {
        ...err,
        category: "Category is Required!",
      };
    }
    setError(err);
    return error;
  };
  const handleSubmitChange = (type: any) => {
    if (type?.name === "artform") {
      setForm({
        ...form,
        type: "",
        artform: type?.value,
      });
      formDetails = { ...formDetails, type: "", artform: type?.value };
    } else {
      setForm({ ...form, [type.name]: type?.value });
      formDetails = { ...formDetails, [type.name]: type?.value };
    }
  };
  const handleSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      setLoading(true);
      props?.editData?._id
        ? props.updateCategory(
            {
              categoryId: props?.editData?._id,
              typeId: form?.type?.value,
              name: form?.category,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          )
        : props.createCategory(
            {
              name: form?.category,
              typeId: form?.type?.value,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
                props.handleName({
                  name: "category",
                  type: form?.type?.value,
                  category: form?.category,
                });
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          );
    }
  };
  const handleOnInputChange = (type: any) => {
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
      if (type?.value.length === 0 && !form?.artform?.value) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        form?.artform?.value &&
        !form?.type?.value &&
        !form?.category
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: form?.artform?.value,
        });
      }
    }
  };

  const callingGetCategory = (value: any) => {
    props.getCategoryFilter(
      {
        offset: 0,
        limit: 10,
        artformId: formDetails?.artform?.value,
        typeId: formDetails?.type?.value,
        categoryName: value?.value,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (value?.value === ele?.name) {
              setFilterValue(ele?.name);
            }
          });
        }
      }
    );
  };
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "category") {
        callingGetCategory(data?.value);
      }
      if (data?.name === "artform") {
        getArtformData(data);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
    }, 1000),
    []
  );
  return (
    <>
      <div className="AddCategoryForm">
        <div className="form">
          {loading ? (
            <div className="LoaderDivModal">
              <Loader />
            </div>
          ) : (
            <form>
              <div className="formDiv">
                <Label>
                  Artform<span>*</span>
                </Label>

                <Select
                  className="React_selector text-white"
                  classNamePrefix="react-select"
                  placeholder="Select Artform"
                  isSearchable
                  name="artform"
                  id="artform"
                  value={form?.artform}
                  options={artformOptions}
                  onChange={(e: any) => {
                    handleSubmitChange({
                      name: "artform",
                      value: e,
                    });
                  }}
                  onInputChange={(e) =>
                    handleOnInputChange({
                      name: "artform",
                      value: e,
                    })
                  }
                />
                {Error && !form?.artform ? (
                  <p className="RequiredField">{Error?.artform} </p>
                ) : (
                  ""
                )}
              </div>
              <div className="formDiv">
                <Label>
                  Type<span>*</span>
                </Label>

                <Select
                  className="React_selector text-white"
                  classNamePrefix="react-select"
                  placeholder="Select Type"
                  isSearchable
                  name="Type"
                  id="Type"
                  value={form?.type}
                  options={
                    form?.artform?.value
                      ? typeOptions
                      : [
                          {
                            label: (
                              <p className="req">***Please select Artform***</p>
                            ),
                            value: "",
                          },
                        ]
                  }
                  onChange={(e: any) => {
                    handleSubmitChange({
                      name: "type",
                      value: e,
                    });
                  }}
                  onInputChange={(e) =>
                    handleOnInputChange({
                      name: "type",
                      value: e,
                    })
                  }
                />
                {Error.type && !form?.type ? (
                  <p className="RequiredField">{Error?.type} </p>
                ) : (
                  ""
                )}
              </div>
              <div className="formDiv">
                <Label>
                  Category<span>*</span>
                </Label>
                <Input
                  name="category"
                  id="category"
                  className="form-control"
                  placeholder="Type Name"
                  value={form?.category}
                  onChange={(e: any) => {
                    handleSubmitChange({
                      name: "category",
                      value: e.target.value,
                    });
                    handleChangeDebounce({
                      name: "category",
                      artform: form?.artform?.value,
                      type: form?.type?.value,
                    });
                  }}
                />
                {Error.category && !form?.category ? (
                  <p className="RequiredField">{Error?.category} </p>
                ) : (
                  ""
                )}
                {form?.category && form?.category === filterValue ? (
                  // filterValue?.product === ""
                  <p className="RequiredField">Category is already exist's</p>
                ) : (
                  ""
                )}
              </div>
              <Button
                className="artButton"
                type="button"
                onClick={() => handleSubmit()}
              >
                {loading ? "Processing" : "Save"}
              </Button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createCategory,
  updateCategory,
  getTypes,
  getArtforms,
  getCategoryFilter,
})(AddOrEditCategoryForm);
