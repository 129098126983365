import { types } from "./actionTypes";
const initialState = {
  getArtformDetails: null,
  updateArtformDetails: null,
  createArtformDetails: null,
  deleteArtformDetails: null,
  getArtformFilterDetails:null
};
const artformReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ARTFORM_SUCCESS:
      state = {
        ...state,
        getArtformDetails: action.payload,
      };
      return state;
      case types.GET_ARTFORM_FILTERS_SUCCESS:
      state = {
        ...state,
        getArtformFilterDetails: action.payload,
      };
      return state;
    case types.CREATE_ARTFORM_SUCCESS:
      state = {
        ...state,
        createArtformDetails: action.payload,
      };
      return state;
    case types.UPDATE_ARTFORM_SUCCESS:
      state = {
        ...state,
        updateArtformDetails: action.payload,
      };
      return state;
    case types.DELETE_ARTFORM_SUCCESS:
      state = {
        ...state,
        deleteArtformDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default artformReducer;
