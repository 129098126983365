import React from "react";

const HideIcon = (props: any) => {
  const { index, name, onClick, pin } = props;
  return (
    <svg
      className="showOrHide"
      onClick={() =>
        onClick({
          name,
          index,
          show: true,
          pin,
        })
      }
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="26" rx="4" fill="white" />
      <path
        d="M17.05 17.0505C15.8845 17.9389 14.4653 18.431 13 18.455C8.22727 18.455 5.5 13.0005 5.5 13.0005C6.34811 11.4199 7.52441 10.0391 8.95 8.95046M11.5682 7.70955C12.0375 7.5997 12.518 7.54478 13 7.54592C17.7727 7.54592 20.5 13.0005 20.5 13.0005C20.0861 13.7747 19.5925 14.5037 19.0273 15.1755M14.4455 14.4459C14.2582 14.6469 14.0324 14.8081 13.7815 14.9199C13.5306 15.0317 13.2597 15.0918 12.9851 15.0966C12.7104 15.1015 12.4376 15.0509 12.1829 14.9481C11.9282 14.8452 11.6969 14.6921 11.5026 14.4978C11.3084 14.3036 11.1553 14.0722 11.0524 13.8175C10.9495 13.5628 10.899 13.29 10.9038 13.0154C10.9087 12.7408 10.9688 12.4699 11.0806 12.219C11.1924 11.9681 11.3536 11.7423 11.5545 11.555"
        stroke="#404B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 5.5L20.5 20.5"
        stroke="#404B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HideIcon;
