import React from "react";

const ProductDesignIcon = () => {
  return (
    <svg
      className="svg1"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#C59C6C" />
      <path
        d="M42.9996 30.7658C42.9319 31.199 42.8845 31.6322 42.8033 32.0654C42.3158 34.6783 41.0564 36.8579 39.059 38.5976C37.2309 40.1883 35.098 41.0954 32.6944 41.3594C31.5704 41.4812 30.4465 41.4338 29.3361 41.2037C28.0699 40.9397 27.3725 39.7348 27.6298 38.3607C27.8329 37.3114 27.6908 36.3232 27.0204 35.4567C26.6684 35.0032 26.2147 34.6918 25.7069 34.4346C25.585 34.3737 25.4632 34.3195 25.3345 34.2586C25.3684 33.0808 25.3954 31.9097 25.4293 30.7184C26.2079 30.7522 26.8715 30.5018 27.3793 29.8993C27.7585 29.4526 27.9277 28.9381 27.9142 28.356C27.8871 27.0834 27.0001 26.3049 25.4022 26.1425C25.2668 24.4028 25.1246 22.6564 24.9824 20.8423C25.3751 20.6054 25.822 20.3211 26.2892 20.0706C28.0632 19.1094 29.9522 18.534 31.9835 18.5544C34.4819 18.5814 36.6757 19.4614 38.6053 21.0183C40.7111 22.7106 42.0923 24.8767 42.7085 27.5166C42.8236 28.0243 42.871 28.5523 42.9522 29.0667C42.9658 29.1344 42.9793 29.1953 42.9996 29.263C42.9996 29.7504 42.9996 30.2581 42.9996 30.7658ZM29.9522 26.3997C31.2251 26.3929 32.234 25.364 32.2272 24.0982C32.2204 22.8459 31.171 21.8238 29.9048 21.8374C28.659 21.8509 27.6501 22.8798 27.6569 24.1456C27.6704 25.3911 28.6996 26.4065 29.9522 26.3997ZM35.1251 31.5916C35.1251 32.8439 36.1543 33.8592 37.4069 33.8592C38.673 33.8592 39.6954 32.8303 39.6954 31.5645C39.6887 30.3055 38.6527 29.2833 37.3866 29.2901C36.134 29.3036 35.1251 30.3325 35.1251 31.5916ZM35.3824 35.8899C35.3824 34.6309 34.3668 33.602 33.121 33.602C31.848 33.602 30.8189 34.6174 30.8189 35.8764C30.8189 37.1355 31.848 38.1711 33.1074 38.1711C34.36 38.1711 35.3824 37.149 35.3824 35.8899ZM33.5543 25.689C33.5543 26.948 34.5564 27.9701 35.8158 27.9769C37.0887 27.9837 38.1178 26.9683 38.1178 25.7025C38.1246 24.4434 37.0819 23.4078 35.8225 23.4078C34.5699 23.4145 33.5543 24.4299 33.5543 25.689Z"
        fill="white"
      />
      <path
        d="M17 42.9975C17 42.4966 17 42.0024 17 41.5015C17.1625 41.4677 17.3115 41.4474 17.4536 41.4C18.1172 41.1901 18.5641 40.5742 18.5776 39.8702C18.5911 39.2136 18.5438 38.5434 18.6318 37.8936C18.8417 36.3299 20.2839 35.213 21.8615 35.3349C23.4188 35.45 24.6578 36.7767 24.6714 38.3404C24.6917 40.6486 22.9922 42.6184 20.7036 42.9501C20.6563 42.9569 20.6089 42.9772 20.5615 42.9975C19.3698 42.9975 18.1849 42.9975 17 42.9975Z"
        fill="white"
      />
      <path
        d="M23.9063 30.7522C22.3761 30.7522 20.873 30.7522 19.3563 30.7522C19.3495 30.6574 19.3428 30.5762 19.3428 30.495C19.4105 28.1394 19.4443 25.7769 19.5662 23.4213C19.6475 21.817 19.8303 20.2195 20.3178 18.6762C20.4396 18.2768 20.6157 17.8774 20.8391 17.5322C21.2792 16.8215 21.9428 16.8282 22.4167 17.5187C22.8568 18.1685 23.0396 18.9199 23.1954 19.6712C23.5745 21.4447 23.7167 23.2521 23.7912 25.0594C23.8657 26.86 23.8725 28.6606 23.9063 30.4611C23.9063 30.5559 23.9063 30.6507 23.9063 30.7522Z"
        fill="white"
      />
      <path
        d="M23.8316 34.3668C21.6717 33.6154 21.4686 33.6154 19.4238 34.3668C19.4238 33.6696 19.4238 32.9927 19.4238 32.3022C20.8796 32.3022 22.3624 32.3022 23.8316 32.3022C23.8316 32.9859 23.8316 33.6628 23.8316 34.3668Z"
        fill="white"
      />
      <path
        d="M25.4297 29.1412C25.4297 28.6741 25.4297 28.2071 25.4297 27.7468C25.687 27.6182 25.9646 27.6723 26.1813 27.8889C26.4182 28.1326 26.4656 28.5049 26.2964 28.796C26.1271 29.1006 25.8089 29.2292 25.4297 29.1412Z"
        fill="white"
      />
      <path
        d="M29.9527 24.8766C29.5329 24.8766 29.1944 24.5449 29.1876 24.132C29.1808 23.7191 29.5194 23.3671 29.9256 23.3603C30.3454 23.3536 30.711 23.7055 30.7043 24.1252C30.7043 24.5381 30.3657 24.8766 29.9527 24.8766Z"
        fill="white"
      />
      <path
        d="M38.173 31.5848C38.173 31.991 37.8277 32.3362 37.4147 32.3362C37.0017 32.3362 36.6631 32.0045 36.6563 31.5848C36.6496 31.1584 37.0017 30.8132 37.4282 30.8199C37.8277 30.8199 38.173 31.1719 38.173 31.5848Z"
        fill="white"
      />
      <path
        d="M33.1011 35.1318C33.5209 35.1318 33.8595 35.4703 33.8595 35.8832C33.8595 36.3029 33.5209 36.6481 33.1079 36.6481C32.6813 36.6481 32.3293 36.2961 32.336 35.8764C32.3496 35.4567 32.6881 35.125 33.1011 35.1318Z"
        fill="white"
      />
      <path
        d="M35.0782 25.6821C35.0782 25.2624 35.4235 24.924 35.8366 24.9308C36.2496 24.9308 36.5949 25.276 36.5949 25.6889C36.5949 26.1154 36.2496 26.4538 35.8162 26.447C35.3965 26.447 35.0715 26.1086 35.0782 25.6821Z"
        fill="white"
      />
    </svg>
  );
};

export default ProductDesignIcon;
