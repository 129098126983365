import React from "react";
import CreateUser from "./CreateUser";
import { useLocation } from "react-router-dom";

const CreateUserWithRoute = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, -1000);
  }, [location]);
  return (
    <>
      <CreateUser />
    </>
  );
};

export default CreateUserWithRoute;
