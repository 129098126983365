import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import VerticalMenuBar from "../common/authLayout/VerticalMenuBar";
import notifications from "../common/Components/Notifications/Notification";
import HeaderNew from "../common/HeaderNew";
import ReactGA from "react-ga4";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  rest,
}: any) => {
  const location = useLocation();
  const [mini, setMini] = useState(localStorage.getItem("mini") || false);

  let miniLocal = localStorage.getItem("mini") || "";
  let trackingId: any = process.env.REACT_APP_GTAG_MEASUREMENT_ID;
  ReactGA.initialize(trackingId);
  let getUserRes: any = localStorage.getItem("GetUserRes");
  let getUserResFinal: any = JSON.parse(getUserRes) || "";
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname.replace("/", ""),
    });
  }, []);

  useEffect(() => {
    if (miniLocal !== undefined || miniLocal !== null) {
      if (miniLocal === "true") {
        let conentMini: any = document.getElementById(
          "contentCard"
        ) as HTMLElement;
        if (conentMini) {
          conentMini.style.marginLeft = "250px";
        }
      } else if (miniLocal === "false") {
        let conentMini: any = document.getElementById(
          "contentCard"
        ) as HTMLElement;
        if (conentMini) {
          conentMini.style.marginLeft = "100px";
        }
      }
    }
  }, [mini, miniLocal]);
  const menuBarIn = () => {
    let conentMini: any = document.getElementById("contentCard") as HTMLElement;
    conentMini.style.marginLeft = "250px";
    localStorage.setItem("mini", "true");
    setMini(true);
  };

  const menuBarOut = () => {
    let conentMini: any = document.getElementById("contentCard") as HTMLElement;
    conentMini.style.marginLeft = "100px";
    localStorage.setItem("mini", "false");
    setMini(false);
  };
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let miniFlag =
    miniLocal !== undefined || miniLocal !== null || miniLocal !== " "
      ? miniLocal === "true"
        ? true
        : false
      : mini;
  return (
    <Route
      {...rest}
      render={({ props }: any) => {
        if (isAuthProtected && !authUser) {
          return (
            <>
              <Redirect
                to={{ pathname: "/home", state: { from: location } }}
              />
            </>
          );
        } else {
          return (
            <React.Fragment>
              <Layout>
                {isAuthProtected ? (
                  <Grid container className="darkContain">
                    <Grid item xs={1}>
                      <VerticalMenuBar
                        {...props}
                        mini={miniFlag}
                        menuBarIn={menuBarIn}
                        menuBarOut={menuBarOut}
                      />
                      <div
                        className={miniFlag ? "sideArrow1" : "sideArrow"}
                        onClick={() => {
                          if (miniFlag) {
                            menuBarOut();
                          } else {
                            menuBarIn();
                          }
                        }}
                      >
                        {miniFlag ? (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.18848 10.4819L1.75006 6.04341L6.18848 1.605"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.78125 1.60498L6.21966 6.04344L1.78125 10.4819"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} id="contentCard">
                      <HeaderNew
                        {...props}
                        getUserDetailsFromAPI={getUserResFinal}
                      />

                      <Component {...props} {...rest} />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Component {...props} {...rest} />
                  </Grid>
                )}
              </Layout>
            </React.Fragment>
          );
        }
      }}
    />
  );
};

export default AppRoute;
