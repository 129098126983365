import React from 'react'
import { Container, Grid } from "@mui/material";
import WhyHastakalaLeft from './WhyHastakalaLeft';
import WhyHastakalaRight from './WhyHastakalaRight';
import MobileWhyHastakala from './MobileWhyHastakala';
import BuyerTrust from "../../assets/images/buyer_trust.jpg";
import Sustainable_Consumption from "../../assets/images/sustainable_consumption.jpg";
import Culture_Preservation from "../../assets/videos/cultural preservation.mp4"
// import WhyHastakalaRight from '../common-components/WhyHastakalaPramankRight';
// import WhyHastakalaRight from '../common-components/WhyHastakalaRight';
var whyHastakalaDataDetails = [{
  type: "image",
  image: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Counterfeit-Products-1.webp",
  title: "Counterfeit Products",
  borderRadius: "",
  des: "The market is flooded with counterfeit products that are falsely marketed as handmade or handloom, deceiving buyers and undermining the authenticity of genuine artisans' work. HastKala Pramanak helps combat this issue by providing a reliable and objective verification system."
}, {
  type: "image",
  image: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Protecting-Artisans-1.webp",
  title: "Protecting Artisans",
  borderRadius: "",
  des: "Authentic artisans who dedicate their skills and craftsmanship to create handmade products often face unfair competition from mass- produced, powerloom-blended goods. HastKala Pramanak supports artisans by establishing a transparent system that verifies and promotes genuine handmade products, ensuring they receive due recognition and fair value for their work."
}, {
  type: "image",
  image: BuyerTrust,
  title: "Buyer Trust",
  borderRadius: "25px",
  des: "In the absence of a reliable authentication system, buyers find it challenging to differentiate between genuine handmade products and counterfeit or powerloom-blended ones. HastKala Pramanak instills buyer confidence by offering an easy and trustworthy way to verify the authenticity of handmade products before making a purchase."
}, {
  type: "video",
  image: Culture_Preservation,
  title: "Cultural Heritage Preservation",
  borderRadius: "",
  des: "India's rich cultural heritage is closely tied to its handloom and handicraft traditions. By promoting and preserving genuine handmade products, HastKala Pramanak plays a vital role in safeguarding these cultural treasures for future generations and sustaining the livelihoods of artisans and weavers."
}, {
  type: "image",
  image: Sustainable_Consumption,
  title: "Sustainable Consumption",
  borderRadius: "25px",
  des: "Supporting handmade products encourages sustainable consumption practices by valuing craftsmanship, promoting local artisans, and reducing the demand for mass-produced goods. HastKala Pramanak aligns with the growing global movement towards ethical and sustainable consumer choices."
},]
const WhyHastakalaParmank = (props: any) => {
  return (
    <>
      <div className="why-hastakala-parmank">
        <Container>
          <div className="header">
            <h2 className="header-title">Why  <span>Hastkala</span>Pramanak?</h2>

          </div>
          <Grid container spacing={4} className='desktop-display-whyhastakala'>
            {
              whyHastakalaDataDetails.map((ele, i) => (
                <React.Fragment key={i}>
                  {
                    i === 0 || i % 2 === 0 ? <>  <Grid item xs={12} sm={0} md={1}></Grid>
                      <Grid item xs={12} sm={12} md={10}>
                        <WhyHastakalaRight {...props} data={ele} value={i} />
                      </Grid>
                      <Grid item xs={12} sm={0} md={1}></Grid></> : <>  <Grid item xs={12} sm={0} md={1}></Grid>
                      <Grid item xs={12} sm={12} md={10}>
                        <WhyHastakalaLeft {...props} data={ele} value={i} />
                      </Grid>
                      <Grid item xs={12} sm={0} md={1}></Grid></>
                  }


                </React.Fragment>
              ))
            }
          </Grid>
          <Grid container spacing={4} className='mobile-display-whyhastakala'>
            {
              whyHastakalaDataDetails.map((ele, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={12}>
                    <MobileWhyHastakala {...props} data={ele} />
                  </Grid>
                </React.Fragment>
              ))
            }
          </Grid>

        </Container>
      </div>
    </>
  )
}

export default WhyHastakalaParmank