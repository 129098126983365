import React from 'react'

const CloseIcon = (props:any) => {
  return (
    <svg
    onClick={() =>props.closeSideBar()}
    width="15"
    height="15"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
 <path
       d="M8 1L1 8"
       stroke={props.stroke}
       strokeWidth="2"
       strokeLinecap="round"
       strokeLinejoin="round"
     />
     <path
       d="M1 1L8 8"
       stroke={props.stroke}
       strokeWidth="2"
       strokeLinecap="round"
       strokeLinejoin="round"
     />
  </svg>
  )
}

export default CloseIcon