import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import axios, { AxiosResponse } from "axios";
import jwt from "jwt-decode";
//medthods
import { postApi, patchApi, getApi } from "../../../helpers/auth_helpers";

// workers

function* workerCheckLoginUser({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/auth/login";

    const response: AxiosResponse = yield call(postApi, url, payload);
    // let authUser = jwt(response?.data?.accessToken)
    callback(response);
    // localStorage.setItem("authUser",JSON.stringify(authUser));
    // localStorage.setItem("accessToken", response.data.accessToken);
    // localStorage.setItem("refreshToken", response.data.refreshToken);
    yield put({
      type: types.CHECK_LOGIN_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerChangePassword({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/user/change-password";

    const response: AxiosResponse = yield call(postApi, url, payload);
    // let authUser = jwt(response?.data?.accessToken)
    callback(response);

    yield put({
      type: types.CHANGE_PASSWORD_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerForgetPassword({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/user/forgot-password";

    const response: AxiosResponse = yield call(postApi, url, payload);
    // let authUser = jwt(response?.data?.accessToken)
    callback(response);

    yield put({
      type: types.FORGET_PASSWORD_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerResetPassword({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/user/reset-password";

    const response: AxiosResponse = yield call(postApi, url, payload);
    // let authUser = jwt(response?.data?.accessToken)
    callback(response);

    yield put({
      type: types.RESET_PASSWORD_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchCheckLoginUser() {
  yield takeEvery(types.CHECK_LOGIN, workerCheckLoginUser);
}
export function* watchChangePassword() {
  yield takeEvery(types.CHANGE_PASSWORD, workerChangePassword);
}
export function* watchForgetPassword() {
  yield takeEvery(types.FORGET_PASSWORD, workerForgetPassword);
}
export function* watchResetPassword() {
  yield takeEvery(types.RESET_PASSWORD, workerResetPassword);
}

function* authLoginSaga() {
  yield all([fork(watchCheckLoginUser)]);
  yield all([fork(watchChangePassword)]);
  yield all([fork(watchForgetPassword)]);
  yield all([fork(watchResetPassword)]);
}
export default authLoginSaga;
