import { Store } from "react-notifications-component";

const notifications = (message: any) => {
  Store.addNotification({
    title: message?.title,
    message: message?.message,
    type: message?.type,
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
  return null;
};

export default notifications;
