import React from "react";
import { Label, Input, Row, Col } from "reactstrap";

const ViewOrganization = (props: any) => {
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          organization: props.editData?.name,
          address: props.editData?.address?.addressLane,
          phone: props.editData?.phone ? props.editData?.phone : "NA",
          GST: props.editData?.gst ? props.editData?.gst : "NA",
          TAN: props.editData?.tan ? props.editData?.tan : "NA",
          logo: props.editData?.logo,
          pinCode:
            props?.editData?.address?.pinCode === 0
              ? ""
              : props?.editData?.address?.pinCode,
          city: props?.editData?.address?.city,
          state: props?.editData?.address?.state,
          spoc: props?.editData?.spocName,
          spocContact: props?.editData?.spocContact,
        }
      : ""
  );
  return (
    <>
      <div className="AddArtform">
        <div className="d-flex justify-content-center">
          <div className="imageDiv">
            {form?.logo?.length ? (
              <img
                src={form?.logo}
                alt="profile"
                width="200px"
                height={"200px"}
              />
            ) : (
              <div>No Preview Available</div>
            )}
          </div>
        </div>
        <div className="form">
          <div className="formDiv">
            <Label>Organization</Label>
            <Input
              name="organization"
              id="organization"
              className="form-control"
              placeholder="Enter Company Name"
              value={form?.organization}
              readOnly
            />
          </div>
          <div className="formDiv">
            <Label>Address</Label>
            <textarea
              name="address"
              id="address"
              className="form-control"
              placeholder="Enter Company Address"
              value={form?.address}
              readOnly
            />
          </div>
          <div className="formDiv">
            <Row>
              <Col xs={12} md={4}>
                <Label>
                  City<span>*</span>
                </Label>
                <Input
                  name="City"
                  className="form-control"
                  placeholder="City"
                  defaultValue={form?.city}
                  readOnly
                />
              </Col>
              <Col xs={12} md={4}>
                <Label>
                  State<span>*</span>
                </Label>
                <Input defaultValue={form?.state} readOnly />
              </Col>
              <Col xs={12} md={4}>
                <Label>
                  Pincode<span>*</span>
                </Label>
                <Input
                  name="pincode"
                  className="form-control"
                  placeholder="Pincode"
                  value={form?.pinCode}
                  readOnly
                />
              </Col>
            </Row>
          </div>
          <div className="formDiv">
            <Row>
              <Col xs={12} md={4}>
                <Label>Phone</Label>
                <Input
                  name="phone"
                  id="phone"
                  className="form-control"
                  placeholder="Enter Number"
                  value={form?.phone}
                  readOnly
                />
              </Col>
              <Col xs={12} md={4}>
                <Label>GST</Label>
                <Input
                  name="GST"
                  id="GST"
                  className="form-control text-Uppercase"
                  placeholder="Enter Number"
                  value={form?.GST}
                  readOnly
                />
              </Col>
              <Col xs={12} md={4}>
                <Label>SPOC</Label>
                <Input
                  name="spoc"
                  type="text"
                  className="form-control"
                  placeholder="SPOC"
                  value={form?.spoc}
                  readOnly
                />
              </Col>
              {/* <Col xs={12} md={4}>
                <Label>TAN</Label>
                <Input
                  name="TAN"
                  id="TAN"
                  className="form-control"
                  placeholder="Enter Number"
                  value={form?.TAN}
                  readOnly
                />
              </Col> */}
            </Row>
          </div>
          <div className="formDiv">
            <Row>
              {" "}
              <Col xs={12} md={4}>
                <Label>SPOC Contact</Label>
                <Input
                  name="spocContact"
                  type="number"
                  className="form-control"
                  placeholder="SPOC Contact"
                  maxLength={10}
                  value={form?.spocContact}
                  readOnly
                />
              </Col>
            </Row>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrganization;
