import React from 'react'
import { Grid } from '@mui/material';
import ReactPlayer from "react-player/file";
const WhyHastakalaLeft = (props: any) => {
    const { data, value } = props;
    return (
        <div className='why-hastakala-left' key={value}>
            <Grid container>

                <Grid item xs={12} sm={6} className='Content'>
                    <h2>{data?.title}</h2>
                    <p>{data?.des}</p>
                </Grid>
                <Grid xs={12} sm={6} item>
                    {data?.type == "image" ?
                        <img
                            style={{
                                borderRadius: data?.borderRadius
                            }}
                            src={data?.image}
                            width="100%" height="100%" alt="why hastakala" /> :
                        <ReactPlayer
                            className="react-player-div"
                            url={data?.image}
                            playing={true}
                            loop={true}
                            controls={true}
                            muted={true}

                            width={"100%"}
                            height={"280px"}
                        />}
                </Grid>
            </Grid>
        </div>
    )
}

export default WhyHastakalaLeft