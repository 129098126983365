export const HeaderData: any = {
  columns: [

    {
      label: "SrNo",
      field: "orgSrNo",
      sort: true,
      width: 2,
    },
    {
      label: "DesignNo",
      field: "designNo",
      sort: true,
      width: 2,
    },
    {
      label: "Image",
      field: "logo",
      sort: false,
      width: 1,
    },
    {
      label: "Product Name",
      field: "productName",
      sort: true,
      width: 3,
    },
    {
      label: "Status",
      field: "status",
      sort: false,
      width: 3,
    },
    // {
    //   label: "Artform",
    //   field: "artform",
    //   sort: true,
    //   width: 2,
    // },
    {
      label: "Color",
      field: "color",
      sort: false,
      width: 1,
    },
    // {
    //   label: "Type",
    //   field: "type",
    //   sort: true,
    //   width: 2,
    // },
    // {
    //   label: "Category",
    //   field: "category",
    //   sort: true,
    //   width: 2,
    // },
    // {
    //   label: "Sub-Category",
    //   field: "subCategory",
    //   sort: true,
    //   width: 2,
    // },
    // {
    //   label: "Weave Type",
    //   field: "weaveType",
    //   sort: true,
    //   width: 2,
    // },
    // {
    //   label: "Created by",
    //   field: "createdBy",
    //   sort: true,
    //   width: 2,
    // },
    {
      label: "Creator Oganization",
      field: "orgName",
      sort: true,
      width: 3,
    },

    {
      label: "Design Owner",
      field: "designOwner",
      sort: true,
      width: 3,
    },
    {
      label: "Product Owner",
      field: "productOwner",
      sort: true,
      width: 3,
    },
    {
      label: "HKPtagNo",
      field: "srNo",
      sort: true,
      width: 2,
    },
    // {
    //   label: "IsTransfered",
    //   field: "isTransfered",
    //   sort: false,
    //   width: 1,
    // },
    {
      label: "HKP Tag",
      field: "nfcTag",
      sort: true,
      width: 2,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 2,
    },
    {
      label: "Actions",
      field: "actions",
      width: 4,
    },
  ],
};
