import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";
import {
  Label,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
} from "reactstrap";
import Select from "react-select";
import { Toggle } from "rsuite";

//components
import ProductImagePreView from "./ProductImagePreView";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
import DeleteConfirm from "../../common/Components/DeleteConfirm";
import Index from "../../common/Components/Index";
import { HeaderData } from "./Header";
import QuickFilter from "./QuickFilter";
import notifications from "../../common/Components/Notifications/Notification";
import { debounce } from "../../common/commonFiles/debounce";
//store
import { getWeaveType } from "../../Store/weave-type/actions";
import { getArtforms } from "../../Store/artform/actions";
import { getSubCategorys } from "../../Store/actions";
import { getTypes } from "../../Store/type/actions";
import { getCategory } from "../../Store/category/actions";
import {
  deleteProducts,
  getProducts,
  changeProductStatus,
  productTransfer,
  addNfcTagForProduct,
  undoTransferProduct,
} from "../../Store/products/actions";
import {
  getOrganisation,
  connectOrg,
  getAssocciationsOrg,
} from "../../Store/organisation/actions";
import { isArray } from "lodash";
import AddNfcTagModal from "./AddNfcTagModal";
var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
// selector options
var artformOptions: any = [];
var weaveTypeOptions: any = [];
var typeOptions: any = [];
var subcategoryOptions: any = [];
var categoryOptions: any = [];

var associationOptions: any = [];

const Products = (props: any) => {
  const history = useHistory();
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const [loading, setLoading]: any = useState(false);
  const [deleteData, setDeleteData]: any = useState("");
  const [filters, setFilters]: any = useState("");
  const [sortName, setSortName]: any = useState({
    name: "",
    order: "",
  });
  const [warningModal, setWarningModal]: any = useState(false);
  const [trans, setTrans]: any = useState(false);
  const [productName, setProductName]: any = useState("");
  const [associationData, setAssociationData]: any = useState<any>({
    associationId: "",
    productOwnerShipTransferFlag: true,
    designOwnerShipTransferFlag: false,
  });
  const [Error, setError]: any = useState({
    associationId: "",
  });

  const [addNfc, setAddNfc]: any = useState(false);
  const [nfcData, setNfcData]: any = useState("");
  const [nfcTagID, setNfcTagID]: any = useState("");
  const [openPreview, setOpenPreview]: any = useState(false);
  const [image, setImage]: any = useState("");
  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const closePreview = () => {
    setOpenPreview(false);
  };
  var data: any = [];
  const openNfcModal = () => {
    setAddNfc(true);
  };
  const closeNfcModal = () => {
    setAddNfc(false);
    setNfcData("");
    setNfcTagID("");
  };
  const addNfcId = (payload: any) => {
    props.addNfcTagForProduct(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        closeNfcModal();
        getAllData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const openTrans = () => {
    setTrans(true);
    getAssociationOrg();
  };
  const closeTrans = () => {
    setTrans(false);
    setProductName("");
    setError("");
    setAssociationData({
      associationId: "",
      productOwnerShipTransferFlag: true,
      designOwnerShipTransferFlag: false,
    });
  };
  const deleteDataWithConfirm = (deleteData: any) => {
    props.deleteProducts(
      {
        productId: deleteData?._id,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const getWeaveTypeData = (data?: any) => {
    props.getWeaveType(
      {
        offset: 0,
        limit: 10,
        weaveTypeName: data?.value,
        subCategoryId: filters?.subCategory?.value || data?.subCategory,
        categoryId: filters?.category?.value || data?.category,
        typeId: filters?.type?.value || data?.type,
        artformId: filters?.artform?.value || data?.artform,
      },
      (res: any) => {
        weaveTypeOptions = [];
        res?.data?.docs.map((ele: any) => {
          weaveTypeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: data?.value,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const handleChangeStatus = (payload: any) => {
    setLoading(true);
    props.changeProductStatus(payload, (res: any) => {
      setLoading(false);
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        getAllData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const undoTransferProductApi = (payload: any) => {
    setLoading(true);
    props.undoTransferProduct(payload, (res: any) => {
      setLoading(false);

      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        getAllData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };

  if (props.productDetails !== undefined) {
    if (
      isArray(props?.productDetails?.data?.docs) &&
      props?.productDetails?.data?.docs?.length > 0
    ) {
      totCnt = props?.productDetails?.data?.length;
      totPages = Math.ceil(totCnt / 10);
      fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
      toVal =
        props?.productDetails?.data?.docs?.length === 0
          ? 0
          : props?.productDetails?.data?.offset * 10 +
          props?.productDetails?.data?.docs?.length;
      props?.productDetails?.data?.docs?.length > 0 &&
        props?.productDetails?.data?.docs.map((ele: any, i: any) => {
          let initialFlag =
            ele?.srNo?.length > 0 ||
            ele?.artform?.name?.length > 0 ||
            ele?.type?.name?.length > 0 ||
            ele?.category?.name?.length > 0 ||
            ele?.subcategory?.name?.length > 0 ||
            ele?.weavetype?.name?.length > 0 ||
            ele?.typeOfSareeByDesign?.length > 0 ||
            ele?.name?.length > 0 ||
            ele?.colour?.length > 0 ||
            ele?.tags?.length > 0 ||
            ele?.brand?.length > 0 ||
            ele?.weaver?.length > 0 ||
            ele?.zari?.length > 0 ||
            ele?.sareeWeight?.length > 0 ||
            ele?.zariWeight?.length > 0 ||
            ele?.purity?.length > 0 ||
            ele?.yarnType?.length > 0 ||
            ele?.loomNum?.length > 0 ||
            ele?.xuid?.length > 0 ||
            ele?.workingHours?.length > 0 ||
            ele?.productionTimeInMonths?.length > 0 ||
            ele?.silk?.length > 0 ||
            ele?.handloom?.length > 0;
          let initialFlagWithMedia = ele?.thumbnailDetails?.media?.length > 0;

          let designFlag = ele?.designingDetails?.karigar?.name?.length > 0;
          let designFlagWithMedia = ele?.designingDetails?.media?.length > 0;
          let dyingFlag = ele?.dyingDetails?.karigar?.name?.length > 0;
          let dyingFlagWithMedia = ele?.dyingDetails?.media?.length > 0;
          let finishingFlag = ele?.finishingDetails?.karigar?.name?.length > 0;
          let finishingFlagWithMedia = ele?.finishingDetails?.media?.length > 0;
          let weavingFlag = ele?.weavingDetails?.karigar?.name?.length > 0;
          let weavingFlagWithMedia = ele?.weavingDetails?.media?.length > 0;

          let pinedImage: any = [];
          let unpin: any = [];
          if (ele?.thumbnailDetails?.media?.length > 0) {
            ele?.thumbnailDetails?.media.map((ele: any, i: any) => {
              console.log("ele",ele)
              if (ele?.pin === "1" && ele?.show === "1") {
                pinedImage.push(ele?.mediaURL);
              } else if (ele?.show === "1") {
                unpin.push(ele?.mediaURL);
              }
            });
          }
          let profileImages: any = [...pinedImage, ...unpin];
           console.log("profileImages",profileImages)
          data.push({
            id: <span>{ele?.productUniqueId}</span>,
            logo:
              [...pinedImage, ...unpin]?.length > 0 ? (
                <img
                  onClick={() => {
                    handleOpenPreview();
                    setImage([...pinedImage, ...unpin]);
                  }}
                  src={[...pinedImage, ...unpin][0]}
                  alt="logo"
                  className="logoImage"
                ></img>
              ) : (
                <span className="logoPreview">Image not uploaded</span>
              ),
            srNo: ele?.srNo,
            nfcTag: (
              <span
                style={{
                  textTransform: "none",
                }}
              >
                {ele?.xuid}
              </span>
            ),
            status: (
              <>
                <div className="ProductFlowStatusParent">
                  <div className="hr"></div>
                  <div className="ProductFlowStatus">
                    <div className="tooltipIcon">
                      <div className="ps2 ">
                        <div
                          className="innerCircle"
                          style={{
                            backgroundColor:
                              initialFlag && initialFlagWithMedia
                                ? "rgb(119 216 119)"
                                : initialFlag || initialFlagWithMedia
                                  ? "rgb(84 115 182)"
                                  : "#FFF",
                          }}
                        ></div>
                        <span className="tooltipIcontext">
                          <b>Basic Information&nbsp;: </b> &nbsp;
                          {initialFlag && initialFlagWithMedia
                            ? "Basic Information is entered and Media uploaded"
                            : initialFlag
                              ? "Basic Information is entered and Media missing"
                              : initialFlagWithMedia
                                ? "Basic Information is missing,Media uploaded"
                                : "Basic Information and media missing"}
                        </span>
                      </div>
                    </div>
                    <div className="tooltipIcon">
                      <div className="ps1 ">
                        <div
                          className="innerCircle"
                          style={{
                            backgroundColor:
                              designFlag && designFlagWithMedia
                                ? "rgb(119 216 119)"
                                : designFlag || designFlagWithMedia
                                  ? "rgb(84 115 182)"
                                  : "#FFF",
                          }}
                        ></div>
                        <span className="tooltipIcontext">
                          <b>Designing&nbsp;: </b> &nbsp;
                          {designFlag && designFlagWithMedia
                            ? "Karigar is entered and Media uploaded"
                            : designFlag
                              ? "Karigar is entered and Media missing"
                              : designFlagWithMedia
                                ? "Karigar is missing,Media uploaded"
                                : "Karigar and media missing"}
                        </span>
                      </div>
                    </div>
                    <div className="tooltipIcon">
                      <div className="ps3">
                        <div
                          className="innerCircle"
                          style={{
                            backgroundColor:
                              dyingFlag && dyingFlagWithMedia
                                ? "rgb(119 216 119)"
                                : dyingFlag || dyingFlagWithMedia
                                  ? "rgb(84 115 182)"
                                  : "#FFF",
                          }}
                        ></div>
                        <span className="tooltipIcontext">
                          <b>Dying&nbsp;: </b> &nbsp;
                          {dyingFlag && dyingFlagWithMedia
                            ? "Karigar is entered and Media uploaded"
                            : dyingFlag
                              ? "Karigar is entered and Media missing"
                              : dyingFlagWithMedia
                                ? "Karigar is missing,Media uploaded"
                                : "Karigar and media missing"}
                        </span>
                      </div>
                    </div>
                    <div className="tooltipIcon">
                      <div className="ps4">
                        <div
                          className="innerCircle"
                          style={{
                            backgroundColor:
                              weavingFlag && weavingFlagWithMedia
                                ? "rgb(119 216 119)"
                                : weavingFlag || weavingFlagWithMedia
                                  ? "rgb(84 115 182)"
                                  : "#FFF",
                          }}
                        ></div>
                        <span className="tooltipIcontext">
                          <b>Weaving&nbsp;: </b> &nbsp;
                          {weavingFlag && weavingFlagWithMedia
                            ? "Karigar is entered and Media uploaded"
                            : weavingFlag
                              ? "Karigar is entered and Media missing"
                              : weavingFlagWithMedia
                                ? "Karigar is missing,Media uploaded"
                                : "Karigar and media missing"}
                        </span>
                      </div>
                    </div>
                    <div className="tooltipIcon">
                      <div className="ps5 ">
                        <div
                          className="innerCircle"
                          style={{
                            backgroundColor:
                              finishingFlag && finishingFlagWithMedia
                                ? "rgb(119 216 119)"
                                : finishingFlag || finishingFlagWithMedia
                                  ? "rgb(84 115 182)"
                                  : "#FFF",
                          }}
                        ></div>
                        <span className="tooltipIcontext">
                          <b>Finishing&nbsp;: </b> &nbsp;
                          {finishingFlag && finishingFlagWithMedia
                            ? "Karigar is entered and Media uploaded"
                            : finishingFlag
                              ? "Karigar is entered and Media missing"
                              : finishingFlagWithMedia
                                ? "Karigar is missing,Media uploaded"
                                : "Karigar and media missing"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ),
            isTransfered: ele?.transfer === true ? "true" : "false",
            transferTime: ele?.transferTime,
            productOwner: ele?.productOwner,
            designOwner: ele?.designOwner,
            category: ele?.category?.name,
            artform: ele?.artform?.name,
            type: ele?.type?.name,
            color: ele?.color,
            subCategory: ele?.subcategory?.name,
            weaveType: ele?.weavetype?.name,
            orgSrNo: ele?.orgSrNo,
            designNo: ele?.designNum,
            orgName: ele?.organizations[0]?.name,
            productName: (
              <span
                onClick={() => {
                  history.push(`/view-product/${ele?.productUniqueId}`, {
                    productDetails: ele,
                  });
                }}
              >
                {" "}
                {ele?.name}
              </span>
            ),
            createdAt: ele?.createdAt
              ? moment(new Date(ele?.createdAt))
                .subtract(5, "hours")
                .subtract(30, "minutes")
                .format("YYYY-MM-DD LT")
              : "NA",
            createdBy: ele?.createdBy ? ele?.createdBy?.role : "",

            actions: (
              <>
                <div className=" tableBtnsProduct actionDropdown">
                  <div className="d1">
                    {ele?.status === "COMPLETED" && ele?.archived === false && (
                      <>
                        <span
                          className="btns"
                          onClick={() => {
                            setNfcData(ele);
                            setNfcTagID(ele?.xuid);
                            openNfcModal();
                          }}
                        >
                          {ele?.xuid?.length > 0
                            ? "Edit HKP Tag"
                            : "Add HKP Tag"}
                        </span>
                        <span className="actionLineHeight me-2"></span>
                        {ele?.transfer === false &&
                          ele?.productOwner ==
                          authUser?.users?.organizationResponse?.name && (
                            <>
                              {" "}
                              <span
                                className="btns"
                                onClick={() => {
                                  openTrans();
                                  setProductName(ele);
                                }}
                              >
                                Transfer
                              </span>
                              <span className="actionLineHeight me-2"></span>
                            </>
                          )}

                        {ele?.transfer === true &&
                          ele?.transferTime !== null && (
                            <>
                              {" "}
                              <span
                                className="btns"
                                onClick={() => {
                                  undoTransferProductApi({
                                    transferData: [
                                      {
                                        id: ele?._id,
                                      },
                                    ],
                                    associationId: ele?.assosiationId,
                                  });
                                }}
                              >
                                Undo Transfer
                              </span>
                              <span className="actionLineHeight me-2"></span>
                            </>
                          )}
                      </>
                    )}
                  </div>
                  <div className="d1">
                    <UncontrolledDropdown className="me-2" direction="start">
                      <DropdownToggle color="#969696">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                            fill="#969696"
                          />
                        </svg>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            history.push(`/createProduct`, {
                              productDetails: ele,
                              name: "variant",
                            });
                          }}
                        >
                          {" "}
                          <span className="btns">Create Variant</span>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            history.push(
                              `/edit-product/${ele?.productUniqueId}`,
                              {
                                productDetails: ele,
                              }
                            );
                          }}
                        >
                          {" "}
                          <span className="btns">Edit</span>
                        </DropdownItem>
                        <DropdownItem divider />{" "}
                        {ele?.status === "COMPLETED" && (
                          <>
                            <DropdownItem
                              onClick={() => {
                                if (
                                  ele?.productOwner ===
                                  "tantuvi by smriti morarka"
                                ) {
                                  history.push(
                                    `/pre-ViewProduct-tantuvi/${ele?.productUniqueId}`,
                                    {
                                      productDetails: ele,
                                    }
                                  );
                                } else {
                                  history.push(
                                    `/pre-viewProduct/${ele?.productUniqueId}`,
                                    {
                                      productDetails: ele,
                                    }
                                  );
                                }
                              }}
                            >
                              <span className="btns">Pre-View</span>
                            </DropdownItem>
                            <DropdownItem divider />
                          </>
                        )}
                        <DropdownItem
                          onClick={() => {
                            history.push(
                              `/view-product/${ele?.productUniqueId}`,
                              {
                                productDetails: ele,
                              }
                            );
                          }}
                        >
                          {" "}
                          <span className="btns">View</span>
                        </DropdownItem>{" "}
                        {ele?.status === "COMPLETED" ? (
                          ele?.archived ? (
                            <>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => {
                                  handleChangeStatus({
                                    productId: ele?._id,
                                  });
                                }}
                              >
                                {/* <span className="actionLineHeight"></span> */}
                                &nbsp;&nbsp;
                                <span className="btns">Publish</span>
                              </DropdownItem>
                            </>
                          ) : (
                            <>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => {
                                  handleChangeStatus({
                                    productId: ele?._id,
                                  });
                                }}
                              >
                                {/* <span className="actionLineHeight"></span> */}
                                &nbsp;&nbsp;
                                <span className="btns">Archive</span>
                              </DropdownItem>
                            </>
                          )
                        ) : (
                          ""
                        )}
                        {/* <DropdownItem divider /> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  {/* <div className=" tableBtnsProduct"> */}

                  {/* <span
                    className="btns"
                    onClick={() => {
                      history.push(`/view-product/${ele?.productUniqueId}`, {
                        productDetails: ele,
                      });
                    }}
                  >
                    View
                  </span>
                  <span className="actionLineHeight me-2"></span>
                  <span
                    className="btns"
                    onClick={() => {
                      history.push(`/edit-product/${ele?.productUniqueId}`, {
                        productDetails: ele,
                      });
                    }}
                  >
                    Edit
                  </span>

                  {ele?.status === "COMPLETED" ? (
                    ele?.archived ? (
                      <>
                        <span className="actionLineHeight"></span>&nbsp;&nbsp;
                        <span
                          className="btns"
                          onClick={() => {
                            handleChangeStatus({
                              productId: ele?._id,
                            });
                          }}
                        >
                          Publish
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="actionLineHeight"></span>&nbsp;&nbsp;
                        <span
                          className="btns"
                          onClick={() => {
                            handleChangeStatus({
                              productId: ele?._id,
                            });
                          }}
                        >
                          Archive
                        </span>
                      </>
                    )
                  ) : (
                    ""
                  )} */}
                  {/* <span
                  className="btns"
                  onClick={() => {
                    setDeleteData(ele);
                    setWarningModal(true);
                  }}
                >
                  Delete
                </span> */}
                </div>
              </>
            ),
          });
        });
    } else {
      totCnt = 0;
      totPages = 0;
    }
  }

  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    if (sortName?.name?.length > 0) {
      getAllData();
    }
  }, [sortName]);
  const getAllData = (filter?: any) => {
    localStorage.setItem(
      "offsetForProduct",
      filter?.offset ? filter?.offset : offset
    );
    let orderByAsc = sortName?.order === "asc" ? "1" : "";
    let orderByDesc = sortName?.order === "desc" ? "-1" : "";
    setLoading(true);
    let offsetForProduct: any = localStorage.getItem("offsetForProduct");
    let filetrsForProduct: any = localStorage.getItem("FiltersForProducts");
    let filters: any = filter ? filter : JSON.parse(filetrsForProduct) || "";
    props.getProducts(
      {
        offset: offsetForProduct,
        limit: limit,
        ProductsName: filters?.productName,
        artformId: filters?.artform?.value,
        typeId: filters?.type?.value,
        categoryId: filters?.category?.value,
        subCategoryId: filters?.subCategory?.value,
        weaveTypeId: filters?.weaveType?.value,
        tags: filters?.tags,
        sortBy: sortName?.name,
        orderBy: orderByAsc || orderByDesc,
      },
      (res: any) => {
        localStorage.setItem("ProductCount", res?.data?.length);
        setLoading(false);
      }
    );
  };
  const getCategoryData = (data?: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: filters?.type?.value || data?.type,
        artformId: filters?.artform?.value || data?.artform,
        categoryName: data?.value,
      },
      (res: any) => {
        categoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          categoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getSubCategoryData = (data?: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 10,
        categoryId: filters?.category?.value || data?.category,
        typeId: filters?.type?.value || data?.type,
        artformId: filters?.artform?.value || data?.artform,
        subCategoryName: data?.value,
      },
      (res: any) => {
        subcategoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          subcategoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        artFormId: filters?.artform?.value || data?.artform,
        typeName: data?.value,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };

  const handlePageChange = (event: any, value: any) => {
    offset = (value - 1) * 1;
    localStorage.setItem("offsetForProduct", offset);
    getAllData({
      ...filters,
      offset,
    });
  };
  const handleSort = (data: any) => {
    if (data?.name === "id") {
      setSortName({
        name: "id",
        order: data?.sort,
      });
    } else if (data?.name === "productName") {
      setSortName({
        name: "name",
        order: data?.sort,
      });
    } else if (data?.name === "category") {
      setSortName({
        name: "category",
        order: data?.sort,
      });
    } else if (data?.name === "category") {
      setSortName({
        name: "category",
        order: data?.sort,
      });
    } else if (data?.name === "subCategory") {
      setSortName({
        name: "subcategory",
        order: data?.sort,
      });
    } else if (data?.name === "type") {
      setSortName({
        name: "type",
        order: data?.sort,
      });
    } else if (data?.name === "artform") {
      setSortName({
        name: "artform",
        order: data?.sort,
      });
    } else if (data?.name === "weaveType") {
      setSortName({
        name: "weavetype",
        order: data?.sort,
      });
    } else if (data?.name === "nfcTag") {
      setSortName({
        name: "xuid",
        order: data?.sort,
      });
    } else if (data?.name === "srNo") {
      setSortName({
        name: "srNo",
        order: data?.sort,
      });
    } else if (data?.name === "createdAt") {
      setSortName({
        name: "createdAt",
        order: data?.sort,
      });
    }
  };
  const handleOnchangeFilter = (type: any) => {
    offset = 0;
    localStorage.setItem("offsetForProduct", offset);
    localStorage.setItem(
      "FiltersForProducts",
      JSON.stringify({
        ...filters,
        [type.name]: type?.value,
      })
    );
    setFilters({
      ...filters,
      [type.name]: type?.value,
    });
    if (type?.name === "productName" || type?.name === "tags") {
      handleChangeDebounceForProduct();
    } else {
      getAllData();
    }
  };
  const handleChangeDebounceForProduct = React.useCallback(
    debounce(getAllData, 1000),
    []
  );
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "artform") {
        getArtformData(data);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
      if (data?.name === "category") {
        getCategoryData(data);
      }
      if (data?.name === "subCategory") {
        getSubCategoryData(data);
      }
      if (data?.name === "weaveType") {
        getWeaveTypeData(data);
      }
      if (data?.name === "assOrg") {
        props.connectOrg({ gst: data?.value }, (res: any) => {
          getAssociationOrg();
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        });
      }
    }, 1000),
    []
  );
  const handleResetData = () => {
    localStorage.removeItem("FiltersForProducts");
    offset = 0;
    localStorage.setItem("offsetForProduct", offset);
    setFilters({
      type: "",
      category: "",
      subCategory: "",
      id: "",
      productName: "",
      artform: "",
      weaveType: "",
    });
    props.getProducts(
      {
        offset: offset,
        limit: limit,
      },
      () => {
        setLoading(false);
      }
    );
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      getAllData();
    }
  };
  const autoFocus = (data: any) => {
    if (data?.name === "artform") {
      getArtformData();
    } else if (data?.name === "type") {
      getTypesData();
    } else if (data?.name === "category") {
      getCategoryData();
    } else if (data?.name === "subCategory") {
      getSubCategoryData();
    } else if (data?.name === "weaveType") {
      getWeaveTypeData();
    }
  };
  const handleOnInputChange = (type: any) => {
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        !filters?.artform?.value &&
        !filters?.type?.value &&
        !filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.artform?.value &&
        !filters?.type?.value &&
        !filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
        });
      }
    }
    if (type?.name === "category") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.type?.value &&
        !filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
        });
      }
    }
    if (type?.name === "subCategory") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
          category: filters?.category?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
          category: filters?.category?.value,
        });
      }
    }
    if (type?.name === "weaveType") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
          category: filters?.category?.value,
          subCategory: filters?.subCategory?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.subCategory?.value &&
        !filters?.weaveType?.value &&
        !filters?.productName
      ) {
        handleChangeDebounce({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
          category: filters?.category?.value,
          subCategory: filters?.subCategory?.value,
        });
      }
    }
  };
  const warningToggle = () => {
    setWarningModal(false);
  };
  const getAssociationOrg = (filters?: any) => {
    let payload: any = {
      offset: 0,
      limit: 10,
      gst: filters?.gst,
    };
    props.getAssocciationsOrg(payload, (res: any) => {
      associationOptions = [];
      if (isArray(res?.data?.docs)) {
        res?.data?.docs.map((ele: any, i: any) => {
          associationOptions.push({
            label: ele?.manufacturer[0]?.name + " (" + ele?.gst + ")",
            value: { id: ele?._id, gst: ele?.gst },
          });
        });
      }
    });
  };
  const handleSubmitChange = (type: any) => {
    setAssociationData({
      ...associationData,
      [type?.name]: type?.value,
    });
  };
  const handleOnInputChangAssOrg = (e: any) => {
    if (associationOptions?.length > 0 && e?.length >= 2) {
      associationOptions.some((ele: any) => {
        if (e?.length >= 2) {
          getAssociationOrg({
            offset: 0,
            limit: 10,
            gst: e,
          });
        }
        // if (ele.value?.gst.includes(e) || ele?.label.includes(e)) {
        // } else {
        //   handleChangeDebounce({
        //     name: "assOrg",
        //     value: e,
        //   });
        // }
      });
    }
  };
  const validate = () => {
    let error = "";
    let err = Error;
    if (associationData?.associationId === "") {
      error = "Required";
      err = {
        ...err,
        associationId: " Association Name is Required!",
      };
    }

    setError(err);
    return error;
  };
  const handleAssSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      props.productTransfer(
        {
          transferData: [
            {
              id: productName?._id,
              productownershipTransfer:
                associationData?.productOwnerShipTransferFlag,
              designownershipTransfer:
                associationData?.designOwnerShipTransferFlag,
            },
          ],
          associationId: associationData?.associationId?.value?.id,
        },
        (res: any) => {
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            getAllData();
            closeTrans();
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        }
      );
    }
  };
  return (
    <>
      {openPreview && (
        <ProductImagePreView
          {...props}
          openModal={openPreview}
          closeModal={closePreview}
          images={image}
        />
      )}
      <Modal
        {...props}
        isOpen={trans}
        toggle={closeTrans}
        size="lg"
        fade={false}
        className="TransferModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Transfering</h3>
            <ModalCloseIcon {...props} closeModal={closeTrans} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="formDiv">
            <Label>
              <b>
                Product Name:{" "}
                <span className="text-capitalise">{productName?.name}</span>
              </b>
            </Label>
          </div>
          <div className="formDiv">
            <Label>
              <b>Association</b>
              <span>*</span>
            </Label>

            <Select
              className="React_selector text-white"
              classNamePrefix="react-select"
              placeholder="Select Association"
              autoFocus={false}
              isSearchable
              value={associationData?.associationId}
              options={associationOptions}
              onChange={(e: any) => {
                handleSubmitChange({
                  name: "associationId",
                  value: e,
                });
              }}
              onInputChange={(e) => handleOnInputChangAssOrg(e)}
            />
            {Error.associationId && !associationData?.associationId ? (
              <p className="RequiredField">{Error?.associationId} </p>
            ) : (
              ""
            )}
          </div>
          <div className="formDiv mt-2">
            <Label>
              <b>Product Owner Transfer</b>
            </Label>
            <FormGroup>
              <Toggle
                size="lg"
                // defaultChecked={true}
                checked={
                  associationData?.designOwnerShipTransferFlag == true
                    ? associationData?.designOwnerShipTransferFlag
                    : associationData?.productOwnerShipTransferFlag
                }
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked: boolean, event) => {
                  handleSubmitChange({
                    name: "productOwnerShipTransferFlag",
                    value: checked,
                  });
                }}
              />{" "}
            </FormGroup>
          </div>
          <div className="formDiv mt-2">
            <Label>
              <b>Design Owner Transfer</b>
            </Label>
            <FormGroup>
              <Toggle
                size="lg"
                // defaultChecked={true}
                checked={associationData?.designOwnerShipTransferFlag}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked: boolean, event) => {
                  handleSubmitChange({
                    name: "designOwnerShipTransferFlag",
                    value: checked,
                  });
                }}
              />{" "}
            </FormGroup>
          </div>
          <div className="btnS">
            <Button
              disabled={
                associationData?.productOwnerShipTransferFlag == true ||
                  associationData?.designOwnerShipTransferFlag == true
                  ? false
                  : true
              }
              onClick={() => {
                handleAssSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <AddNfcTagModal
        {...props}
        addNfc={addNfc}
        closeNfcModal={closeNfcModal}
        openNfcModal={openNfcModal}
        nfData={nfcData}
        getAllData={getAllData}
        addNfcId={addNfcId}
        nfcTagID={nfcTagID}
      />
      <DeleteConfirm
        {...props}
        warningModal={warningModal}
        warningToggle={warningToggle}
        deleteDataWithConfirm={deleteDataWithConfirm}
        deleteData={deleteData}
        getAllData={getAllData}
        name={"Product"}
      />
      <QuickFilter
        {...props}
        handleOnchangeFilter={handleOnchangeFilter}
        typeOptions={typeOptions}
        categoryOptions={categoryOptions}
        subcategoryOptions={subcategoryOptions}
        filters={filters}
        handleResetData={handleResetData}
        getAllData={getAllData}
        handleKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        handleOnInputChange={handleOnInputChange}
        artformOptions={artformOptions}
        weaveTypeOptions={weaveTypeOptions}
      />
      {/* {isArray(props?.userDetails?.data?.docs) ? ( */}
      <Index
        {...props}
        data={data}
        headerData={HeaderData}
        limit={limit}
        handleChange={handlePageChange}
        offset={localStorage.getItem("offsetForProduct")}
        totPages={totPages}
        loading={loading}
        handleSort={handleSort}
        fromVal={fromVal}
        toVal={toVal}
        totCnt={totCnt}
      />
      {/* ) : (
        <div></div>
      )} */}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    productDetails: state?.productReducer?.getProductDetails,
  };
};
export default connect(mapStateToProps, {
  getProducts,
  deleteProducts,
  getCategory,
  getTypes,
  getSubCategorys,
  getArtforms,
  getWeaveType,
  changeProductStatus,
  connectOrg,
  getAssocciationsOrg,
  getOrganisation,
  productTransfer,
  addNfcTagForProduct,
  undoTransferProduct,
})(Products);
