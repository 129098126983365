import React from "react";

const ManIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2H32V32H2V2Z" fill="white" fillOpacity="0.01" />
      <path
        d="M17.0003 13.6667C20.222 13.6667 22.8337 11.055 22.8337 7.83333C22.8337 4.61168 20.222 2 17.0003 2C13.7787 2 11.167 4.61168 11.167 7.83333C11.167 11.055 13.7787 13.6667 17.0003 13.6667Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 31V32H32V31C32 27.2663 32 25.3995 31.2734 23.9734C30.6343 22.719 29.6143 21.6991 28.3599 21.0599C26.9338 20.3333 25.067 20.3333 21.3333 20.3333H12.6667C8.933 20.3333 7.06617 20.3333 5.64008 21.0599C4.38565 21.6991 3.36578 22.719 2.72663 23.9734C2 25.3995 2 27.2663 2 31Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ManIcon;
