import React from "react";

export const AddIcon = (props: any) => {
  const { name, value, openModal } = props;
  return (
    <svg
      className="cursor-pointer"
      onClick={() =>
        openModal({
          name,
          value,
        })
      }
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="25" height="25" rx="4" fill="#8C673E" />
      <path
        d="M12.868 9.72815V17.0072"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22852 13.3677H16.5076"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
