import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import {  postApi ,putApi,putUploadImagesApi} from "../../helpers/auth_helpers";

// workers
function* workFetchProfilePresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/userProfilePresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_USER_PROFILE_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchProductPresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/productPresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_PRODUCT_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchProductWeavingPresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/productWeavingPresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_PRODUCT_WEAVING_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchProductDyingPresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/productDyningPresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_PRODUCT_DYER_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchProductFinisingPresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/productFinishingPresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_PRODUCT_FINISHING_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchProductDesigningPresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/productDesigningPresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_PRODUCT_DESIGNING_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchMainLogoPresignedUrl({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/mainLogoPresignedURL";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_MAIN_LOGO_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUploadImages({ uri, data, headers }: any) {
  try {
    // Try to call the API
    const response: AxiosResponse = yield call(
      putUploadImagesApi,
      uri,
      data,
      headers
    );
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_IMAGE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}
function* workUploadImages1({ uri, data, headers,callback }: any) {
  try {
    // Try to call the API
    const response: AxiosResponse = yield call(
      putUploadImagesApi,
      uri,
      data,
      headers
    );
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_IMAGE_SUCCESS1,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}
// watchers
export function* watchFetchProfilePresignedUrl() {
  yield takeEvery(types.GET_USER_PROFILE_PRESIGNED_URL, workFetchProfilePresignedUrl);
}
export function* watchFetchProductPresignedUrl() {
  yield takeEvery(types.GET_PRODUCT_PRESIGNED_URL, workFetchProductPresignedUrl);
}
export function* watchFetchProductWeavingPresignedUrl() {
  yield takeEvery(types.GET_PRODUCT_WEAVING_PRESIGNED_URL, workFetchProductWeavingPresignedUrl);
}
export function* watchFetchProductDyerPresignedUrl() {
  yield takeEvery(types.GET_PRODUCT_DYER_PRESIGNED_URL, workFetchProductDyingPresignedUrl);
}
export function* watchFetchProductFinishingPresignedUrl() {
  yield takeEvery(types.GET_PRODUCT_FINISHING_PRESIGNED_URL, workFetchProductFinisingPresignedUrl);
}
export function* watchFetchProductDesigningPresignedUrl() {
  yield takeEvery(types.GET_PRODUCT_DESIGNING_PRESIGNED_URL, workFetchProductDesigningPresignedUrl);
}
export function* watchFetchMainLogoPresignedUrl() {
  yield takeEvery(types.GET_MAIN_LOGO_PRESIGNED_URL, workFetchMainLogoPresignedUrl);
}
export function* watchUploadImages() {
  yield takeEvery(types.UPLOAD_IMAGE, workUploadImages);
}
export function* watchUploadImages1() {
  yield takeEvery(types.UPLOAD_IMAGE1, workUploadImages1);
}
function* uploadModule() {
  yield all([fork(watchFetchProfilePresignedUrl)]);
  yield all([fork(watchFetchProductDesigningPresignedUrl)])
  yield all([fork(watchFetchProductPresignedUrl)]);
  yield all([fork(watchFetchProductFinishingPresignedUrl)]);
  yield all([fork(watchFetchProductWeavingPresignedUrl)]);
  yield all([fork(watchFetchProductDyerPresignedUrl)]);
  yield all([fork(watchFetchMainLogoPresignedUrl)]);
  yield all([fork(watchUploadImages)]);
  yield all([fork(watchUploadImages1)]);


}
export default uploadModule;
