import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import Logo from "../../assets/images/D-3 1.jpg";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
const SignUPSignInCommonForm = (props: any) => {
  const {
    handleOnChange,
    Errors,
    value,
    handleSubmit,
    loading,
    handleChangedChecked,
    remember,
    response,
  } = props;
  const [signUpShow, setSignUpShow]: any = useState(false);
  const [signInShow, setSignInShow]: any = useState(false);

  const showLoginForm = () => {
    setSignInShow(true);
    setSignUpShow(false);
  };
  const showSignUpForm = () => {
    setSignInShow(false);
    setSignUpShow(true);
  };
  return (
    <>
      {/* <div className="d-flex justify-content-center">
        <img
          src={Logo}
          width={"250px"}
          height="200px"
          alt="logo"
          className="LogoImage"
        />
      </div> */}
      {signInShow || signUpShow ? (
        <>
          {signInShow && (
            <LoginForm
              {...props}
              value={value}
              Errors={Errors}
              handleOnChange={handleOnChange}
              handleSubmit={handleSubmit}
              showSignUpForm={showSignUpForm}
              loading={loading}
              handleChangedChecked={handleChangedChecked}
              remember={remember}
              response={response}
            />
          )}
          {signUpShow && (
            <SignUpForm
              {...props}
              value={value}
              Errors={Errors}
              handleOnChange={handleOnChange}
              handleSubmit={handleSubmit}
              showLoginForm={showLoginForm}
            />
          )}
        </>
      ) : (
        <LoginForm
          {...props}
          value={value}
          Errors={Errors}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          showSignUpForm={showSignUpForm}
          loading={loading}
          handleChangedChecked={handleChangedChecked}
        />
      )}
    </>
  );
};

export default SignUPSignInCommonForm;
