const baseImageCovert = (base: any) => {
  let base64Files: any = [];
  base.forEach((ele: any) => {
    if (ele?.mediaURL?.length > 0) {
      if (ele?.mediaURL.toLowerCase().endsWith(".docx")) {
        base64Files.push({
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".doc")) {
        base64Files.push({
          type: "application/msword",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".pdf")) {
        base64Files.push({
          type: "application/pdf",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".jpg")) {
        base64Files.push({
          type: "image/jpg",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".jpeg")) {
        base64Files.push({
          type: "image/jpeg",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".png")) {
        base64Files.push({
          type: "image/png",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".mp4")) {
        base64Files.push({
          type: "video/mp4",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".webm")) {
        base64Files.push({
          type: "video/webm",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".ogg")) {
        base64Files.push({
          type: "video/ogg",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      }else if (ele?.mediaURL.toLowerCase().endsWith(".bmp")) {
        base64Files.push({
          type: "image/bmp",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
        });
      }
    }
  });
  return base64Files;
};
const baseImageCovertForLogoPin = (base: any) => {
  let base64Files: any = [];
  base.forEach((ele: any) => {
    if (ele?.mediaURL?.length > 0) {
      if (ele?.mediaURL.toLowerCase().endsWith(".docx")) {
        base64Files.push({
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".doc")) {
        base64Files.push({
          type: "application/msword",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".pdf")) {
        base64Files.push({
          type: "application/pdf",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".jpg")) {
        base64Files.push({
          type: "image/jpg",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".jpeg")) {
        base64Files.push({
          type: "image/jpeg",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".png")) {
        base64Files.push({
          type: "image/png",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".mp4")) {
        base64Files.push({
          type: "video/mp4",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".webm")) {
        base64Files.push({
          type: "video/webm",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      } else if (ele?.mediaURL.toLowerCase().endsWith(".ogg")) {
        base64Files.push({
          type: "video/ogg",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      }
      else if (ele?.mediaURL.toLowerCase().endsWith(".bmp")) {
        base64Files.push({
          type: "image/bmp",
          blobImage: ele?.mediaURL,
          show: ele?.show === "1" ? true : false,
          pin: ele?.pin === "1" ? true : false,
        });
      }
    }
  });
  return base64Files;
};
export { baseImageCovert, baseImageCovertForLogoPin };
