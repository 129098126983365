import React from 'react'
import "./style.scss";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import CheckIcon from "../../assets/icons/icons8-check-64.png"
const WorkIcon = () => <>
  <img className="check-icon"src={CheckIcon} width={25} height={25}/>
</>;
const TimeLineComponents = () => {
  return (
    <>
       <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#F5EEE0", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #F5EEE0" }}
          iconStyle={{ background: "#F5EEE0", color: "#fff" }}
          icon={<WorkIcon />}
        >
         <p><b>Step1:</b> Find product tag</p>
         <img src={"https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Step-1.webp"} className="disImage" width="auto" height={"200px"} />
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
          contentStyle={{ background: "#F5EEE0", color: "#333" }}
          contentArrowStyle={{ borderRight: "7px solid  #F5EEE0" }}
          iconStyle={{ background: "#F5EEE0", color: "#333" }}
          icon={<WorkIcon />}
        >
            <p><b>Step2:</b> Tap NFC enabled phone</p>
         <img src={"https://hastkala.fistbump.digital/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-07-at-1.32.16-PM.jpeg"} className="disImage" width="100%" height={"auto"} />
        </VerticalTimelineElement>
           <VerticalTimelineElement
        className="vertical-timeline-element--work"
          contentStyle={{ background: "#F5EEE0", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #F5EEE0" }}
          iconStyle={{ background: "#F5EEE0", color: "#fff" }}
          icon={<WorkIcon />}
        >
            <p><b>Step3:</b> Objectively verify your product</p>
         <img src={"https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Step3.webp"} className="disImage" width="auto" height={"auto"} />
        </VerticalTimelineElement>   <VerticalTimelineElement
        className="vertical-timeline-element--work"
          contentStyle={{ background: "#F5EEE0", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #F5EEE0" }}
          iconStyle={{ background: "#F5EEE0", color: "#fff" }}
          icon={<WorkIcon />}
        >
            <p><b>Step4:</b> Experience the product journey</p>
         <img src={"https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Step-4.webp"} className="disImage"  width="auto" height={"auto"} />
        </VerticalTimelineElement>
       
      
      
      
      </VerticalTimeline>
    </>
  )
}

export default TimeLineComponents