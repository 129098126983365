export const HeaderData: any = {
  columns: [
    {
      label: "Organization",
      field: "organization",
      sort: true,
      width: 200,
    },
    {
      label: "Organization Type",
      field: "orgType",
      sort: true,
      width: 200,
    },
    {
      label: "Address",
      field: "address",
      sort: true,
      width: 200,
    },
    {
      label: "Phone",
      field: "phone",
      sort: true,
      width: 200,
    },
    {
      label: "GST",
      field: "gst",
      sort: false,
      width: 200,
    },
    // {
    //   label: "Created by",
    //   field: "createdBy",
    //   sort: true,
    //   width: 200,
    // },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 200,
    },
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
export const HeaderDataManufacturers: any = {
  columns: [
    {
      label: "Organization",
      field: "organization",
      sort: false,
      width: 200,
    },
    {
      label: "Address",
      field: "address",
      sort: false,
      width: 200,
    },
    {
      label: "Phone",
      field: "phone",
      sort: false,
      width: 200,
    },
    {
      label: "Gst",
      field: "gst",
      sort: false,
      width: 200,
    },
    {
      label: "Party Type",
      field: "partyType",
      sort: false,
      width: 200,
    },

    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
