import React, { useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Card } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// components
import VerticalMenuBar from "./authLayout/VerticalMenuBar";
import BackButton from "./Components/Icons/BackButton";
import ChangePassword from "../pages/login/ChangePassword";

const HeaderNew = (props: any) => {
  const location = useLocation();
  const history = useHistory();
  const [menu, setMenu]: any = useState(false);
  const [sideBar, setSideBar]: any = useState(
    localStorage.getItem("show") || false
  );
  const [warningModal, setWarningModal]: any = useState(false);
  // change password
  const [changePswdModal, setChangePswdModel]: any = useState(false);
  const handleChangePaswd = () => {
    setChangePswdModel(true);
  };
  const handleCloseModal = () => {
    setChangePswdModel(false);
  };

  const sideBarOpen = () => {
    setSideBar(true);
    localStorage.setItem("show", JSON.stringify(true));
  };
  const closeSideBar = () => {
    setSideBar(false);
  };
  const toggle = () => {
    setMenu(!menu);
  };

  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let routeName: any = "";
  if (location.pathname.split("/")[1] === "editUser") {
    routeName = "Edit User Details";
  } else if (location.pathname.split("/")[1] === "viewUser") {
    routeName = "View User Details";
  } else if (location?.pathname === "/organisation") {
    routeName = "Organization List";
  } else if (location?.pathname === "/organisation-profile") {
    routeName = "Organization Profile";
  } else if (location?.pathname === "/users") {
    routeName = "User List";
  } else if (location?.pathname === "/createUser") {
    routeName = "Create User";
  } else if (location?.pathname.split(":")[0] === "/artform") {
    routeName = "Artform List";
  } else if (location?.pathname.split(":")[0] === "/type") {
    routeName = "Type List";
  } else if (location?.pathname.split(":")[0] === "/category") {
    routeName = "Category List";
  } else if (location?.pathname.split(":")[0] === "/sub-category") {
    routeName = "Sub-Category List";
  } else if (location?.pathname.split(":")[0] === "/dashboard") {
    routeName = "Dashboard";
  } else if (location?.pathname.split(":")[0] === "/weave-type") {
    routeName = "Weave Type List";
  } else if (location?.pathname.split(":")[0] === "/products") {
    routeName = "Product List";
  } else if (location?.pathname === "/createProduct") {
    routeName = "Create Product";
  } else if (location.pathname.split("/")[1] === "view-product") {
    routeName = "View Product";
  } else if (location.pathname.split("/")[1] === "pre-viewProduct") {
    routeName = "Product Preview";
  } else if (location.pathname.split("/")[1] === "pre-ViewProduct-tantuvi") {
    routeName = "Product Preview for Tantuvi";
  } else if (location.pathname.split("/")[1] === "edit-product") {
    routeName = "Edit Product";
  } else if (location?.pathname.split(":")[0] === "/edit-profile") {
    routeName = "Edit Profile";
  } else if (location?.pathname.split(":")[0] === "/view-user-profile") {
    routeName = "User Profile";
  } else {
    routeName = location?.pathname.replace("/", "");
  }
  let nameShortStart: any = " ";
  let nameShort: any = authUser?.users?.name.trim().split(" ");
  nameShort.map((ele: any) => {
    nameShortStart += ele[0];
  });
  const warningToggle = () => {
    setWarningModal(false);
  };
  return (
    <>
      <ChangePassword
        {...props}
        openModal={changePswdModal}
        closeModal={handleCloseModal}
        userId={authUser?.users?._id}
      />
      <Modal
        isOpen={warningModal}
        toggle={warningToggle}
        size="ms"
        className="ConfirmModal"
        fade={false}
      >
        <ModalHeader>
          <div className="Heading">
            <h3>Warning</h3>
            <svg
              onClick={() => warningToggle()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ContentDiv">
            {" "}
            Are you sure to exit without saving
          </div>
          <div className="NameHeader">
            {/* {name}: <div className="textCapitalize">{deleteData?.name}</div> */}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="BtnFlex">
            <Button
              className="btnY1"
              onClick={() => {
                history.push("/products");
              }}
            >
              Ok
            </Button>
            <Button className="btnN1" onClick={() => warningToggle()}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {sideBar ? (
        <VerticalMenuBar
          {...props}
          closeSideBar={closeSideBar}
          show={sideBar}
        />
      ) : (
        ""
      )}
      <Card className="CardHeaderStyle">
        <div className="HeaderStyle">
          <div className="d-flex">
            <svg
              onClick={() => sideBarOpen()}
              className="m-2 cursor-pointer mobileToggle"
              width="25"
              height="18"
              viewBox="0 0 25 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H23"
                stroke="#C59C6C"
                strokeWidth="4"
                strokeLinecap="round"
              />
              <path
                d="M2 9H23"
                stroke="#C59C6C"
                strokeWidth="4"
                strokeLinecap="round"
              />
              <path
                d="M2 16H23"
                stroke="#C59C6C"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
            <span className="text-capitalize">
              {(location.pathname.split("/")[1] === "editUser" ||
                location.pathname.split("/")[1] === "viewUser" ||
                location?.pathname === "/createUser") && (
                <Link to="/users">
                  <span className="me-2 backbutton">
                    <BackButton {...props} />
                  </span>
                </Link>
              )}
              {(location?.pathname.split(":")[0] ===
                "/edit-profile-user-details" ||
                location?.pathname.split(":")[0] ===
                  "/edit-profile-organisation-details") && (
                <Link to="/view-user-profile">
                  <span className="me-2 backbutton">
                    <BackButton {...props} />
                  </span>
                </Link>
              )}
              {(location.pathname.split("/")[1] === "view-product" ||
                location.pathname.split("/")[1] === "pre-viewProduct" ||
                location.pathname.split("/")[1] === "edit-product" ||
                location.pathname.split("/")[1] ===
                  "pre-ViewProduct-tantuvi") && (
                <Link to="/products">
                  <span className="me-2">
                    <BackButton {...props} />
                  </span>
                </Link>
              )}
              {location?.pathname === "/createProduct" && (
                // <Link to="/products">
                <span className="me-2">
                  <BackButton
                    {...props}
                    onClick={() => setWarningModal(true)}
                    name={"createProduct"}
                  />
                </span>
                // </Link>
              )}
              <span className="routeSpan"> {routeName}</span>
            </span>
          </div>
          <div>
            <Dropdown isOpen={menu} toggle={() => toggle()}>
              <DropdownToggle>
                {props.getUserDetailsFromAPI?.profileURL ? (
                  <img
                    src={props.getUserDetailsFromAPI?.profileURL}
                    alt="Profile"
                  />
                ) : (
                  <button className="imageBtn">
                    {nameShortStart.toUpperCase()}
                  </button>
                )}
                <span className="text-capitalize">{authUser?.users?.name}</span>
                <svg
                  className="ms-1"
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5.5 5L10 1"
                    stroke="#1A1A1A"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </DropdownToggle>

              <DropdownMenu>
                {/* <> */}
                <DropdownItem
                  className="cursor-point DropdownButton"
                  onClick={() => handleChangePaswd()}
                >
                  <div>
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="0.00393677"
                        width="23"
                        height="23"
                        rx="4"
                        fill="#EAD6C0"
                      />
                      <path
                        d="M8.67734 16.8706C8.55418 16.8444 8.43102 16.826 8.3131 16.792C7.54794 16.5771 7.06578 15.9875 7.03171 15.1857C6.99765 14.355 7.08674 13.5374 7.40381 12.7618C7.60821 12.2613 7.9017 11.8263 8.39958 11.5616C8.70617 11.3991 9.03372 11.3205 9.37699 11.3284C9.48443 11.331 9.59973 11.386 9.69669 11.4385C9.85653 11.5249 10.0033 11.6324 10.1579 11.7267C11.0567 12.2822 11.9529 12.2796 12.8517 11.7241C12.9775 11.6481 13.1032 11.5721 13.2212 11.4856C13.436 11.3258 13.6719 11.3048 13.9261 11.3415C14.6231 11.4437 15.1131 11.8263 15.4459 12.4342C15.742 12.9766 15.8756 13.5662 15.9333 14.1742C15.9647 14.486 15.9778 14.8005 15.9752 15.1149C15.9647 16.0504 15.3201 16.7474 14.3899 16.8496C14.3663 16.8523 14.3453 16.8627 14.3217 16.868C12.4377 16.8706 10.5588 16.8706 8.67734 16.8706Z"
                        fill="#C59C6C"
                      />
                      <path
                        d="M14.0176 8.71846C14.0281 10.1335 12.8594 11.3048 11.4365 11.3101C10.0136 11.3153 8.84491 10.1413 8.84229 8.73156C8.84229 7.30605 9.98217 6.14782 11.4182 6.13734C12.8489 6.12686 14.0098 7.27984 14.0176 8.71846Z"
                        fill="#C59C6C"
                      />
                    </svg>

                    <span className="ms-2">Change Password</span>
                  </div>
                </DropdownItem>
                <hr className="hrLineProfile"></hr>
                <Link to="/view-user-profile">
                  <DropdownItem className="cursor-point DropdownButton">
                    <div>
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="0.00393677"
                          width="23"
                          height="23"
                          rx="4"
                          fill="#EAD6C0"
                        />
                        <path
                          d="M8.67734 16.8706C8.55418 16.8444 8.43102 16.826 8.3131 16.792C7.54794 16.5771 7.06578 15.9875 7.03171 15.1857C6.99765 14.355 7.08674 13.5374 7.40381 12.7618C7.60821 12.2613 7.9017 11.8263 8.39958 11.5616C8.70617 11.3991 9.03372 11.3205 9.37699 11.3284C9.48443 11.331 9.59973 11.386 9.69669 11.4385C9.85653 11.5249 10.0033 11.6324 10.1579 11.7267C11.0567 12.2822 11.9529 12.2796 12.8517 11.7241C12.9775 11.6481 13.1032 11.5721 13.2212 11.4856C13.436 11.3258 13.6719 11.3048 13.9261 11.3415C14.6231 11.4437 15.1131 11.8263 15.4459 12.4342C15.742 12.9766 15.8756 13.5662 15.9333 14.1742C15.9647 14.486 15.9778 14.8005 15.9752 15.1149C15.9647 16.0504 15.3201 16.7474 14.3899 16.8496C14.3663 16.8523 14.3453 16.8627 14.3217 16.868C12.4377 16.8706 10.5588 16.8706 8.67734 16.8706Z"
                          fill="#C59C6C"
                        />
                        <path
                          d="M14.0176 8.71846C14.0281 10.1335 12.8594 11.3048 11.4365 11.3101C10.0136 11.3153 8.84491 10.1413 8.84229 8.73156C8.84229 7.30605 9.98217 6.14782 11.4182 6.13734C12.8489 6.12686 14.0098 7.27984 14.0176 8.71846Z"
                          fill="#C59C6C"
                        />
                      </svg>

                      <span className="ms-2">Profile</span>
                    </div>
                  </DropdownItem>
                </Link>
                <hr className="hrLineProfile"></hr>
                {/* </> */}

                <DropdownItem
                  className="cursor-point DropdownButton"
                  onClick={() => {
                    localStorage.removeItem("authUser");
                    let rememberMe: any = localStorage.getItem("rememberMe");
                    let userLoginDetails: any = localStorage.getItem(
                      "UsersLoginDeatils" || ""
                    );
                    let userData: any = JSON.parse(userLoginDetails);
                    localStorage.clear();
                    history.push("/home");
                    localStorage.setItem("rememberMe", rememberMe);

                    if (rememberMe) {
                      localStorage.setItem(
                        "UsersLoginDeatils",
                        JSON.stringify(userData)
                      );
                    }
                  }}
                >
                  <div className="">
                    <svg
                      className="me-1"
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="0.996002"
                        width="23"
                        height="23"
                        rx="4"
                        fill="#EAD6C0"
                      />
                      <path
                        d="M17.8924 12.7893C17.8924 16.2002 15.6717 18.8489 12.603 19.3972C9.25822 19.9962 5.99121 17.8104 5.25614 14.4773C4.66108 11.7742 5.88231 8.97784 8.27034 7.58158C8.75261 7.29767 9.33989 7.43768 9.62381 7.89662C9.90773 8.35944 9.7716 8.95061 9.28933 9.24231C8.29367 9.84126 7.60527 10.6852 7.25912 11.7937C6.44237 14.4073 8.212 17.1648 10.9189 17.507C13.3381 17.8104 15.5355 16.1497 15.8972 13.7383C16.1617 11.9881 15.3216 10.2185 13.7853 9.29287C13.5092 9.12563 13.307 8.90783 13.2525 8.58502C13.1864 8.18442 13.3147 7.84995 13.6492 7.61659C13.9798 7.38323 14.3376 7.36767 14.6916 7.56603C15.3099 7.91606 15.8544 8.36333 16.3173 8.90394C17.3479 10.1018 17.8691 11.502 17.8924 12.7893Z"
                        fill="#C59C6C"
                      />
                      <path
                        d="M12.4745 8.01719C12.4745 8.52279 12.4784 12.4549 12.4745 12.9605C12.4667 13.4933 12.1323 13.8783 11.615 13.9522C11.1133 14.0261 10.5999 13.6333 10.5376 13.1277C10.5338 13.081 10.526 13.0305 10.526 12.9838C10.526 11.9531 10.5182 7.49602 10.5299 6.46536C10.5338 5.89364 10.9888 5.47359 11.5489 5.49693C12.0311 5.51638 12.4512 5.9053 12.4667 6.38758C12.4862 6.93208 12.4706 7.47658 12.4706 8.02108C12.4706 8.01719 12.4706 8.01719 12.4745 8.01719Z"
                        fill="#C59C6C"
                      />
                    </svg>

                    <span className="">Logout</span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </Card>
    </>
  );
};

export default HeaderNew;
