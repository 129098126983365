import React from 'react'
import { Grid } from '@mui/material';
const MobileWhyHastakala = (props:any) => {
    const { data } = props;
    return (
        <div className='why-hastakala-left'>
            <Grid container>

                <Grid item xs={12} className='Content'>
                    <h2>{data?.title}</h2>
                    <p>{data?.des}</p>
                </Grid>
                <Grid xs={12} item>
                    <img src={data?.image} width="100%" height="100%" alt="why hastakala" />
                </Grid>
            </Grid>
        </div>
    )
}

export default MobileWhyHastakala