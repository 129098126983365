import React from "react";

const ViewEyeIcon = (props: any) => {
  return (
    <svg
      {...props}
      onClick={props.onClick}
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 1H32V31H2V1Z" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 30.1666C25.2843 30.1666 32 23.2315 32 18.4999C32 13.7683 25.2843 6.83325 17 6.83325C8.71575 6.83325 2 13.7731 2 18.4999C2 23.2268 8.71575 30.1666 17 30.1666Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M17 23.4999C19.7614 23.4999 22 21.2614 22 18.4999C22 15.7385 19.7614 13.4999 17 13.4999C14.2386 13.4999 12 15.7385 12 18.4999C12 21.2614 14.2386 23.4999 17 23.4999Z"
        fill="#C59C6C"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M8.05371 5.38837L10.2158 8.4052"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M26.6885 5.75867L24.5264 8.7755"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M17.0078 1.83325V6.83327"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ViewEyeIcon;
