import React from 'react'
import { Grid, Container } from "@mui/material"
import PricingCard from './PricingCard'
import Header from '../Header'
import Footer from '../Footer'
const Pricing = (props: any) => {
    let arryOfItem: any = [
        {
            name: "Standard",
            value: "₹499/month",
            content: "Essential features for small entities with up to 500 pieces/month production volume, ensuring authenticity and consumer trust.",
            listContent: ["1 users", "Basic insights", "Access to all functionality", "Standard authenticity certificate", "3 day wait for support"]
        },
        {
            name: "Premium",
            value: "₹799/month",
            content: "Advanced insights for clients exceeding 1000 pieces/month, optimizing operations and enhancing customer satisfaction.",
            listContent: ["Upto 4 users", "Detailed insights", "Access to all functionality", "Standard authenticity certificate", "1 day wait for support"]
        },
        {
            name: "Enterprise",
            value: "Contact sales",
            content: "Fully customizable solution for artisans,designers, and large corporations with complex needs and extensive production volumes",
            listContent: ["More than 10 users", "Custom insights", "Custom design for authenticity certificate", "On call support"]
        }
    ]
    return (
        <>
            <Header />
            <Container>
                <div className='pricing'>
                    <h2 className='mb-4'>Pricing</h2>
                    <Grid container spacing={2}>
                        {
                            arryOfItem.map((item: any, i: any) => {
                                return (<>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <PricingCard
                                            {...props}
                                            key={i}
                                            item={item}
                                        />
                                    </Grid>
                                </>)
                            })
                        }
                    </Grid>
                    <div className='smart-tag'>
                        <h4>Smart Tags</h4>
                        <p>You need one smart tag for each product</p>
                        <p>Price - 200 piece</p>
                    </div>

                </div>

            </Container>
            <Footer />
        </>
    )
}

export default Pricing