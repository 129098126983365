import React from "react";
import ReactApexChart from "react-apexcharts";

const UsersRolesPieChart = (props: any) => {
  let superAdmin: any;
  let admin: any;
  let orgAdmin: any;
  let orgManager: any;
  let nesQ: any;
  let stainMaster: any;
  let rufger: any;
  let tassel: any;
  let dyer: any;
  let weaver: any;
  let array: any = [];
  props.usersDataArry.map((ele: any) => {
    if (ele?.name === "Super Admin" && ele?.value !== 0) {
      superAdmin = "#E19600";
      array.push(superAdmin);
    }
    if (ele?.name === "Admin" && ele?.value !== 0) {
      admin = "#FF6F00";
      array.push(admin);
    }
    if (ele?.name === "Organization Admin" && ele?.value !== 0) {
      orgAdmin = "#28A34C";
      array.push(orgAdmin);
    }
    if (ele?.name === "Organization Manager" && ele?.value !== 0) {
      orgManager = "#AC1000";
      array.push(orgManager);
    }
    if (ele?.name === "NAQSEBAND" && ele?.value !== 0) {
      nesQ = "#BC51FF";
      array.push(nesQ);
    }
    if (ele?.name === "Stain Master" && ele?.value !== 0) {
      stainMaster = "#8F00FF";
      array.push(stainMaster);
    }
    if (ele?.name === "Refugar" && ele?.value !== 0) {
      rufger = "#FF6F00";
      array.push(rufger);
    }
    if (ele?.name === "Tassel" && ele?.value !== 0) {
      tassel = "#006400";
      array.push(tassel);
    }
    if (ele?.name === "Dyer" && ele?.value !== 0) {
      dyer = "#808080";
      array.push(dyer);
    }
    if (ele?.name === "Weaver" && ele?.value !== 0) {
      weaver = "#808000";
      array.push(weaver);
    }
  });
  let labels: any = [];
  props.usersDataArry.filter((ele: any) => {
    if (ele?.value !== 0) {
      labels.push(ele?.name + " " + ele?.value);
    }
  });
  var options: any = {
    chart: {
      width: "100%",
      // type: "donut",
    },
    colors: array,
    dataLabels: {
      enabled: false,
    },
    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 6,
                },
                // value: {
                //   show: true,
                //   fontSize: "16px",
                //   fontFamily: "Helvetica, Arial, sans-serif",
                //   fontWeight: 400,
                //   color: undefined,
                //   offsetY: 16,
                //   formatter: function (val: any) {
                //     return val;
                //   },
                // },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#373d3f",
                  formatter: function (w: any) {
                    return " ";
                  },
                },
              },
            },
          },
        },
        options: {
          chart: {
            width: 200,
            // type: "donut",
          },
          legend: {
            show: true,
            position: "bottom",
            labels: {
              show: true,
              colors: "#FFFFFF",
              useSeriesColors: true,
            },
          },
        },
      },
    ],
  };
  let series: any = [];
  props.usersDataArry.filter((ele: any) => {
    if (ele?.value !== 0) {
      series.push(ele?.value);
    }
  });
  return (
    <ReactApexChart
      {...props}
      options={options}
      series={series}
      type="donut"
      width={"100%"}
      height={"400px"}
    />
  );
};

export default UsersRolesPieChart;
