import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";

//components
import editIcon from "../../assets/images/icons8-edit-64 (1).png";
import PhoneIcon from "../../common/Components/Icons/PhoneIcon";
import EmailIcon from "../../common/Components/Icons/EmailIcon";
import HomeIcon from "../../common/Components/Icons/HomeIcon";
import CardIcon from "../../common/Components/Icons/CardIcon";
import ProfileOrganizationIcon from "../../common/Components/Icons/ProfileOrganizationIcon";
import { getUsers } from "../../Store/users/actions";
import Loader from "../../common/Components/Loader/Loader";
import ProductImagePreView from "../products/ProductImagePreView";

const ViewUserProfile = (props: any) => {
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const [loading, setLoading]: any = useState(false);
  const [imagePreview, setImagePreview]: any = useState({
    orgLogo: false,
    userLogo: false,
  });
  // const [form, setForm]: any = useState(authUser?.users);
  const [form, setForm]: any = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    state: "",
    pinCode: 0,
    adharCard: "",
    panCard: "",
    artform: "",
    role: "",
    organization: "",
    domain: "",
    artisanCard: "",
    profileURL: "",
  });
  useEffect(() => {
    if (authUser?.users?._id) {
      getUserDataById();
    }
  }, [authUser?.users?._id]);
  const getUserDataById = () => {
    setLoading(true);
    props.getUsers(
      {
        userId: authUser?.users?._id,
      },
      (res: any) => {
        setLoading(false);
        let formOrganisationDetails: any = [];
        let editDetails: any = res?.data?.docs[0];
        if (editDetails?.businessRoleDetails?.length) {
          if (editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND") {
            editDetails?.organizationDetails.forEach((ele: any) => {
              formOrganisationDetails.push({
                label: ele?.name,
                value: ele?._id,
              });
            });
          }
        }
        setForm({
          name: editDetails?.name,
          phone: editDetails?.phone,
          email: editDetails?.email,
          address: editDetails?.address?.addressLane,
          state: {
            value: editDetails?.address?.state,
            label: editDetails?.address?.state,
          },
          pinCode: editDetails?.address?.pinCode,
          adharCard: editDetails?.aadharCard,
          panCard: editDetails?.panCard,
          artform: editDetails?.artFormDetails?.length
            ? {
                value: editDetails?.artFormDetails[0]?._id,
                label: editDetails?.artFormDetails[0]?.name,
              }
            : "",
          role: editDetails?.businessRoleDetails?.length
            ? {
                value: editDetails?.businessRoleDetails[0]?._id,
                label: editDetails?.businessRoleDetails[0]?.role,
              }
            : "",
          orgContact: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.phone
            : "----",
          orgAddress: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.address?.addressLane +
              "," +
              editDetails?.organizationDetails[0]?.address?.city +
              "," +
              editDetails?.organizationDetails[0]?.address?.state +
              "," +
              (editDetails?.organizationDetails[0]?.address?.pinCode !== 0
                ? editDetails?.organizationDetails[0]?.address?.pinCode + ","
                : ",") +
              " India"
            : "----",
          orgProfile: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.logo
            : "",
          orgState: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.address?.state
            : "----",
          orgGST: editDetails?.organizationDetails?.length
            ? editDetails?.organizationDetails[0]?.gst
            : "----",
          organization: editDetails?.businessRoleDetails?.length
            ? editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND"
              ? formOrganisationDetails
              : {
                  value: editDetails?.organizationDetails[0]?._id,
                  label: editDetails?.organizationDetails[0]?.name,
                }
            : "",
          domain: {
            value: editDetails?.businessDetails?.domain,
            label: editDetails?.businessDetails?.domain,
          },
          artisanCard: editDetails?.businessDetails?.artisanCard,
          profileURL: editDetails?.profileURL,
        });
      }
    );
  };
  const closeModal = () => {
    setImagePreview({
      orgLogo: false,
      userLogo: false,
    });
  };
  return (
    <>
      {imagePreview?.userLogo && (
        <ProductImagePreView
          {...props}
          openModal={imagePreview?.userLogo}
          closeModal={closeModal}
          images={[form?.profileURL]}
        />
      )}
      {imagePreview?.orgLogo && (
        <ProductImagePreView
          {...props}
          openModal={imagePreview?.orgLogo}
          closeModal={closeModal}
          images={[form?.orgProfile]}
        />
      )}
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <>
          <div className="viewUserProfile alignmentForProfile">
            <div>
              <div className="d1">
                <div className="imgDiv">
                  {form?.profileURL?.length ? (
                    <img
                      onClick={() => {
                        setImagePreview({
                          ...imagePreview,
                          userLogo: true,
                        });
                      }}
                      src={form?.profileURL}
                      alt="profile"
                      className="imageRound"
                    />
                  ) : (
                    <div className="previewno">No Preview Available</div>
                  )}
                </div>
              </div>
            </div>
            <div className="d2">
              <h2 className="text-capitalize">{form?.name}</h2>
              <div className="d21">{form?.role?.label?.replace("_", " ")}</div>
              <div className="d21">
                <div>
                  <EmailIcon />
                </div>
                <div className="sp1">{form?.email}</div>
              </div>
              <div className="d21">
                <div>
                  <PhoneIcon />
                </div>
                <div className="sp1">+91 {form?.phone}</div>
              </div>
              <div className="d21">
                <div>
                  <HomeIcon />
                </div>
                <div className="sp1">
                  {form?.address} , {form?.pinCode} ,{"India"}
                </div>
              </div>
              <div className="OrgDiv">
                <div className="OD1">KYC Details</div>
                <div>
                  <Link to="/edit-profile-user-details">
                    <img className="editIcon" src={editIcon} alt="editIcon" />

                    {/* <Button className="editprofileBtn">Edit Profile</Button> */}
                  </Link>
                  <CardIcon />
                </div>
              </div>
              <div className="OrgSub">
                <div className="OrD1">
                  <div className="OrD11">Aadhar Card:</div>
                  <div>{form?.adharCard ? form?.adharCard : "----"}</div>
                </div>
                <div className="OrD1">
                  <div className="OrD11">Pan Card:</div>
                  <div className="text-uppercase">
                    {form?.panCard ? form?.panCard : "----"}
                  </div>
                </div>
                <div className="OrD1">
                  <div className="OrD11">Artisan Card:</div>
                  <div>{form?.artisanCard ? form?.artisanCard : "----"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="viewUserProfile alignmentForProfile">
            <div>
              <div className="d1">
                <div className="imgDiv">
                  {form?.orgProfile?.length ? (
                    <img
                      onClick={() => {
                        setImagePreview({
                          ...imagePreview,
                          orgLogo: true,
                        });
                      }}
                      src={form?.orgProfile}
                      alt="profile"
                      className="imageRound"
                    />
                  ) : (
                    <div className="previewno">No Preview Available</div>
                  )}
                </div>
              </div>
            </div>
            <div className="d2">
              <div className="OrgDiv">
                <div className="OD1">Organization Details</div>
                <div>
                  <Link to="/edit-profile-organisation-details">
                    <img className="editIcon" src={editIcon} alt="editIcon" />

                    {/* <Button className="editprofileBtn">Edit Profile</Button> */}
                  </Link>
                  <ProfileOrganizationIcon />
                </div>
              </div>
              <div className="OrgSub">
                <div className="OrD1">
                  <div className="OrD11 ">Organization Name:</div>
                  <div className="text-capitalize">
                    {form?.organization?.label}
                  </div>
                </div>
                <div className="OrD1">
                  <div className="OrD11">GST:</div>
                  <div className="text-Uppercase">
                    {form?.orgGST ? form?.orgGST : "-----"}
                  </div>
                </div>
                <div className="OrD1">
                  <div className="OrD11">Organization Contact:</div>
                  <div>+91 {form?.orgContact}</div>
                </div>
                <div className="OrD1">
                  <div className="OrD11">Organization Address:</div>
                  <div className="text-capitalize">
                    {form?.orgAddress ? form?.orgAddress : "-----"}
                  </div>
                </div>
                <div className="OrD1">
                  <div className="OrD11">State:</div>
                  <div>{form?.orgState ? form?.orgState : "-----"}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// export default ViewUserProfile;
const mapStateToProps = (state: any) => {
  return {
    ...state,
    userDetails: state?.usersReducer?.getUsersDetails,
  };
};
export default connect(mapStateToProps, {
  getUsers,
})(ViewUserProfile);
