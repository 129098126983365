import React from 'react';
import { Container } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
const PrivacyPolicy = (props: any) => {
    return (
        <>
            <Header />
            <Container>

                <div className='Privacy-policy'>
                    <h2>Privacy Policy</h2>
                    <div className='title'>Privacy Policy</div>
                    <div className='title'>Last Updated: 4/11/2023</div>

                    <div className='sub-element'>This Privacy Policy describes how HastkalaPramanak (“we,” “our,” or “us”) collects, uses, and discloses your personal information when you visit our website (“Website”). Please read this Privacy Policy carefully before using the Website..</div>
                    <b>Information We Collect</b>
                    <p><span className='title'>Personal Information:</span> We may collect personal information that you voluntarily provide when you interact with our Website. This may include your name, email address, phone number, and any other information you choose to provide.</p>
                    <p> <span className='title'>Automatically Collected Information:</span> When you visit our Website, we may automatically collect certain information about your device and usage patterns. This information may include your IP address, browser type, operating system, referring URLs, access times, and pages viewed.</p>
                    <b>Use of Your Information</b>
                    <p>We may use the information we collect for various purposes, including:</p>
                    <div className='sub-element'>– Providing and maintaining our Website.</div>
                    <div className='sub-element'>– Responding to your inquiries and requests.</div>
                    <div className='sub-element'>– Sending you updates, newsletters, and promotional materials.</div>
                    <div className='sub-element'>– Analyzing and improving our Website and services.</div>
                    <div className='sub-element'>– Monitoring and preventing fraud and unauthorized access.</div>

                    <b> Sharing Your Information</b>
                    <p>We do not sell, rent, or lease your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our Website and conducting our business.</p>
                    <p>We may also disclose your personal information as required by law, such as to comply with a subpoena, or similar legal processes, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to government requests.

                    </p>
                    <b>Your Choices</b>
                    <p>You can opt out of receiving promotional emails from us by following the instructions provided in those emails. Please note that even if you opt out, you will continue to receive transactional emails related to our services.</p>

                    <b>Security</b>
                    <p> We use reasonable administrative, technical, and physical measures to protect your personal information. However, no method of transmission over the internet or method of electronic storage is entirely secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                    <b>Changes to this Privacy Policy</b>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective immediately upon posting, and your continued use of the Website after the changes will signify your acceptance of those changes.</p>
                    <b>Contact Us</b>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at kunal@hastkalapramanak.in</p>

                </div>
            </Container>

            <Footer />
        </>
    )
}

export default PrivacyPolicy