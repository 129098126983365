import React from 'react'

const ImageAddIcon = (props:any) => {
    const {fileUploadTrigger}=props;
  return (
    <svg
    onClick={() => {
      fileUploadTrigger();
    }}
    className=""
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="34"
      height="34"
      rx="4"
      fill="#1A1A1A"
    />
    <path
      d="M17.5 12.5503V22.4497"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5503 17.5H22.4498"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default ImageAddIcon