import React from "react";

const PhotoIcon = () => {
  return (
    <svg
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 0H32V30H2V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M30.3333 6.66669H3.66667C2.74619 6.66669 2 7.41288 2 8.33335V26.6667C2 27.5872 2.74619 28.3334 3.66667 28.3334H30.3333C31.2538 28.3334 32 27.5872 32 26.6667V8.33335C32 7.41288 31.2538 6.66669 30.3333 6.66669Z"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33301 6.66669L7.13859 1.66669H13.5274L15.333 6.66669H5.33301Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1663 23.3334C24.388 23.3334 26.9997 20.7217 26.9997 17.5C26.9997 14.2784 24.388 11.6667 21.1663 11.6667C17.9447 11.6667 15.333 14.2784 15.333 17.5C15.333 20.7217 17.9447 23.3334 21.1663 23.3334Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 3.33331V6.66665"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PhotoIcon;
