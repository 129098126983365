import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { isArray } from "lodash";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// components
import AddParty from "./AddParty";
import ViewOrganization from "./ViewOrganization";
import Index from "../../common/Components/Index";
import { HeaderDataManufacturers } from "./Header";
import QuickFilter from "./QuickFilter";
import notifications from "../../common/Components/Notifications/Notification";

//files
import { debounce } from "../../common/commonFiles/debounce";
import {
  getOrganisation,
  connectOrg,
  getAssocciationsOrg,
} from "../../Store/organisation/actions";
var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
var gstOptions: any = [];
const Manufacturer = (props: any) => {
  const location: any = useLocation();
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  var data: any = [];
  const [openModalView, setOpenModalView]: any = useState(false);
  const [viewData, setViewData]: any = useState("");
  const [loading, setLoading]: any = useState(false);
  const [sortName, setSortName]: any = useState({
    name: "",
    order: "",
  });
  const [filters, setFilters]: any = useState({
    organization: "",
    gst: "",
    partyType: "",
  });
  const [openAddPart, setAddPart]: any = useState(false);
  const [addPartyDetails, setAddPartyDetails]: any = useState("");
  const handleOpenAddParty = () => {
    setAddPart(true);
  };
  const closeHandleAddParty = () => {
    setAddPart(false);
    setAddPartyDetails("");
  };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    if (sortName?.name?.length > 0) {
      getAllData();
    }
  }, [sortName]);
  useEffect(() => {
    if (
      filters?.organization?.length ||
      filters?.gst?.length ||
      filters?.partyType?.value?.length
    ) {
      handleChangeDebounce({ ...filters });
    }
  }, [filters]);
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      getAllData(data);
    }, 1000),
    []
  );
  const getAllData = (filters?: any) => {
    let payload: any = {
      offset: filters?.offset ? filters?.offset : offset,
      limit: limit,
      gst: filters?.gst,
      partyType: filters?.partyType?.value,
      name: filters?.organization,
    };
    setLoading(true);
    gstOptions = [];
    props.getAssocciationsOrg(payload, () => {
      setLoading(false);
    });
  };
  const handleOnchangeFilter = (type: any) => {
    offset = 0;

    setFilters({
      ...filters,
      [type.name]: type?.value,
    });
  };
  const openModalPopUpView = () => {
    setOpenModalView(true);
  };
  if (props.organisationDetails !== undefined) {
    if (
      isArray(props?.organisationDetails?.data?.docs) &&
      props?.organisationDetails?.data?.docs?.length > 0
    ) {
      totCnt = props?.organisationDetails?.data?.length;
      totPages = Math.ceil(totCnt / 10);
      fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
      toVal =
        props?.organisationDetails?.data?.offset * 10 +
        props?.organisationDetails?.data?.docs?.length;
      props?.organisationDetails?.data?.docs.map((ele: any, i: any) => {
        data.push({
          organization:
            ele?.manufacturer !== null ? ele?.manufacturer[0]?.name : "",
          address:
            ele?.manufacturer !== null
              ? ele?.manufacturer[0]?.address?.addressLane
              : "",
          phone: ele?.manufacturer !== null ? ele?.manufacturer[0]?.phone : "",
          gst: ele?.gst ? (
            <span style={{ textTransform: "uppercase" }}>{ele?.gst}</span>
          ) : (
            "N/A"
          ),
          partyType: ele?.partyType,
          createdAt: ele?.createdAt
            ? moment(new Date(ele?.createdAt))
                .subtract(5, "hours")
                .subtract(30, "minutes")
                .format("YYYY-MM-DD LT")
            : "NA",
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <span
                className="btns"
                onClick={() => {
                  setViewData({
                    _id: ele?._id,
                    name: ele?.manufacturer[0]?.name,
                    address: {
                      addressLane: ele?.manufacturer[0]?.address?.addressLane,
                      city: ele?.manufacturer[0]?.address?.city,
                      pinCode: ele?.manufacturer[0]?.address?.pinCode,
                      state: ele?.manufacturer[0]?.address?.state,
                    },
                    phone: ele?.manufacturer[0]?.phone,
                    gst: ele?.gst,
                    logo: ele?.logo,
                    tan: ele?.tan,
                    organizationType: ele?.organizationType,
                  });
                  openModalPopUpView();
                }}
              >
                View
              </span>
              <span className="actionLineHeight me-2"></span>
              <span
                className="btns"
                onClick={() => {
                  setAddPartyDetails({
                    id: ele?._id,
                    name: ele?.manufacturer[0]?.name,
                    address: {
                      addressLane: ele?.manufacturer[0]?.address?.addressLane,
                      city: ele?.manufacturer[0]?.address?.city,
                      pinCode: ele?.manufacturer[0]?.address?.pinCode,
                      state: ele?.manufacturer[0]?.address?.state,
                    },
                    phone: ele?.manufacturer[0]?.phone,
                    gst: ele?.gst,
                    logo: ele?.logo,
                    tan: ele?.tan,
                    partyType: ele?.partyType,
                  });
                  handleOpenAddParty();
                }}
              >
                Edit
              </span>
              {/*  {ele?.active ? (
                <span
                  className="btns"
                  // onClick={() => {
                  //   handleChangeStatus({
                  //     organizationId: ele?._id,
                  //     active: false,
                  //   });
                  // }}
                >
                  DeActivate
                </span>
              ) : (
                <span
                  className="btns"
                  // onClick={() => {
                  //   handleChangeStatus({
                  //     organizationId: ele?._id,
                  //     active: true,
                  //   });
                  // }}
                >
                  Activate
                </span>
              )} */}
            </div>
          ),
        });
      });
    } else {
      totCnt = 0;
      totPages = 0;
    }
  }
  const handlePageChange = (event: any, value: any) => {
    offset = (value - 1) * 1;
    getAllData({
      offset,
    });
  };
  const handleSort = (data: any) => {
    if (data?.name === "address") {
      setSortName({
        name: "address",
        order: data?.sort,
      });
    } else if (data?.name === "organization") {
      setSortName({
        name: "organization",
        order: data?.sort,
      });
    } else if (data?.name === "phone") {
      setSortName({
        name: "phone",
        order: data?.sort,
      });
    }
  };
  const CallingGetOrg = (value?: any) => {
    props.getOrganisation(
      {
        offset: 0,
        limit: 10,
        gst: value?.value,
        phone: value?.phone,
      },
      (res: any) => {
        setLoading(false);
        if (res?.data?.docs?.length) {
          gstOptions = [];
          res.data?.docs.map((ele: any, i: any) => {
            gstOptions.push({
              label:
                (ele?.gst?.length > 0 ? ele?.gst.toUpperCase() : "N/A") +
                "(" +
                ele?.name +
                ")" +
                "(" +
                ele?.phone +
                ")",
              value: {
                gst: ele?.gst,
                phone: ele?.phone,
              },
            });
          });
        }
      }
    );
  };
  const handleChangeDebounceforPartyFilters = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "gst") {
        CallingGetOrg(data);
      }
      if (data?.name === "phone") {
        CallingGetOrg(data);
      }
    }, 1000),
    []
  );
  const handleOnInputChangeFilters = (type: any) => {
    if (type?.name === "gst") {
      if (type?.value?.length >= 2) {
        handleChangeDebounceforPartyFilters({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "phone") {
      if (type?.value?.length >= 2) {
        handleChangeDebounceforPartyFilters({
          name: type?.name,
          phone: type?.value,
        });
      }
    }
  };
  const handleConnect = (payload: any) => {
    props.connectOrg(payload, (res: any) => {
      getAllData();
      gstOptions = [];
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const closeModalView = () => {
    setOpenModalView(false);
    setViewData("");
  };
  const handleResetData = () => {
    setFilters({
      gst: "",
      organization: "",
      partyType: "",
    });
    let payload: any = {
      offset: 0,
      limit: limit,
    };
    setLoading(true);
    props.getAssocciationsOrg(payload, () => {
      setLoading(false);
    });
  };
  return (
    <>
      <AddParty
        {...props}
        openModal={openAddPart}
        closeModal={closeHandleAddParty}
        gstOptions={gstOptions}
        handleOnInputChangeFilters={handleOnInputChangeFilters}
        handleConnect={handleConnect}
        handleOpenAddParty={handleOpenAddParty}
        partyDetails={addPartyDetails}
        getAllData={getAllData}
      />
      <Modal
        isOpen={openModalView}
        toggle={closeModalView}
        size="lg"
        fade={false}
        className="OrganizationModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>View Party</h3>
            <svg
              onClick={() => closeModalView()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <ViewOrganization
            {...props}
            closeModal={closeModalView}
            editData={viewData}
            getAllData={getAllData}
          />
        </ModalBody>
      </Modal>
      <QuickFilter
        {...props}
        location={location}
        gstOptions={gstOptions}
        handleOnInputChangeFilters={handleOnInputChangeFilters}
        handleConnect={handleConnect}
        handleOnchangeFilter={handleOnchangeFilter}
        handleResetData={handleResetData}
        filters={filters}
        getAllData={getAllData}
        handleOpenAddParty={handleOpenAddParty}
      />

      <Index
        {...props}
        data={data}
        headerData={HeaderDataManufacturers}
        limit={limit}
        handleChange={handlePageChange}
        offset={offset}
        totPages={totPages}
        loading={loading}
        handleSort={handleSort}
        fromVal={fromVal}
        toVal={toVal}
        totCnt={totCnt}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    organisationDetails: state?.organisationReducer?.getAssociationDetails,
  };
};
export default connect(mapStateToProps, {
  getOrganisation,
  connectOrg,
  getAssocciationsOrg,
})(Manufacturer);
