import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import { postApi, getApi, deleteApi, putApi } from "../../helpers/auth_helpers";

// workers
function* workerGetArtform({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let artformId = filters?.artFormId ? filters?.artFormId : "";
  let artformName = filters?.artformName ? filters?.artformName : "";
  let startDate = filters?.startDate ? filters?.startDate : "";
  let endDate = filters?.endDate ? filters?.endDate :"";
  let sortBy = filters?.sortBy ? filters?.sortBy :"createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy :"-1";
  let createdBy = filters?.createdBy ? filters?.createdBy :""
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/artForm/artForms?offset=" +
      offset +
      "&limit=" +
      limit +
      "&id=" +
      artformId +
      "&name=" +
      artformName+
      "&sortBy="+
      sortBy+
      "&orderBy="+
      orderBy+
      "&startDate="+
      startDate+
      "&endDate="+
      endDate+
      "&createdBy="+
      createdBy;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_ARTFORM_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetArtformFilters({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let artformName = filters?.artformName ? filters?.artformName : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/artForm/artForms?offset=" +
      offset +
      "&limit=" +
      limit +
      "&name=" +
      artformName;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_ARTFORM_FILTERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateArtform({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/artForm/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_ARTFORM_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateArtform({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/artForm/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_ARTFORM_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteArtform({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/artForm/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    yield put({
      type: types.DELETE_ARTFORM_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchGetArtform() {
  yield takeEvery(types.GET_ARTFORM, workerGetArtform);
}
export function* watchGetArtformFilter() {
  yield takeEvery(types.GET_ARTFORM_FILTERS, workerGetArtformFilters);
}
export function* watchCreateArtform() {
  yield takeEvery(types.CREATE_ARTFORM, workerCreateArtform);
}
export function* watchUpdateArtform() {
  yield takeEvery(types.UPDATE_ARTFORM, workerUpdateArtform);
}
export function* watchDeleteArtform() {
  yield takeEvery(types.DELETE_ARTFORM, workerDeleteArtform);
}

function* artformSaga() {
  yield all([fork(watchGetArtform)]);
  yield all([fork(watchGetArtformFilter)]);
  yield all([fork(watchCreateArtform)]);
  yield all([fork(watchUpdateArtform)]);
  yield all([fork(watchDeleteArtform)]);
}
export default artformSaga;
