import React from "react";
import ReactPlayer from "react-player/file";
const ProductPreviewImage = (props: any) => {
  const { fileUrl, handleOpen, name } = props;
  return (
    <>

      {(fileUrl.toLowerCase().endsWith(".jpg") ||
        fileUrl.toLowerCase().endsWith(".jpeg") ||
        fileUrl.toLowerCase().endsWith(".png") || fileUrl.toLowerCase().endsWith(".bmp")) && (
          <img src={fileUrl} alt="design" width={"80px"} height="80px"></img>
        )}
      {(
        fileUrl.toLowerCase().endsWith(".pdf")) && (
          <iframe
            title={`${(new Date() as any) / 1000}_pdf`}
            src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
            width={"80px"}
            height="80px"
            frameBorder="0"
          ></iframe>
        )}
      {(fileUrl.toLowerCase().endsWith(".doc")) && (
        <iframe
          title={`${(new Date() as any) / 1000}_doc`}
          src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
          width={"80px"}
          height="80px"
          frameBorder="0"
        ></iframe>
      )}
      {(fileUrl.toLowerCase().endsWith(".docx")) && (
        <iframe
          title={`${(new Date() as any) / 1000}_docx`}
          src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
          width={"80px"}
          height="80px"
          frameBorder="0"
        ></iframe>
      )}
      {(fileUrl.toLowerCase().endsWith(".mp4") ||
        fileUrl.toLowerCase().endsWith(".webm") ||
        fileUrl.toLowerCase().endsWith(".ogg")) && (
          <>
            {/* <video width={"80px"} height="80px" controls>
            <source src={fileUrl} type="video/mp4" />
          </video> */}
            <ReactPlayer
              playing={true}
              controls={true}
              url={fileUrl}
              loop={true}
              muted={true}
              width={"80px"} height="80px"
            // light={true}
            // playsinline={true}
            />
          </>

        )}
    </>
  );
};

export default ProductPreviewImage;
