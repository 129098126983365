import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";

const AddNfcTagModal = (props: any) => {
  const { closeNfcModal, openNfcModal, nfData, addNfcId, addNfc, nfcTagID } =
    props;
  const [nfcId, setNfcId]: any = useState(
    nfData?.xuid?.length > 0 ? nfData?.xuid : ""
  );

  useEffect(() => {
    setNfcId(nfcTagID);
  }, [nfcTagID]);

  const addingNfcId = () => {
    addNfcId({
      productId: nfData?._id,
      xuid: nfcId?.length > 0 ? nfcId.trim() : "",
    });
  };
  return (
    <Modal
      isOpen={addNfc}
      toggle={closeNfcModal}
      size="ms"
      className="ConfirmModal"
      fade={false}
    >
      <ModalHeader>
        <div className="Heading">
          <h3> {nfData?.xuid?.length > 0 ? "Edit HKP Tag" : "Add HKP Tag"}</h3>
          <svg
            onClick={() => closeNfcModal()}
            width="20"
            height="20"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1L1 8"
              stroke="#C59C6C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L8 8"
              stroke="#C59C6C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <Input
            name="addNfc"
            value={nfcId}
            placeholder="Type HKP Tag"
            onChange={(e: any) => setNfcId(e.target.value)}
          />
        </div>
        {/* <div className="ContentDiv">Are you sure want to delete?</div> */}
        {/* <div className="NameHeader">
          {name}:{" "}
          <span className="textCapitalize">{props?.deleteData?.name}</span>
        </div> */}
      </ModalBody>
      <ModalFooter>
        <div className="BtnFlex">
          <Button
            className="btnY1"
            onClick={() => {
              addingNfcId();
            }}
          >
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddNfcTagModal;
