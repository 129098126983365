import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import { postApi, getApi, deleteApi, putApi } from "../../helpers/auth_helpers";
import { filter } from "lodash";

// workers
function* workerGetOrganisation({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let OrganisationId = filters?.OrganisationId ? filters?.OrganisationId : "";
  let OrganisationName = filters?.label ? filters?.label : "";
  let phone = filters?.phone ? filters?.phone : "";
  let address = filters?.address ? filters?.address : "";
  let sortBy = filters?.sortBy ? filters?.sortBy : "createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy : "-1";
  let organizationType = filters?.organizationType
    ? filters?.organizationType
    : "";
  let gst = filters?.gst ? filters?.gst : "";
  let tan = filters?.tan ? filters?.tan : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/organization/organizations?offset=" +
      offset +
      "&limit=" +
      limit +
      "&id=" +
      OrganisationId +
      "&name=" +
      OrganisationName +
      "&organizationType=" +
      organizationType +
      "&GST=" +
      gst +
      "&tan=" +
      tan +
      "&phone=" +
      phone +
      "&address=" +
      address +
      "&sortBy=" +
      sortBy +
      "&orderBy=" +
      orderBy;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_ORGANISATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerConnectAssociation({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/addassociation";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CONNECT_ORG_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateParty({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/updateAssociation";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_PARTY_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetAssociations({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let gst = filters?.gst ? filters?.gst : "";
  let partyType = filters?.partyType ? filters?.partyType : "";
  let name = filters?.name ? filters?.name : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/organization/associations?offset=" +
      offset +
      "&limit=" +
      limit +
      "&GST=" +
      gst +
      "&partyType=" +
      partyType +
      "&name=" +
      name;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_ASSOCIATIONS_ORG_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateOrganisation({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_ORGANISATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateOrganisation({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_ORGANISATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteOrganisation({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    yield put({
      type: types.DELETE_ORGANISATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerChangeOrganizationStatus({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/toggleActiveStatus";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.CHANGE_ORGANISATION_STATUS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerChangeAssociationStatus({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/organization/toggleActiveStatus";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.ACTIVE_DEACTIVE_ASSOCIATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchChangeAssociationStatus() {
  yield takeEvery(
    types.ACTIVE_DEACTIVE_ASSOCIATION,
    workerChangeAssociationStatus
  );
}
export function* watchConnectAssociation() {
  yield takeEvery(types.CONNECT_ORG, workerConnectAssociation);
}
export function* watchUpdateParty() {
  yield takeEvery(types.UPDATE_PARTY, workerUpdateParty);
}
export function* watchGetAssociations() {
  yield takeEvery(types.GET_ASSOCIATIONS_ORG, workerGetAssociations);
}
export function* watchGetOrganisation() {
  yield takeEvery(types.GET_ORGANISATION, workerGetOrganisation);
}
export function* watchCreateOrganisation() {
  yield takeEvery(types.CREATE_ORGANISATION, workerCreateOrganisation);
}
export function* watchUpdateOrganisation() {
  yield takeEvery(types.UPDATE_ORGANISATION, workerUpdateOrganisation);
}
export function* watchDeleteOrganisation() {
  yield takeEvery(types.DELETE_ORGANISATION, workerDeleteOrganisation);
}
export function* watchChangeOrganizationStatus() {
  yield takeEvery(
    types.CHANGE_ORGANISATION_STATUS,
    workerChangeOrganizationStatus
  );
}
function* organisationSaga() {
  yield all([fork(watchChangeAssociationStatus)]);
  yield all([fork(watchConnectAssociation)]);
  yield all([fork(watchUpdateParty)]);
  yield all([fork(watchGetAssociations)]);
  yield all([fork(watchGetOrganisation)]);
  yield all([fork(watchCreateOrganisation)]);
  yield all([fork(watchUpdateOrganisation)]);
  yield all([fork(watchDeleteOrganisation)]);
  yield all([fork(watchChangeOrganizationStatus)]);
}
export default organisationSaga;
