import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/images/D-3 1.jpg";
//components
import notifications from "../../common/Components/Notifications/Notification";

// store
import { getUsers } from "../../Store/users/actions";
import {
  getOrganisation,
  updateOrganisation,
} from "../../Store/organisation/actions";
import {
  uploadImages,
  fetchMainLogoPresignedUrl,
} from "../../Store/uploadModule/actions";
const OrganizationProfile = (props: any) => {
  const [loading, setLoading]: any = useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          id: "",
          organization: "",
          address: "",
          phone: "",
          GST: "",
          TAN: "",
          profile: "",
        }
      : ""
  );
  useEffect(() => {
    getAllData();
  }, []);
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const getAllData = () => {
    setLoading(true);
    props.getUsers(
      {
        userId: authUser?.users?._id,
      },
      (res: any) => {
        setLoading(false);
        let organisationId: any =
          res?.data?.docs[0]?.organizationDetails[0]?._id;
        props.getOrganisation(
          {
            offset: 0,
            limit: 1,
            OrganisationId: organisationId,
          },

          (res: any) => {
            setLoading(false);
            let editDetails: any = res?.data?.docs[0];

            setForm({
              id: editDetails?._id,
              organization: editDetails?.name,
              address: editDetails?.address?.addressLane,
              phone: editDetails?.phone ? editDetails?.phone : "NA",
              GST: editDetails?.gst ? editDetails?.gst : "NA",
              TAN: editDetails?.tan ? editDetails?.tan : "NA",
              profile: editDetails?.logo ? editDetails?.logo : Logo,
            });
          }
        );
      }
    );
  };
  const fileInputProfile: any = React.useRef();
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  const S3Upload = async () => {
    if (selectedFile) {
      props.fetchMainLogoPresignedUrl(
        { fileType: selectedFile?.type },
        (res: any) => {
          let softCopy = "";

          softCopy = res.data.split("?")[0];
          setForm({ ...form, profile: softCopy });
          const formData = new FormData();
          formData.append(selectedFile, selectedFile.name);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFile.type);
          props.uploadImages(
            res.data + "&x-amz-acl=public-read",
            selectedFile,
            myHeaders
          );
        }
      );
    }
  };

  const fileUpload = (event: any) => {
    let name = event.target.name;
    if (name === "profileURL") {
      uploadImage(event);
      setSelectedFile(event.target.files[0]);
    }
  };
  const uploadImage = async (e: any) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    setBaseImageLogo(base64);
  };
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleSubmit = () => {
    // let result: any = validate();
    // if (result?.length === 0) {
    props.updateOrganisation(
      {
        id: form?.id,
        name: form?.organization,
        logo: form?.profile,
        phone: form?.phone,
        address: {
          addressLane: form?.address,
          city: "",
          state: "",
          pincode: "",
        },
        gst: form?.GST,
        tan: form?.TAN,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
          localStorage.setItem("logo", "true");
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  return (
    <>
      <div className="createUser">
        <div className="form">
          <div className="UserDetailsDiv">
            <div className="UserHeader">Organization Profile Details</div>
            <div className="userPd">
              <div className="d-flex justify-content-center">
                <div className="imageDiv">
                  <div className="editPen">
                    <svg
                      onClick={() => fileUploadTrigger()}
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="26" height="26" rx="4" fill="#262D22" />
                      <path
                        d="M16.3473 6.6267C16.546 6.42801 16.7819 6.2704 17.0415 6.16287C17.3011 6.05534 17.5793 6 17.8603 6C18.1413 6 18.4195 6.05534 18.6791 6.16287C18.9387 6.2704 19.1746 6.42801 19.3733 6.6267C19.572 6.82538 19.7296 7.06126 19.8371 7.32086C19.9447 7.58045 20 7.85869 20 8.13968C20 8.42066 19.9447 8.6989 19.8371 8.95849C19.7296 9.21809 19.572 9.45397 19.3733 9.65266L9.16069 19.8653L5 21L6.13473 16.8393L16.3473 6.6267Z"
                        stroke="#C69C6D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <input
                      type="file"
                      name="Image"
                      multiple={true}
                      accept=".png,.jpg,jpeg,.bmp"
                      style={{ display: "none" }}
                      ref={fileInputProfile}
                      onChange={(e) => {
                        fileUpload({
                          target: {
                            name: "profileURL",
                            files: e.target.files,
                          },
                        });
                      }}
                    />
                  </div>
                  {form?.profileURL?.length || baseImageLogo ? (
                    <img
                      src={baseImageLogo ? baseImageLogo : form?.profile}
                      alt="profile"
                      width="200px"
                      height={"200px"}
                    />
                  ) : (
                    <div>No Preview Available</div>
                  )}
                </div>
              </div>
              <div className="form">
                <div className="formDiv">
                  <Label>
                    Organization<span>*</span>
                  </Label>
                  <Input
                    name="organization"
                    id="organization"
                    className="form-control"
                    placeholder="Enter Company Name"
                    value={form?.organization}
                    onChange={(e: any) =>
                      setForm({
                        ...form,
                        organization: e.target.value,
                      })
                    }
                  />
                  {/* {Error.organization && !form?.organization ? (
              <p className="RequiredField">{Error?.organization} </p>
            ) : (
              ""
            )} */}
                </div>
                <div className="formDiv">
                  <Label>
                    Address<span>*</span>
                  </Label>
                  <Input
                    name="address"
                    id="address"
                    className="form-control"
                    placeholder="Enter Company Address"
                    value={form?.address}
                    onChange={(e: any) =>
                      setForm({
                        ...form,
                        address: e.target.value,
                      })
                    }
                  />
                  {/* {Error.address && !form?.address ? (
              <p className="RequiredField">{Error?.address} </p>
            ) : (
              ""
            )} */}
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>
                        Phone<span>*</span>
                      </Label>
                      <Input
                        name="phone"
                        id="phone"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.phone}
                        onChange={(e: any) =>
                          setForm({
                            ...form,
                            phone: e.target.value,
                          })
                        }
                      />
                      {/* {Error.phone && !form?.phone ? (
                  <p className="RequiredField">{Error?.phone} </p>
                ) : (
                  ""
                )} */}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>GST</Label>
                      <Input
                        name="GST"
                        id="GST"
                        className="form-control text-Uppercase"
                        placeholder="Enter Number"
                        value={form?.GST}
                        onChange={(e: any) =>
                          setForm({
                            ...form,
                            GST: e.target.value,
                          })
                        }
                      />
                      {/* {Error.GST && !form?.GST ? (
                  <p className="RequiredField">{Error?.GST} </p>
                ) : (
                  ""
                )} */}
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>TAN</Label>
                      <Input
                        name="TAN"
                        id="TAN"
                        className="form-control textUpper"
                        placeholder="Enter Number"
                        value={form?.TAN}
                        onChange={(e: any) =>
                          setForm({
                            ...form,
                            TAN: e.target.value,
                          })
                        }
                      />
                      {/* {Error.TAN && !form?.TAN ? (
                  <p className="RequiredField">{Error?.TAN} </p>
                ) : (
                  ""
                )} */}
                    </Col>
                  </Row>
                </div>

                <Button className="artButton" onClick={() => handleSubmit()}>
                  {props?.editData?._id ? "Update" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getOrganisation,
  fetchMainLogoPresignedUrl,
  uploadImages,
  getUsers,
  updateOrganisation,
})(withRouter(OrganizationProfile));
