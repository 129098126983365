export const types = {
  CREATE_APPLICATION:"CREATE_APPLICATION",
  CREATE_APPLICATION_SUCCESS:"CREATE_APPLICATION_SUCCESS",
  GET_APPLICATION:"GET_APPLICATION",
  GET_APPLICATION_SUCCESS:"GET_APPLICATION_SUCCESS",
  UPDATE_APPLICATION:"UPDATE_APPLICATION",
  UPDATE_APPLICATION_SUCCESS:"UPDATE_APPLICATION_SUCCESS",
  DELETE_APPLICATION:"DELETE_APPLICATION",
  DELETE_APPLICATION_SUCCESS:"DELETE_APPLICATION_SUCCESS"
    
}