import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// components Icons

import CloseIcon from "../Components/Icons/CloseIcon";
// store
import { getApplication } from "../../Store/application/actions";
import { getUsersPrpfileLogo } from "../../Store/users/actions";
import { getOrganisation } from "../../Store/organisation/actions";
// images
import closeIcon from "../../assets/images/Group 23.png";
import SideMiniExpand from "./SideMiniExpand";
import SideMiniCollaspe from "./SideMiniCollaspe";

const VerticalMenuBar = (props: any) => {
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let res: any = localStorage.getItem("response");
  let response: any = JSON.parse(res) || "";
  const location = useLocation();
  const history = useHistory();

  let orgLogoUrl: any = authUser?.users?.organizationResponse?.logo;

  const [name, setName]: any = useState("");
  const [logo, setLogo]: any = useState("");
  const [applicationUrl, setApplicationUrl]: any = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (
      response.status === 401 ||
      response.message === "UnAuthorized!" ||
      response.message === "Unauthorized!" ||
      response.status === 403 ||
      response.statusText === "Forbidden"
    ) {
      localStorage.clear();
      localStorage.removeItem("response");
      history.push("/login");
      localStorage.removeItem("response");
    }
  });

  const getAllData = () => {
    props.getUsersPrpfileLogo(
      {
        userId: authUser?.users?._id,
      },
      (res: any) => {
        if (res) {
          localStorage.setItem(
            "GetUserRes",
            JSON.stringify(res?.data?.docs[0])
          );

          if (res?.data?.docs[0].organizationDetails[0]?.logo?.length) {
            setLogo(res?.data?.docs[0].organizationDetails[0]?.logo);
          } else {
            props.getApplication((res1: any) => {
              setApplicationUrl(res1?.data?.docs[0]?.logo);
            });
          }
        }
      }
    );
  };
  const handleClick = (type: any) => {
    setName(type?.name);
  };
  return (
    <>
      {props.show && (
        <div id={"my-sidebar-mobile"} className={`mainExpandedStyle`}>
          <img src={closeIcon} width="10px" height={"10px"} alt="close" />
          <div className="closeIconStyle">
            <CloseIcon
              {...props}
              onClick={props.closeSideBar}
              stroke="#C59C6C"
            />
          </div>
          <SideMiniExpand
            {...props}
            // orgLogoUrl={orgLogoUrl}
            applicationUrl={applicationUrl}
            logo={logo}
            authUser={authUser}
            handleClick={handleClick}
            location={location}
          />
        </div>
      )}
      {props.mini && (
        <>
          <div
            id={props.show ? "my-sidebar-mobile" : "mySidebar"}
            className={`mainExpandedStyle`}
          >
            {props.show ? (
              <>
                <img src={closeIcon} width="10px" height={"10px"} alt="close" />
                <div className="closeIconStyle">
                  <CloseIcon
                    {...props}
                    onClick={props.closeSideBar}
                    stroke="#C59C6C"
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <SideMiniExpand
              {...props}
              // orgLogoUrl={orgLogoUrl}
              applicationUrl={applicationUrl}
              logo={logo}
              authUser={authUser}
              handleClick={handleClick}
              location={location}
            />
          </div>
        </>
      )}
      {props?.mini === false && (
        <>
          <div id="mySidebar" className={`mainExpandedStyles`}>
            <SideMiniCollaspe
              {...props}
              applicationUrl={applicationUrl}
              logo={logo}
              authUser={authUser}
              handleClick={handleClick}
              location={location}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  getOrganisation,
  getUsersPrpfileLogo,
  getApplication,
})(withRouter(VerticalMenuBar));
