import React, { useState, useEffect } from "react";
import { Button, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import HkvBadge from "../assets/images/HKP Badge.png";
import { productSendOtp, productKyc } from "../../../Store/products/actions";
import notifications from "../../../common/Components/Notifications/Notification";
import jwt from "jwt-decode";
import { debounce } from "../../../common/commonFiles/debounce";
import { useHistory, useLocation } from "react-router-dom";

const ProductSuccessFlow = (props: any) => {
  const history: any = useHistory();
  const location: any = useLocation();
  const ProductId: any = location?.pathname.split("/")[3];

  const endUsersDetails: any = localStorage.getItem("endUsersDetails");
  let userDe: any = JSON.parse(endUsersDetails);
  const [form, setForm]: any = useState({
    name: userDe?.name || "",
    mobile: userDe?.mobile || "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [errorMobile, setMobileError]: any = useState("");
  const [showOtp, setShowotp]: any = useState(false);
  const [Error, setError]: any = useState({
    name: "",
    mobile: "",
  });
  const [minutes, setMinutes] = useState(10);
  const [seconds, setSeconds] = useState(0);
  const [enable, setEnable]: any = useState(false);
  const [showVerify, setShowVerify]: any = useState(false);
  useEffect(() => {
    functionFocus("first");
  }, [showVerify, enable]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  useEffect(() => {
    if (form?.mobile?.length === 0 || form?.mobile?.length === 10) {
      setMobileError("");
    }
  }, [errorMobile]);
  const validate = () => {
    let error = "";
    let err = Error;
    if (!form?.name) {
      error = "Required";
      err = {
        ...err,
        name: "Name is required!",
      };
    }
    if (!form?.mobile) {
      error = "Required";
      err = {
        ...err,
        mobile: "Mobile number is required!",
      };
    }

    setError(err);
    return error;
  };
  const handleChangeValues = (type: any) => {
    setForm({
      ...form,
      [type?.name]: type?.value,
    });
  };
  const handleSubmit = () => {
    let payload: any = {
      phone: form.mobile,
    };
    let result: any = validate();
    if (result?.length === 0) {
      localStorage.setItem(
        "endUsersDetails",
        JSON.stringify({
          name: form?.name,
          mobile: form?.mobile,
        })
      );
      // test start
      // setShowotp(true);
      // setEnable(true);
      // setShowVerify(true);
      // functionFocus("first");
      // setMinutes(10);
      // setSeconds(0);
      // localStorage.setItem(
      //   "endUsersDetails",
      //   JSON.stringify({
      //     name: form?.name,
      //     mobile: form?.mobile,
      //   })
      // );
      // test end

      // history.push(`/otp-verifying/${ProductId}`);
      props.productSendOtp(payload, (res: any) => {
        if (res.success) {
          // notifications({
          //   title: "Success!",
          //   message: res?.message,
          //   type: "success",
          // });
          localStorage.setItem(
            "endUsersDetails",
            JSON.stringify({
              name: form?.name,
              mobile: form?.mobile,
            })
          );
          setShowotp(true);
          setEnable(true);
          setShowVerify(true);
          functionFocus("first");
          setMinutes(10);
          setSeconds(0); // history.push(`/otp-verifying/${ProductId}`);
        } else {
          // notifications({
          //   title: "Error!",
          //   message: res?.message,
          //   type: "danger",
          // });
        }
      });
    }
  };

  const handleResendOtp = () => {
    setMinutes(10);
    setSeconds(0);

    props.productSendOtp({ mobile: form?.mobile }, (res: any) => {
      if (res.success) {
        // notifications({
        //   title: "Success!",
        //   message: res?.message,
        //   type: "success",
        // });
      } else {
        // notifications({
        //   title: "Error!",
        //   message: res?.message,
        //   type: "danger",
        // });
      }
    });
  };
  const handleKycUserDetails = () => {
    props.productKyc(
      {
        name: form?.name,
        phone: form?.mobile,
        otp: form?.otp1 + form?.otp2 + form?.otp3 + form?.otp4,
      },
      (res: any) => {
        if (res.success) {
          // notifications({
          //   title: "Success!",
          //   message: res?.message,
          //   type: "success",
          // });
          let resPro: any = jwt(res?.data?.token);
          localStorage.setItem("tokenData", JSON.stringify(resPro));
          localStorage.setItem("productVerificationToken", res?.data?.token);
          localStorage.setItem(
            "productVerificationrefreshToken",
            res?.data?.refreshToken
          );
          history.push(
            `/product-certification-new/${
              location?.pathname.split("/")[2]
            }/${ProductId}`
          );
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };

  // const handleChangeValues = (type: any) => {
  //   setForm({
  //     ...form,
  //     [type?.name]: type?.value,
  //   });
  // };
  const functionFocus = (val: any) => {
    let element: any = document.getElementById(`${val}`);
    return element?.focus();
  };
  const handleChangeDebounceForMobile = React.useCallback(
    debounce(() => {
      setMobileError("Invalid mobile number");
    }, 1000),
    []
  );
  return (
    <div className="productSuccessFlow">
      <img className="backIcon" src={HkvBadge} alt="Hkv Badge" />
      {/* <svg
        className="backIcon"
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="16"
        viewBox="0 0 21 16"
        fill="none"
      >
        <path
          d="M1.25348 8.76337L6.85609 15.5663C7.31974 16.0852 8.06284 16.1346 8.55077 15.7352C9.0387 15.3357 9.11909 14.5284 8.71937 14.0405L4.72989 9.20073L19.7994 9.20073C20.4624 9.20073 21 8.66311 21 8.00013C21 7.33715 20.4624 6.79953 19.7994 6.79953L4.72989 6.79953L8.71937 1.95975C9.11882 1.47209 9.02893 0.675968 8.55077 0.265072C8.05753 -0.158665 7.25555 -0.0539818 6.85609 0.433954L1.25348 7.23689C0.888636 7.74967 0.919899 8.26662 1.25348 8.7627V8.76337Z"
          fill="#1C1C1C"
        />
      </svg> */}
      <div className="PleaseShareDiv">
        <span>Please enter following details</span>
      </div>
      <div className="InputFormDiv">
        <Label>Your name</Label>
        <Input
          type="text"
          placeholder="e.g. Neeta Mukherjee"
          value={form?.name}
          onChange={(e: any) =>
            handleChangeValues({
              name: "name",
              value: e.target.value,
            })
          }
        />
        {Error.name && !form?.name ? (
          <p className="RequiredField">{Error?.name} </p>
        ) : (
          ""
        )}
      </div>
      <div className="InputFormDiv">
        <div className="inputform">
          <Label>Your mobile number</Label>
          <Input
            style={{
              paddingLeft: "55px",
            }}
            type="number"
            placeholder="10 digit mobile number"
            value={form?.mobile}
            onChange={(e: any) => {
              handleChangeValues({
                name: "mobile",
                value:
                  e.target.value?.length <= 10 ? e.target.value : form?.mobile,
              });
              if (e.target.value?.length <= 9 || e.target.value?.length > 1) {
                handleChangeDebounceForMobile();
              }
              if (
                e.target.value?.length === 0 ||
                e.target.value?.length === 10
              ) {
                setMobileError("");
              }
            }}
          />
          <div className="div91">+91</div>
        </div>
        {Error.mobile && !form?.mobile ? (
          <p className="RequiredField">{Error?.mobile} </p>
        ) : (
          ""
        )}
        <p className="RequiredField">{errorMobile}</p>
        {/* {form?.mobile?.length < 10 && form?.mobile?.length > 1 ? (
          <p className="RequiredField">Invalid mobile number </p>
        ) : (
          ""
        )} */}
        <p>An OTP will be sent to this number</p>
      </div>
      {showOtp ? (
        <>
          <div className="InputFormDiv">
            <div className="inputform">
              <Label>Enter 4 digit OTP</Label>
              {/* <OtpVerify {...props} /> */}
              {/* <div className="InputFormDiv">
            <p>sent on +91 {form?.mobile}</p>
          </div> */}
              <div className="InputsOtpDIv">
                <Input
                  type="number"
                  id="first"
                  inputmode="numeric"
                  max="1"
                  value={form?.otp1}
                  onChange={(e: any) => {
                    handleChangeValues({
                      name: "otp1",
                      value:
                        e.target.value?.length <= 1
                          ? e.target.value
                          : form?.otp1,
                    });
                    functionFocus("sec");
                  }}
                />
                <Input
                  type="number"
                  id="sec"
                  inputmode="numeric"
                  maxlength="1"
                  value={form?.otp2}
                  onChange={(e: any) => {
                    handleChangeValues({
                      name: "otp2",
                      value: e.target.value?.length <= 1 ? e.target.value : "",
                    });
                    functionFocus("third");
                  }}
                />
                <Input
                  type="number"
                  id="third"
                  inputmode="numeric"
                  maxlength="1"
                  value={form?.otp3}
                  onChange={(e: any) => {
                    handleChangeValues({
                      name: "otp3",
                      value: e.target.value?.length <= 1 ? e.target.value : "",
                    });
                    functionFocus("fourth");
                  }}
                />
                <Input
                  type="number"
                  id="fourth"
                  onkeyup="clickEvent(this,'fourth') "
                  inputmode="numeric"
                  maxlength="1"
                  value={form?.otp4}
                  onChange={(e: any) => {
                    handleChangeValues({
                      name: "otp4",
                      value: e.target.value?.length <= 1 ? e.target.value : "",
                    });

                    setEnable(true);
                  }}
                />
              </div>
              <div className="expires">
                <div>
                  {minutes <= 0 && seconds <= 0 ? (
                    <>Otp is expired</>
                  ) : (
                    <>
                      {" "}
                      Expires in{" "}
                      <span>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>{" "}
                      seconds
                    </>
                  )}
                  {/* Expires in{" "}
                  <span>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>{" "}
                  seconds */}
                </div>{" "}
                <div>
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      handleResendOtp();
                    }}
                  >
                    Resend OTP
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {/* <div className="HkvDiv">
        <img className="HkvBadge" src={HkvBadge} alt="Hkv Badge" />
      </div> */}

      <div className="sendButtonDivUser">
        {form?.name && form?.mobile && enable === false ? (
          <Button
            onClick={() => {
              handleSubmit();
            }}
          >
            Send OTP
          </Button>
        ) : (
          ""
        )}
        {showVerify ? (
          <>
            {enable === true ? (
              <Button
                onClick={() => {
                  handleKycUserDetails();
                }}
              >
                Verify
              </Button>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  productSendOtp,
  productKyc,
})(ProductSuccessFlow);
