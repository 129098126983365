import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
// components
import DeleteConfirm from "../../common/Components/DeleteConfirm";
import { debounce } from "../../common/commonFiles/debounce";
import Index from "../../common/Components/Index";
import { HeaderData, HeaderDataForSuperAdmin } from "./Header";
import QuickFilter from "./QuickFilter";
import notifications from "../../common/Components/Notifications/Notification";

// store

import {
  deleteUsers,
  getUsers,
  getUserRoles,
  changeUsersStatus,
} from "../../Store/users/actions";
import { isArray } from "lodash";

var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
var userRolesOptions: any;
const User = (props: any) => {
  const history = useHistory();
  const [loading, setLoading]: any = useState(false);
  const [editData, setEditData]: any = useState("");
  const [deleteData, setDeleteData]: any = useState("");
  const [filters, setFilters]: any = useState("");
  const [sortName, setSortName]: any = useState({
    name: "",
    order: "",
  });
  const [warningModal, setWarningModal]: any = useState(false);

  useEffect(() => {
    getAllData();
  }, []);
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let organizationId =
    authUser?.users?.role !== "SUPER_ADMIN"
      ? authUser?.users?.organizationResponse?._id
      : "";
  const getAllData = (filter?: any) => {
    localStorage.setItem(
      "offsetForUsers",
      filter?.offset ? filter?.offset : offset
    );
    let orderByAsc = sortName?.order === "asc" ? "1" : "";
    let orderByDesc = sortName?.order === "desc" ? "-1" : "";
    setLoading(true);
    let offsetForUsers: any = localStorage.getItem("offsetForUsers");
    let filtersForUser: any = localStorage.getItem("FiltersForUsers");
    let filters: any = filter ? filter : JSON.parse(filtersForUser) || "";

    props.getUsers(
      {
        offset: offsetForUsers,
        limit: limit,
        usersName: filters?.name,
        phone: filters?.phone,
        email: filters?.email,
        role: filters?.role?.value,
        sortBy: sortName?.name,
        orderBy: orderByAsc || orderByDesc,
        organizationId,
      },
      () => {
        setLoading(false);
      }
    );
    setEditData("");
  };
  const getUserRolesData = () => {
    props.getUserRoles(
      {
        offset: 0,
        limit: 1000,
      },
      (res: any) => {
        userRolesOptions = [];
        res?.data?.docs.map((ele: any) => {
          userRolesOptions.push({
            label: ele?.role ? ele?.role.replace("_", " ") : "",
            value: ele?._id,
          });
        });
      }
    );
  };
  const handlePageChange = (event: any, value: any) => {
    offset = (value - 1) * 1;
    localStorage.setItem("offsetForUsers", offset);
    getAllData({
      ...filters,
      offset,
    });
  };
  var data: any = [];

  const deleteDataWithConfirm = (deleteData: any) => {
    props.deleteUsers(
      {
        userId: deleteData?._id,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const handleChangeStatus = (payload: any) => {
    props.changeUsersStatus(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        getAllData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  if (props.userDetails !== undefined) {
    if (
      isArray(props?.userDetails?.data?.docs) &&
      props?.userDetails?.data?.docs?.length > 0
    ) {
      totCnt = props?.userDetails?.data?.length;
      totPages = Math.ceil(totCnt / 10);
      fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
      toVal =
        props?.userDetails?.data?.offset * 10 +
        props?.userDetails?.data?.docs?.length;
      props?.userDetails?.data?.docs.map((ele: any, i: any) => {
        data.push({
          name: ele?.name,
          role: ele?.businessRoleDetails
            ? ele?.businessRoleDetails[0]?.role.replace("_", " ")
            : "",
          phone: ele?.phone,
          emailId: (
            <span
              style={{
                textTransform: "lowercase",
              }}
            >
              {ele?.email}
            </span>
          ),
          createdBy: ele?.createdBy?.role
            ? ele?.createdBy?.role.replace("_", " ")
            : "",
          createdAt: ele?.createdAt
            ? moment(new Date(ele?.createdAt))
                .subtract(5, "hours")
                .subtract(30, "minutes")
                .format("YYYY-MM-DD LT")
            : "NA",
          organization:
            authUser?.users?.role === "SUPER_ADMIN"
              ? ele?.organizationDetails[0]?.name
              : "",
          actions: (
            <div className="d-flex align-items-center tableBtns">
              {authUser?.users?.role === "ORGANIZATION_ADMIN" ||
              authUser?.users?.role === "SUPER_ADMIN" ? (
                <>
                  <span
                    className="btns"
                    onClick={() => {
                      history.push(`/viewUser/${ele?._id}`);
                    }}
                  >
                    View
                  </span>
                  <span className="actionLineHeight me-2"></span>
                  <span
                    className="btns"
                    onClick={() => {
                      history.push(`/editUser/${ele?._id}`);
                    }}
                  >
                    Edit
                  </span>
                  <span className="actionLineHeight"></span>
                  &nbsp;&nbsp;
                  {ele?.active ? (
                    <span
                      className="btns"
                      onClick={() => {
                        handleChangeStatus({
                          userId: ele?._id,
                          active: false,
                        });
                      }}
                    >
                      DeActivate
                    </span>
                  ) : (
                    <span
                      className="btns"
                      onClick={() => {
                        handleChangeStatus({
                          userId: ele?._id,
                          active: true,
                        });
                      }}
                    >
                      Activate
                    </span>
                  )}
                  {/* <span
                    className="btns"
                    onClick={() => {
                      setDeleteData(ele);
                      setWarningModal(true);
                    }}
                  >
                    Delete
                  </span> */}
                </>
              ) : (
                <>
                  <span
                    className="btns"
                    onClick={() => {
                      history.push(`/viewUser/${ele?._id}`);
                    }}
                  >
                    View
                  </span>
                </>
              )}
            </div>
          ),
        });
      });
    } else {
      totCnt = 0;
      totPages = 0;
    }
  }
  useEffect(() => {
    if (sortName?.name?.length > 0) {
      getAllData();
    }
  }, [sortName]);
  const handleSort = (data: any) => {
    if (data?.name === "name") {
      setSortName({
        name: "name",
        order: data?.sort,
      });
    } else if (data?.name === "role") {
      setSortName({
        name: "role",
        order: data?.sort,
      });
    } else if (data?.name === "phone") {
      setSortName({
        name: "phone",
        order: data?.sort,
      });
    } else if (data?.name === "emailId") {
      setSortName({
        name: "email",
        order: data?.sort,
      });
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      getAllData();
    }
  };
  const handleResetData = () => {
    localStorage.removeItem("FiltersForUsers");
    offset = 0;
    localStorage.setItem("offsetForUsers", offset);
    setFilters("");
    setFilters({
      role: "",
    });
    props.getUsers(
      {
        offset: offset,
        limit: limit,
        organizationId,
      },
      () => {
        setLoading(false);
      }
    );
  };
  const autoFocus = (data: any) => {
    if (data?.name === "role") {
      getUserRolesData();
    }
  };
  const handleOnInputChange = (data: any) => {
    if (
      (data?.value?.length >= 2 || data?.value?.length == 0) &&
      data?.name === "role"
    ) {
      props.getUserRoles(
        {
          offset: 0,
          limit: 10,
        },
        (res: any) => {
          userRolesOptions = [];
          res?.data?.docs.map((ele: any) => {
            userRolesOptions.push({
              label: ele?.role,
              value: ele?._id,
            });
          });
        }
      );
    }
  };
  const handleOnchangeFilter = (type: any) => {
    offset = 0;
    localStorage.setItem("offsetForUsers", offset);
    localStorage.setItem(
      "FiltersForUsers",
      JSON.stringify({
        ...filters,
        [type.name]: type?.value,
      })
    );
    setFilters({
      ...filters,
      [type.name]: type?.value,
    });
    if (type?.name === "role") {
      getAllData();
    } else {
      handleChangeDebounce();
    }
  };
  const handleChangeDebounce = React.useCallback(
    debounce(getAllData, 1000),
    []
  );
  const warningToggle = () => {
    setWarningModal(false);
  };
  return (
    <>
      <DeleteConfirm
        {...props}
        warningModal={warningModal}
        warningToggle={warningToggle}
        deleteDataWithConfirm={deleteDataWithConfirm}
        deleteData={deleteData}
        getAllData={getAllData}
        name={"User"}
      />
      <QuickFilter
        {...props}
        handleOnchangeFilter={handleOnchangeFilter}
        filters={filters}
        userRolesOptions={userRolesOptions}
        handleResetData={handleResetData}
        handleKeyDown={handleKeyDown}
        getAllData={getAllData}
        autoFocus={autoFocus}
        handleOnInputChange={handleOnInputChange}
      />
      <Index
        {...props}
        data={data}
        headerData={
          authUser?.users?.role === "SUPER_ADMIN"
            ? HeaderDataForSuperAdmin
            : HeaderData
        }
        limit={limit}
        handleChange={handlePageChange}
        offset={offset}
        totPages={totPages}
        loading={loading}
        handleSort={handleSort}
        fromVal={fromVal}
        toVal={toVal}
        totCnt={totCnt}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    userDetails: state?.usersReducer?.getUsersDetails,
  };
};
export default connect(mapStateToProps, {
  deleteUsers,
  getUsers,
  getUserRoles,
  changeUsersStatus,
})(User);
