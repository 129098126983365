import React from 'react'

const WeaveTypeIcon = () => {
  return (
    <svg
    width="25"
    height="25"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9965 2.07422C17.8527 2.45742 17.5685 2.57344 17.1721 2.57344C11.7135 2.56641 6.25141 2.56992 0.792828 2.56992C0.361333 2.56992 0.0912103 2.37305 0.0456051 2.01445C0.0280647 1.88789 0.0420971 1.76133 0.0420971 1.61016C0.112259 1.61016 0.175404 1.61016 0.235042 1.61016C0.768271 1.61016 1.29799 1.61016 1.83122 1.61016C1.91191 1.61016 1.9961 1.60664 2.07328 1.57852C2.20659 1.53281 2.28026 1.42031 2.27675 1.27969C2.27324 1.13203 2.19606 1.02305 2.04522 0.984375C1.97155 0.966797 1.89437 0.963281 1.8207 0.963281C1.29448 0.963281 0.768271 0.963281 0.242058 0.963281C0.178913 0.963281 0.115767 0.963281 0 0.963281C0.0456051 0.734765 0.0561294 0.520312 0.129799 0.333984C0.210485 0.123047 0.410446 0.028125 0.631456 0.00703125C0.705126 5.23869e-10 0.782304 0.00351562 0.859482 0.00351562C6.29702 0.00351562 11.7346 0.00703125 17.1686 0C17.565 0 17.8456 0.119531 17.9965 0.495703C17.9965 0.646875 17.9965 0.801562 17.9965 0.952734C17.9228 0.95625 17.8456 0.966797 17.772 0.966797C15.6601 0.966797 13.5517 0.966797 11.4399 0.966797C11.3522 0.966797 11.2645 0.970312 11.1803 0.98789C11.0329 1.02305 10.9523 1.12852 10.9487 1.27969C10.9452 1.43086 11.0189 1.53984 11.1627 1.58555C11.2434 1.61016 11.3346 1.61367 11.4223 1.61367C13.5377 1.61367 15.6531 1.61367 17.772 1.61367C17.8492 1.61367 17.9228 1.61719 18 1.6207C17.9965 1.77187 17.9965 1.92305 17.9965 2.07422ZM6.60573 1.61016C7.35997 1.61016 8.11421 1.61016 8.86845 1.61016C8.93159 1.61016 8.99825 1.61016 9.06139 1.59609C9.22978 1.56445 9.33502 1.43789 9.33502 1.2832C9.33502 1.125 9.22627 1.00547 9.05438 0.977343C8.99123 0.966797 8.92809 0.966797 8.86143 0.966797C7.35997 0.966797 5.85851 0.966797 4.35354 0.966797C4.28338 0.966797 4.21321 0.970312 4.14305 0.984375C3.98519 1.0125 3.90099 1.11797 3.89047 1.27617C3.87995 1.43437 3.97466 1.55742 4.13253 1.59258C4.19918 1.61016 4.26934 1.61367 4.33951 1.61367C5.09725 1.61367 5.85149 1.61016 6.60573 1.61016Z"
      fill="#C59C6C"
    />
    <path
      d="M15.2304 3C15.8103 3.13086 16 3.37432 16 3.7182C15.9783 5.08156 15.9892 6.44492 15.9892 7.80828C15.9892 7.86914 15.9892 7.93001 15.9892 8C15.3334 8 14.6938 8 14.0218 8C14.0164 7.93914 14.0055 7.87523 14.0055 7.81132C14.0055 6.44188 14.0109 5.07243 14.0001 3.70603C13.9947 3.36214 14.1952 3.11564 14.7968 3C14.9431 3 15.0895 3 15.2304 3Z"
      fill="#C59C6C"
    />
    <path
      d="M1.76961 17C1.18969 16.8691 1 16.6257 1 16.2818C1.02168 14.9184 1.01084 13.5551 1.01084 12.1917C1.01084 12.1309 1.01084 12.07 1.01084 12C1.66663 12 2.30616 12 2.97821 12C2.98363 12.0609 2.99447 12.1248 2.99447 12.1887C2.99447 13.5581 2.98905 14.9276 2.99989 16.294C3.00531 16.6379 2.80478 16.8844 2.20319 17C2.05685 17 1.91052 17 1.76961 17Z"
      fill="#C59C6C"
    />
    <path
      d="M2.23039 3C2.81031 3.13086 3 3.37432 3 3.7182C2.97832 5.08156 2.98916 6.44492 2.98916 7.80828C2.98916 7.86914 2.98916 7.93001 2.98916 8C2.33337 8 1.69384 8 1.02179 8C1.01637 7.93914 1.00553 7.87523 1.00553 7.81132C1.00553 6.44188 1.01095 5.07243 1.00011 3.70603C0.994687 3.36214 1.19522 3.11564 1.79681 3C1.94315 3 2.08948 3 2.23039 3Z"
      fill="#C59C6C"
    />
    <path
      d="M14.7696 17C14.1897 16.8691 14 16.6257 14 16.2818C14.0217 14.9184 14.0108 13.5551 14.0108 12.1917C14.0108 12.1309 14.0108 12.07 14.0108 12C14.6666 12 15.3062 12 15.9782 12C15.9836 12.0609 15.9945 12.1248 15.9945 12.1887C15.9945 13.5581 15.9891 14.9276 15.9999 16.294C16.0053 16.6379 15.8048 16.8844 15.2032 17C15.0569 17 14.9105 17 14.7696 17Z"
      fill="#C59C6C"
    />
    <path
      d="M7 9C7 9.67409 7 10.3315 7 11C4.66827 11 2.34135 11 0 11C0 10.3426 0 9.67967 0 9C2.32212 9 4.64904 9 7 9Z"
      fill="#C59C6C"
    />
    <path
      d="M17 9C17 9.67409 17 10.3315 17 11C14.6683 11 12.3413 11 10 11C10 10.3426 10 9.67967 10 9C12.3221 9 14.649 9 17 9Z"
      fill="#C59C6C"
    />
    <path
      d="M12 17C9.66667 17 7.33814 17 5 17C5 16.3278 5 15.6778 5 15C7.32852 15 9.65223 15 12 15C12 15.6444 12 16.3056 12 17Z"
      fill="#C59C6C"
    />
    <path
      d="M5 6C5 5.32773 5 4.67227 5 4C7.33654 4 9.66346 4 12 4C12 4.66667 12 5.32213 12 6C9.67788 6 7.35096 6 5 6Z"
      fill="#C59C6C"
    />
    <path
      d="M8 14C8 11.6635 8 9.33654 8 7C8.33426 7 8.66295 7 9 7C9 9.33173 9 11.6587 9 14C8.66574 14 8.33705 14 8 14Z"
      fill="#C59C6C"
    />
  </svg>
  )
}

export default WeaveTypeIcon