import React from 'react'
import SupportingMakers from "./components/SupportingMakers";
import WhatIsHastakalaPramank from "./components/whathastakala/WhatIsHastakalaPramank";
import WhyHastakalaParmank from "./components/whyhastakala/WhyHastakalaParmank";
import ImpactStackHolders from "./components/importstackholders/ImpactStackHolders";
import BenifitsOfStackHolders from "./components/benifits/BenifitsOfStackHolders";
import Footer from './components/Footer';
import HowToUse from "./components/howtouse/HowToUse";
import ProductInfo from './components/product/ProductInfo';
import Header from './components/Header';
const Home = (props:any) => {
    return (
        <>
            <div className="navbar-header">
                <Header {...props} />
            </div>
            <div
                className="page-content"
            >

                <SupportingMakers />
                <WhatIsHastakalaPramank />
                <WhyHastakalaParmank />
                <ProductInfo />
                <ImpactStackHolders />
                <BenifitsOfStackHolders />
                <HowToUse />
                <Footer />
            </div>
        </>

    )
}

export default Home