import React from 'react'
import {Card,CardBody, CardTitle,CardText } from "reactstrap";
const PricingCard = (props: any) => {
    let { key, item } = props;
  return (
      <React.Fragment key={key}>
          <div className='pricing-card'>
              <div className='card-header'>
          <h2>{item?.name}</h2>
          {
            item?.value === "Contact sales" ?
              <a href="https://wa.link/5edo3n" target="_blank"><h2>{ item?.value}</h2></a>:<h2>{ item?.value}</h2>
          }
                      
              </div>
              <div className='card-body'>
                  <p className='text'>{item?.content}</p>
                  <hr></hr>
                       <ul>
                  {
                      item?.listContent.map((list: any, i: any) => (
                
                              <React.Fragment key={i}>
                              <li>{list}</li>
                              </React.Fragment>
                       
                      ))
                      }
                         </ul>
              </div>
          
         </div>
    </React.Fragment>
  )
}

export default PricingCard