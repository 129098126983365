import React, { useState, useEffect } from 'react';
import { Card, Button } from '@mui/material'
import Container from '@mui/material/Container'
import menuIcon from '../assets/icons/icons8-menu-30.png'
import closeIcon from '../assets/icons/icons8-close-50.png'
import { Link } from 'react-router-dom';
const Header = (props:any) => {
  const [menuOpen, setMenuOpen] = useState(false)
 const [screenWidth, setScreenWidth] = useState(window.innerWidth);
 useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  return (
    <>
      <Card
        className='Header-Card'
        style={
          {
            // height: menuOpen ? "300px" : "70px",
            // marginTop: menuOpen ? "-300px" : ""
          }
        }
      >
        <Container className='Header-Container'>
          <Link to="/">
          <div className='Hastakala-logo'>
            <span className='sp1'>Hastkala</span>{' '}
            <span className='sp2'>Paramanak</span>
            <p>Supporting the makers, Protecting the buyers</p>
            </div>
            </Link>
          <ul className='Header-routes desktop-header'>
            <li>
              <a href='#Impact'>Impact</a>
            </li>
            <li>
              <a href='#Benefits'>Benefits</a>
            </li>
            <li>
              <Link to='/pricing'>Pricing</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li><a href=''>Contact Us</a></li>
          </ul>
          <div className='mobile-header'>
            {menuOpen ? (
              <img
                onClick={() => {
                  setMenuOpen(!menuOpen)
                }}
                src={closeIcon}
                width={20}
                height={20}
                alt='close'
              />
            ) : (
              <img
                onClick={() => {
                  setMenuOpen(!menuOpen)
                }}
                src={menuIcon}
                width={30}
                height={30}
                alt='menu'
              />
            )}
          </div>
        </Container>
      </Card>
      {(menuOpen && screenWidth < 678) && (
        <div
          className='mobile-header'
          onClick={() => {
            setMenuOpen(true)
          }}
        >
          <div className='Header-routes-mobile'>
           <div>
              <a href='#Impact'>Impact</a>
            </div>
            <div>
              <a href='#Benefits'>Benefits</a>
            </div>
             <div>
              <Link to='/pricing'>Pricing</Link>
            </div>
            <div>
              <Link to="/login">
                Login
             </Link>
            </div>
          
            <div><a href=''>Contact Us</a></div>
          </div>
        
        </div>
      )}
    </>
  )
}

export default Header
