import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1122 12.8791C26.2702 11.8193 26.2184 10.7342 25.9568 9.69088C25.6112 8.31263 24.8994 7.00738 23.8216 5.92956C22.7438 4.85176 21.4386 4.14006 20.0604 3.79447C19.0171 3.53286 17.9319 3.48105 16.8721 3.63903"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3686 12.3772C21.5379 11.2418 21.1855 10.0437 20.3115 9.16966C19.4374 8.29566 18.2394 7.94322 17.104 8.11247"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.98472 5.49658C9.43879 5.49658 9.85716 5.74288 10.0776 6.13988L11.6066 8.89413C11.8068 9.25475 11.8162 9.69094 11.6317 10.0599L10.1587 13.0059C10.1587 13.0059 10.5856 15.2006 12.3721 16.9871C14.1587 18.7736 16.3459 19.1931 16.3459 19.1931L19.2915 17.7203C19.6607 17.5357 20.0972 17.5453 20.4579 17.7458L23.22 19.2814C23.6167 19.5019 23.8626 19.9201 23.8626 20.3739V23.5449C23.8626 25.1597 22.3627 26.326 20.8326 25.8097C17.6902 24.7494 12.8122 22.7305 9.72048 19.6387C6.62866 16.5469 4.60979 11.669 3.54947 8.52656C3.0332 6.9965 4.19951 5.49658 5.81429 5.49658H8.98472Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
