export const HeaderData: any = {
  columns: [
    {
      label: "Name",
      field: "name",
      sort: true,
      width: 150,
    },
    {
      label: "Role",
      field: "role",
      sort: true,
      width: 200,
    },
    {
      label: "Phone",
      field: "phone",
      sort: true,
      width: 150,
    },
    {
      label: "Email id",
      field: "emailId",
      sort: true,
      width: 300,
    },
    {
      label: "Created by",
      field: "createdBy",
      sort: true,
      width: 200,
    },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 200,
    },
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
export const HeaderDataForSuperAdmin: any = {
  columns: [
    {
      label: "Name",
      field: "name",
      sort: true,
      width: 150,
    },
    {
      label: "Role",
      field: "role",
      sort: true,
      width: 200,
    },
    {
      label: "Phone",
      field: "phone",
      sort: true,
      width: 150,
    },
    {
      label: "Email id",
      field: "emailId",
      sort: true,
      width: 300,
    },
    {
      label: "Organization",
      field: "organization",
      sort: true,
      width: 300,
    },
    // {
    //   label: "Created by",
    //   field: "createdBy",
    //   sort: true,
    //   width: 200,
    // },
    {
      label: "Created at",
      field: "createdAt",
      sort: true,
      width: 200,
    },
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ],
};
