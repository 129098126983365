import React from "react";

const ResetIcon = () => {
  return (
    <svg
      width={20}
      height={18}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7601 2.04697V5.20218H9.60486"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.19019 10.4612V7.30597H4.34539"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.51015 4.67681C2.77702 3.92329 3.23037 3.24964 3.82792 2.71866C4.42547 2.18769 5.14776 1.81669 5.92742 1.64027C6.70709 1.46386 7.51874 1.48776 8.28667 1.70977C9.0546 1.93177 9.7538 2.34464 10.3191 2.90987L12.7602 5.2029M1.19019 7.30592L3.63064 9.59896C4.19589 10.1643 4.89513 10.5774 5.66315 10.7994C6.43116 11.0215 7.24291 11.0455 8.02268 10.8691C8.80245 10.6926 9.52484 10.3216 10.1224 9.79052C10.72 9.25945 11.1734 8.58566 11.4402 7.83202"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResetIcon;
