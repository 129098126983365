import React from "react";
import SelectorField from "../../common/Components/SearchBarField/SelectorField";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Toggle } from "rsuite";

const CreateProductHeader = (props: any) => {
  return (
    <>
      <div className="CreateProductHeader QuickFiltersHeader ps-3 pe-3">
        <div className="d-flex">
          <div className="InputColP">
            <Label check>Variant</Label>
            <FormGroup className="ms-2">
              <Toggle
                size="lg"
                // defaultChecked={false}
                checked={props.switchButton}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked: boolean, event) => {
                  props.handleSwitchButton(checked);
                }}
              />
            </FormGroup>
          </div>
          <div>
            <Button
              className="resetBtnMobile"
              onClick={() => {
                props.handleResetData();
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        <div className="InputColP">
          <SelectorField
            {...props}
            name="ProductFilter"
            placeholder="Select Product"
            options={props.productOptions}
            onChange={(e: any) =>
              props.handleOnchangeProductFilter({
                name: "productFilter",
                value: e,
              })
            }
            isDisabled={props.switchButton ? false : true}
            value={props.selectedProduct}
            onInputChange={(e: any) =>
              props.handleOnInputChangeFilters({
                name: "productFilter",
                value: e,
              })
            }
            // onFocus={(e: any) => {
            //   props.autoFocus({
            //     name: "artform",
            //   });
            // }}
          />
        </div>
        <div className="InputColP d768640"></div>
        <div className="InputColP1 searchIcon">
          <Button
            className="ResetBtn"
            onClick={() => {
              props.handleResetData();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateProductHeader;
