import React from 'react'
import { Container, Grid, Card, CardHeader, CardContent } from "@mui/material";

const ProductInfo = () => {
    let productInfoArr = [{
        title: "Consumer Adoption",
        value: "1500+",
        description: "Number of individuals who have utilized HastKalaPramanak's product verification services, reflecting its growing popularity among consumers seeking genuine handmade products and supporting the preservation of traditional crafts."
    }, {
        title: "Number of Products Verified",
        value: "500+",
        description: "HastKalaPramanak has authenticated a diverse range of handmade products, totaling [insert number] across various categories such as textiles, handicrafts, and artisanal goods."
    }, {
        title: "User Base",
        value: "10+",
        description: "Number of organizations, brands, and designers have adopted HastKalaPramanak to ensure the authenticity and traceability of their handmade products, demonstrating its widespread adoption within the industry"
    }, {
        title: "Global Reach",
        value: "10+ countries",
        description: "The number of countries in which users have utilized our services, reflecting the extent of our international presence and the geographic diversity of our user base"
    },]
    return (
        <div className='productinfo-div'> <Container>
            <h2 className="header-title">Metrics</h2>
            <Grid container spacing={3} className='product-grid'>
                {
                    productInfoArr.map((e, i) => {
                        return (<React.Fragment key={i}>
                            <Grid item xs={12} md={6}>
                                <Card className="product-card">
                                    <CardHeader title={e.title} className="Card-Header" />
                                    {/* <h3>{e.title}</h3> */}
                                    <CardContent className='Card-content'>
                                        <p className='value'>{e?.value}</p>
                                        <p className='p-desc'>{e?.description}</p>
                                    </CardContent>

                                </Card>
                            </Grid>
                        </React.Fragment>)
                    })
                }
            </Grid>
        </Container>
        </div>
    )
}

export default ProductInfo