import React from "react";

const ProductWeaveIcon = () => {
  return (
    <svg
      // className="tooltip"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#EAD6C0" />
      <path
        d="M27.4605 17C27.9548 17.4672 28.4558 17.9344 28.9298 18.3813C27.9548 19.3563 27.0407 20.2703 26.1469 21.1641C25.6933 20.7104 25.2058 20.2229 24.6709 19.688C24.6709 18.8484 24.6709 17.9276 24.6709 17.0068C25.5985 17 26.5329 17 27.4605 17Z"
        fill="#8C673E"
      />
      <path
        d="M35.3354 17C35.3354 17.9141 35.3354 18.8214 35.3354 19.688C34.8072 20.2161 34.313 20.7104 33.8322 21.1911C32.9385 20.2974 32.0312 19.3901 31.1104 18.4693C31.5775 17.9885 32.065 17.4943 32.5458 17C33.4734 17 34.401 17 35.3354 17Z"
        fill="#8C673E"
      />
      <path
        d="M17 24.6714C17.9141 24.6714 18.8281 24.6714 19.688 24.6714C20.2161 25.1995 20.7104 25.6938 21.1911 26.1745C20.2974 27.0683 19.3901 27.9756 18.4693 28.8964C17.9885 28.4292 17.4943 27.9485 17 27.4677C17 26.5334 17 25.599 17 24.6714Z"
        fill="#8C673E"
      />
      <path
        d="M43 27.461C42.5328 27.9552 42.0656 28.4563 41.6188 28.9302C40.6438 27.9552 39.7297 27.0412 38.8359 26.1474C39.2896 25.6938 39.7771 25.2063 40.3187 24.6714C41.1651 24.6714 42.0792 24.6714 43 24.6714C43 25.599 43 26.5334 43 27.461Z"
        fill="#8C673E"
      />
      <path
        d="M17 32.5391C17.4672 32.0381 17.9344 31.5438 18.3745 31.0698C19.3563 32.0516 20.2635 32.9657 21.1641 33.8594C20.7104 34.3131 20.2229 34.8006 19.688 35.3354C18.8484 35.3354 17.9276 35.3354 17.0068 35.3354C17 34.4011 17 33.4735 17 32.5391Z"
        fill="#8C673E"
      />
      <path
        d="M42.9997 35.3355C42.0857 35.3355 41.1784 35.3355 40.2982 35.3355C39.7836 34.8141 39.2893 34.3131 38.8086 33.8323C39.6956 32.9454 40.6029 32.0381 41.5305 31.1105C42.0112 31.5777 42.5055 32.0584 42.9997 32.5459C42.9997 33.4735 42.9997 34.4011 42.9997 35.3355Z"
        fill="#8C673E"
      />
      <path
        d="M24.6709 43C24.6709 42.086 24.6709 41.1787 24.6709 40.312C25.199 39.7907 25.6933 39.2964 26.174 38.8157C27.0678 39.7094 27.9751 40.6167 28.8959 41.5375C28.4287 42.0183 27.948 42.5125 27.4673 43C26.5329 43 25.5985 43 24.6709 43Z"
        fill="#8C673E"
      />
      <path
        d="M32.5395 43C32.0385 42.5328 31.5442 42.0656 31.0635 41.6255C32.0452 40.6438 32.9593 39.7297 33.8531 38.8359C34.3067 39.2896 34.7942 39.7771 35.3291 40.312C35.3291 41.1516 35.3291 42.0724 35.3291 42.9932C34.4015 43 33.4739 43 32.5395 43Z"
        fill="#8C673E"
      />
      <path
        d="M33.8326 28.8693C32.9388 27.9756 32.0315 27.0683 31.1445 26.1813C32.0383 25.2875 32.9523 24.3735 33.8461 23.4797C34.7195 24.3532 35.6336 25.2672 36.5341 26.161C35.6471 27.0547 34.7331 27.9688 33.8326 28.8693Z"
        fill="#8C673E"
      />
      <path
        d="M33.8189 36.5339C32.9319 35.6469 32.0246 34.7396 31.1309 33.8458C32.0314 32.9453 32.9454 32.0313 33.846 31.1307C34.7397 32.0245 35.647 32.9318 36.534 33.8188C35.6335 34.7125 34.7262 35.6266 33.8189 36.5339Z"
        fill="#8C673E"
      />
      <path
        d="M32.6883 22.3422C31.8014 23.2292 30.9008 24.1365 30.0071 25.0303C29.1066 24.1297 28.1993 23.2157 27.292 22.3152C28.1857 21.4282 29.093 20.5209 29.9732 19.6339C30.8738 20.5277 31.7878 21.4417 32.6883 22.3422Z"
        fill="#8C673E"
      />
      <path
        d="M26.1607 23.4594C27.0612 24.3599 27.9685 25.2672 28.8622 26.1609C27.9685 27.0547 27.0612 27.962 26.1742 28.8489C25.2805 27.9552 24.3732 27.0411 23.4727 26.1474C24.3461 25.2739 25.2602 24.3599 26.1607 23.4594Z"
        fill="#8C673E"
      />
      <path
        d="M19.6338 29.9796C20.5208 29.0927 21.4213 28.1854 22.315 27.2916C23.2156 28.1921 24.1161 29.0994 25.0031 29.9796C24.1161 30.8666 23.2088 31.7739 22.3218 32.6609C21.4484 31.7942 20.5411 30.8869 19.6338 29.9796Z"
        fill="#8C673E"
      />
      <path
        d="M30.0072 27.2849C30.9009 28.1854 31.8082 29.0927 32.6952 29.9797C31.7947 30.8802 30.8874 31.7875 29.9936 32.6813C29.1134 31.8011 28.1993 30.887 27.2988 29.9932C28.1926 29.1063 29.0999 28.199 30.0072 27.2849Z"
        fill="#8C673E"
      />
      <path
        d="M34.9902 29.9662C35.8637 29.0927 36.771 28.1854 37.6579 27.2985C38.5585 28.199 39.4725 29.113 40.3663 30.0068C39.4861 30.887 38.5788 31.7943 37.6986 32.6745C36.8116 31.7943 35.9043 30.8802 34.9902 29.9662Z"
        fill="#8C673E"
      />
      <path
        d="M23.4521 33.8458C24.3527 32.9453 25.2667 32.0313 26.1673 31.1307C27.061 32.0245 27.9683 32.9318 28.8553 33.8188C27.9548 34.7193 27.0407 35.6333 26.1402 36.5339C25.26 35.6537 24.3459 34.7396 23.4521 33.8458Z"
        fill="#8C673E"
      />
      <path
        d="M29.9732 40.3661C29.093 39.4859 28.1857 38.5786 27.292 37.6917C28.1925 36.7911 29.1066 35.8771 30.0071 34.9766C30.8941 35.8635 31.8014 36.7708 32.6816 37.6578C31.7946 38.5448 30.8805 39.4589 29.9732 40.3661Z"
        fill="#8C673E"
      />
      <span className="tooltiptext">Weaver </span>
    </svg>
  );
};

export default ProductWeaveIcon;
