import React from "react";

const BackButton = (props: any) => {
  return (
    <svg
      className="cursor-pointer"
      onClick={() => {
        if (props.name === "createProduct") props.onClick();
      }}
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="30" height="30" rx="4" fill="#1A1A1A" />
      <path
        d="M12.5625 17.125L8.5 13.0625L12.5625 9"
        stroke="#C59C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 22V16.3125C21.5 15.4505 21.1576 14.6239 20.5481 14.0144C19.9386 13.4049 19.112 13.0625 18.25 13.0625H8.5"
        stroke="#C59C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackButton;
