import React, { useState } from "react";
import { Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";

//components
import { debounce } from "../../common/commonFiles/debounce";
import Loader from "../../common/Components/Loader/Loader";
import notifications from "../../common/Components/Notifications/Notification";
// actions
import {
  createArtform,
  updateArtforms,
  getArtformsWithFilters,
} from "../../Store/artform/actions";
const AddOrEditArtForm = (props: any) => {
  const [loading, setLoading]: any = React.useState(false);
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          artform: props.editData?.name,
        }
      : {
          artform: "",
        }
  );
  const [Error, setError]: any = React.useState({
    artform: "",
  });
  const [filterValue, setFilterValue]: any = useState("");

  const callingGetArtform = (value: any) => {
    props.getArtformsWithFilters(
      {
        offset: 0,
        limit: 10,
        artformName: value,
      },
      (res: any) => {
        if (res?.data?.docs?.length) {
          res.data?.docs.map((ele: any, i: any) => {
            if (value === ele?.name) {
              setFilterValue(ele?.name);
            }
          });
        }
      }
    );
  };
  const handleChangeDebounce = React.useCallback(
    debounce((e: any) => {
      callingGetArtform(e.target.value);
    }, 1000),
    []
  );
  const validate = () => {
    let error = "";
    if (form?.artform === "") {
      error = "Required";
      setError({
        artform: "ArtForm is Required!",
      });
    }
    return error;
  };
  const handleSubmit = () => {
    let result: any = validate();
    if (result?.length === 0) {
      setLoading(true);
      props?.editData?._id
        ? props.updateArtforms(
            {
              artFormId: props?.editData?._id,
              name: form?.artform,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          )
        : props.createArtform(
            {
              name: form?.artform,
            },
            (res: any) => {
              setLoading(false);
              if (res.success) {
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props.closeModal();
                props.getAllData();
                props.handleName({ name: "artform", artform: form?.artform });
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
            }
          );
      setForm({
        artform: "",
      });
    }
  };

  return (
    <>
      <div className="AddArtform">
        <div className="form">
          {loading ? (
            <div className="LoaderDivModal">
              <Loader />
            </div>
          ) : (
            <div className="formDiv">
              <Label>
                Artform<span>*</span>
              </Label>
              <Input
                name="artform"
                id="artform"
                className="form-control"
                placeholder="Type Name"
                value={form?.artform}
                onChange={(e: any) => {
                  setForm({
                    artform: e.target.value,
                  });
                  handleChangeDebounce(e);
                }}
              />
              {Error.artform && !form?.artform ? (
                <p className="RequiredField">{Error?.artform} </p>
              ) : (
                ""
              )}
              {form?.artform && form?.artform === filterValue ? (
                // filterValue?.product === ""
                <p className="RequiredField">Artform is already exist's</p>
              ) : (
                ""
              )}
            </div>
          )}
          <Button className="artButton" onClick={() => handleSubmit()}>
            {loading ? "Processing" : "Save"}
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createArtform,
  updateArtforms,
  getArtformsWithFilters,
})(AddOrEditArtForm);
