import {types } from "./actionTypes";

// creating action
export const getTypes = (filters:any, callback:any) =>{
    return {
        type:types.GET_TYPES,
        filters,
        callback
    }
}
export const getTypesFilters = (filters:any, callback:any) =>{
    return {
        type:types.GET_TYPES_FILTERS,
        filters,
        callback
    }
}

export const createType = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_TYPES,
        payload,
        callback
    }
}
export const updateType = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_TYPES,
        payload,
        callback
    }
}
export const deleteType = (payload:any, callback:any) =>{
    return {
        type:types.DELETE_TYPES,
        payload,
        callback
    }
}