import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
// components
import { debounce } from "../../common/commonFiles/debounce";
import Index from "../../common/Components/Index";
import { HeaderData, HeaderDataOrgAdmin } from "./Header";
import QuickFilter from "./QuickFilter";
import notifications from "../../common/Components/Notifications/Notification";
import ViewArtfom from "../artform/ViewArtfom";
import WeaveTypeModal from "./WeaveTypeModal";
import DeleteConfirm from "../../common/Components/DeleteConfirm";

// store
import { getSubCategorys } from "../../Store/actions";
import { getUsers } from "../../Store/users/actions";
import { getCategory } from "../../Store/actions";
import { getTypes } from "../../Store/type/actions";
import { getArtforms } from "../../Store/artform/actions";
import { deleteWeaveType, getWeaveType } from "../../Store/weave-type/actions";
import { isArray } from "lodash";

var offset: any = 0;
var limit: any = 10;
var totPages = 0;
var totCnt: any = 0;
var toVal: any = 0;
var fromVal: any = 0;
// selector options
var artformOptions: any = [];
var typeOptions: any = [];
var categoryOptions: any = [];
var subcategoryOptions: any = [];
var creatorOptions: any = [];

const WeaveType = (props: any) => {
  const [loading, setLoading]: any = useState(false);
  const [openModal, setOpenModal]: any = useState(false);
  const [editData, setEditData]: any = useState("");
  const [deleteData, setDeleteData]: any = useState("");
  const [filters, setFilters]: any = useState({
    artform: "",
    type: "",
    category: "",
    subCategory: "",
    weaveType: "",
    creator: "",
  });
  const [filterDateValue, setFilterDateValue]: any = useState(null);
  const [handleResetFlag, setResetFlag]: any = useState(false);
  const [sortName, setSortName]: any = useState({
    name: "",
    order: "",
  });
  const [warningModal, setWarningModal]: any = useState(false);

  const [inputChange, setInputChange]: any = useState("");
  const [openViewModal, setOpenViewModal]: any = useState(false);
  const [viewData, setViewData]: any = useState("");
  const openViewModalPopUp = () => {
    setOpenViewModal(true);
  };
  // const [filterDate,setFilterDate]:any=useState({
  //   startDate:null,
  //   endDate:null
  // })
  const openModalPopUp = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    setEditData("");
    setOpenViewModal(false);
    setViewData("");
  };
  let auth: any = localStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let organizationId =
    authUser?.users?.role === "ORGANIZATION_MANAGER" ||
    authUser?.users?.role === "ORGANIZATION_ADMIN"
      ? authUser?.users?.organizationResponse?._id
      : "";
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    if (sortName?.name?.length > 0) {
      getAllData();
    }
  }, [sortName]);
  useEffect(() => {
    if (filters?.weaveType?.length) {
      handleChangeDebounce({ ...filters, filterDateValue });
    } else if (
      filters?.artform?.value ||
      filters?.type?.value ||
      filters?.category?.value ||
      filters?.subCategory?.value ||
      filters?.creator?.value
    ) {
      getAllData({
        ...filters,
        filterDateValue,
      });
    }
  }, [filters]);
  useEffect(() => {
    if (filterDateValue?.length > 0) {
      getAllData({ ...filters, filterDateValue });
    }
  }, [filterDateValue]);

  const getAllData = (filters?: any) => {
    let orderByAsc = sortName?.order === "asc" ? "1" : "";
    let orderByDesc = sortName?.order === "desc" ? "-1" : "";
    let dateValue = filters?.filterDateValue || filterDateValue;
    let startDate =
      dateValue?.length && moment(dateValue[0]).format("YYYY-MM-DD");
    let endDate =
      dateValue?.length && moment(dateValue[1]).format("YYYY-MM-DD");
    setLoading(true);
    props.getWeaveType(
      {
        offset: filters?.offset ? filters?.offset : offset,
        limit: limit,
        artformId: filters?.artform?.value,
        typeId: filters?.type?.value,
        categoryId: filters?.category?.value,
        subCategoryId: filters?.subCategory?.value,
        weaveTypeName: filters?.weaveType,
        startDate,
        endDate,
        sortBy: sortName?.name,
        orderBy: orderByAsc || orderByDesc,
        createdBy: filters?.creator?.value,
      },
      () => {
        setLoading(false);
      }
    );
    setEditData("");
  };
  const getSubCategoryData = (data?: any) => {
    props.getSubCategorys(
      {
        offset: 0,
        limit: 10,
        categoryId: filters?.category?.value || data?.category,
        typeId: filters?.type?.value || data?.type,
        artformId: filters?.artform?.value || data?.artform,
        subCategoryName: data?.value,
      },
      (res: any) => {
        subcategoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          subcategoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getCreatorsData = (data?: any) => {
    props.getUsers(
      {
        offset: offset,
        limit: 10,
        organizationId,
        usersName: data?.value,
      },
      (res: any) => {
        creatorOptions = [];
        res?.data?.docs.map((ele: any) => {
          creatorOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getCategoryData = (data?: any) => {
    props.getCategory(
      {
        offset: 0,
        limit: 10,
        typeId: filters?.type?.value || data?.type,
        artformId: filters?.artform?.value || data?.artform,
        categoryName: data?.value,
      },
      (res: any) => {
        categoryOptions = [];
        res?.data?.docs.map((ele: any) => {
          categoryOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getTypesData = (data?: any) => {
    props.getTypes(
      {
        offset: 0,
        limit: 10,
        artFormId: filters?.artform?.value || data?.artform,
        typeName: data?.value,
      },
      (res: any) => {
        typeOptions = [];
        res?.data?.docs.map((ele: any) => {
          typeOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };
  const getArtformData = (data?: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 10,
        artformName: data?.value,
      },
      (res: any) => {
        artformOptions = [];
        res?.data?.docs.map((ele: any) => {
          artformOptions.push({
            label: ele?.name,
            value: ele?._id,
          });
        });
      }
    );
  };

  const handlePageChange = (event: any, value: any) => {
    offset = (value - 1) * 1;
    getAllData({
      ...filters,
      offset,
      filterDateValue,
    });
  };
  var data: any = [];
  const deleteDataWithConfirm = (deleteData: any) => {
    props.deleteWeaveType(
      {
        weaveTypeId: deleteData?._id,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const getArtFormViewData = (id: any) => {
    props.getArtforms(
      {
        offset: 0,
        limit: 1,
        artFormId: id,
      },
      (res: any) => {
        artformOptions = [];
        setViewData(res?.data?.docs[0]);
      }
    );
  };
  if (props.weaveTypeDetails !== undefined) {
    if (
      isArray(props?.weaveTypeDetails?.data?.docs) &&
      props?.weaveTypeDetails?.data?.docs?.length > 0
    ) {
      totCnt = props?.weaveTypeDetails?.data?.length;
      totPages = Math.ceil(totCnt / 10);
      fromVal = totCnt !== 0 ? offset * 10 + 1 : fromVal;
      toVal =
        props?.weaveTypeDetails?.data?.offset * 10 +
        props?.weaveTypeDetails?.data?.docs?.length;
      props?.weaveTypeDetails?.data?.docs.map((ele: any, i: any) => {
        data.push({
          subCategory: ele?.subcategory?.name,
          weaveType: ele?.name,
          category: ele?.category?.name,
          type: ele?.type?.name,
          artForm: (
            <span
              onClick={() => {
                getArtFormViewData(ele?.artform?._id);
                openViewModalPopUp();
              }}
            >
              {ele?.artform?.name}
            </span>
          ),
          createdBy: ele?.createdBy?.role
            ? ele?.createdBy?.role?.replace("_", " ")
            : "NA",
          createdAt: ele?.createdAt
            ? moment(new Date(ele?.createdAt))
                .subtract(5, "hours")
                .subtract(30, "minutes")
                .format("YYYY-MM-DD LT")
            : "NA",
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <span
                className="btns"
                onClick={() => {
                  setEditData(ele);
                  openModalPopUp();
                }}
              >
                Edit
              </span>
              <span className="actionLineHeight"></span>
              &nbsp;&nbsp;
              <span
                className="btns"
                onClick={() => {
                  setDeleteData(ele);
                  setWarningModal(true);
                }}
              >
                Delete
              </span>
            </div>
          ),
        });
      });
    } else {
      totCnt = 0;
      totPages = 0;
    }
  }
  const handleOnchangeFilter = (type: any) => {
    offset = 0;
    setFilters({
      ...filters,
      [type.name]: type?.value,
    });
  };
  const handleChangeDebounce = React.useCallback(
    debounce((data: any) => {
      getAllData(data);
    }, 1000),
    []
  );
  const handleResetData = () => {
    setResetFlag(true);
    setFilters({
      artform: "",
      type: "",
      category: "",
      weaveType: "",
      subCategory: "",
      creator: "",
    });
    setFilterDateValue(null);
    props.getWeaveType(
      {
        offset: offset,
        limit: limit,
        artformId: "",
        typeId: "",
        categoryId: "",
        subCategoryId: "",
        weaveTypeName: "",
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleChangeDate = (date: any) => {
    offset = 0;
    setFilterDateValue(date);
  };
  const handleSort = (data: any) => {
    if (data?.name === "weaveType") {
      setSortName({
        name: "weavetype",
        order: data?.sort,
      });
    } else if (data?.name === "artForm") {
      setSortName({
        name: "artform",
        order: data?.sort,
      });
    } else if (data?.name === "type") {
      setSortName({
        name: "type",
        order: data?.sort,
      });
    } else if (data?.name === "category") {
      setSortName({
        name: "category",
        order: data?.sort,
      });
    } else if (data?.name === "subCategory") {
      setSortName({
        name: "subcategory",
        order: data?.sort,
      });
    } else if (data?.name === "createdAt") {
      setSortName({
        name: "createdAt",
        order: data?.sort,
      });
    } else if (data?.name === "createdBy") {
      setSortName({
        name: "createdBy",
        order: data?.sort,
      });
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      getAllData();
    }
  };
  const autoFocus = (data: any) => {
    let value = "";
    if (data?.name === "artform") {
      getArtformData(value);
    } else if (data?.name === "creator") {
      getCreatorsData();
    } else if (data?.name === "type") {
      getTypesData();
    } else if (data?.name === "category") {
      getCategoryData();
    } else if (data?.name === "subCategory") {
      getSubCategoryData();
    }
  };
  const handleChangeDebounce1 = React.useCallback(
    debounce((data: any) => {
      if (data?.name === "artform") {
        getArtformData(data);
      }
      if (data?.name === "type") {
        getTypesData(data);
      }
      if (data?.name === "category") {
        getCategoryData(data);
      }
      if (data?.name === "subCategory") {
        getSubCategoryData(data);
      }
      if (data?.name === "creator") {
        getCreatorsData(data);
      }
    }, 1000),
    []
  );
  const handleOnInputChange = (type: any) => {
    if (type?.name === "artform") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
        });
      }
      if (
        type?.value.length === 0 &&
        !filters?.artform?.value &&
        !filters?.type?.value &&
        !filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
        });
      }
    }
    if (type?.name === "type") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.artform?.value &&
        !filters?.type?.value &&
        !filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
        });
      }
    }
    if (type?.name === "category") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.type?.value &&
        !filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
        });
      }
    }
    if (type?.name === "subCategory") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
          category: filters?.category?.value,
        });
      } else if (
        type?.value.length === 0 &&
        filters?.category?.value &&
        !filters?.subCategory?.value &&
        !filters?.weaveType?.value
      ) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
          artform: filters?.artform?.value,
          type: filters?.type?.value,
          category: filters?.category?.value,
        });
      }
    }
    if (type?.name === "creator") {
      if (type?.value?.length >= 2) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
        });
      }
      if (type?.value.length === 0) {
        handleChangeDebounce1({
          name: type?.name,
          value: type?.value,
        });
      }
    }
  };
  const warningToggle = () => {
    setWarningModal(false);
  };
  return (
    <>
      {openViewModal && (
        <ViewArtfom
          {...props}
          closeModal={closeModal}
          openModal={openViewModal}
          viewData={viewData}
        />
      )}
      <WeaveTypeModal
        {...props}
        openModal={openModal}
        closeModal={closeModal}
        editData={editData}
        getAllData={getAllData}
      />
      <DeleteConfirm
        {...props}
        warningModal={warningModal}
        warningToggle={warningToggle}
        deleteDataWithConfirm={deleteDataWithConfirm}
        deleteData={deleteData}
        getAllData={getAllData}
        name={"Weave Type"}
      />
      <QuickFilter
        {...props}
        openModalPopUp={openModalPopUp}
        artformOptions={artformOptions}
        handleOnchangeFilter={handleOnchangeFilter}
        typeOptions={typeOptions}
        categoryOptions={categoryOptions}
        subcategoryOptions={subcategoryOptions}
        creatorOptions={creatorOptions}
        filters={filters}
        handleResetData={handleResetData}
        handleChangeDate={handleChangeDate}
        filterDateValue={filterDateValue}
        getAllData={getAllData}
        handleKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        handleOnInputChange={handleOnInputChange}
      />
      <Index
        {...props}
        data={data}
        headerData={
          authUser?.users?.role === "ORGANIZATION_MANAGER" ||
          authUser?.users?.role === "ORGANIZATION_ADMIN"
            ? HeaderDataOrgAdmin
            : HeaderData
        }
        limit={limit}
        handleChange={handlePageChange}
        offset={offset}
        totPages={totPages}
        loading={loading}
        handleSort={handleSort}
        fromVal={fromVal}
        toVal={toVal}
        totCnt={totCnt}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    weaveTypeDetails: state?.weaveTypeReducer?.getWeaveTypeDetails,
  };
};
export default connect(mapStateToProps, {
  deleteWeaveType,
  getWeaveType,
  getArtforms,
  getTypes,
  getCategory,
  getUsers,
  getSubCategorys,
})(WeaveType);
