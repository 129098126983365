import {types } from "./actionTypes";

// creating action
export const getSubCategorys = (filters:any, callback:any) =>{
    return {
        type:types.GET_SUB_CATEGORY,
        filters,
        callback
    }
}
export const getSubCategorysFilter = (filters:any, callback:any) =>{
    return {
        type:types.GET_SUB_CATEGORY_FILETRS,
        filters,
        callback
    }
}

export const createSubCategory = (payload:any, callback:any) =>{
    return {
        type:types.CREATE_SUB_CATEGORY,
        payload,
        callback
    }
}
export const updateSubCategory = (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_SUB_CATEGORY,
        payload,
        callback
    }
}
export const deleteSubCategory = (payload:any, callback:any) =>{
    return {
        type:types.DELETE_SUB_CATEGORY,
        payload,
        callback
    }
}