import React from "react";

function SortAssendingicon(props: any) {
  return (
    <svg
      onClick={props.handleSort}
      className="cursor-pointer ms-1"
      width="10"
      height="15"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 0L0.113249 5H5.88675L3 0ZM2.5 11.5C2.5 11.7761 2.72386 12 3 12C3.27614 12 3.5 11.7761 3.5 11.5H2.5ZM2.5 4.5V11.5H3.5V4.5H2.5Z"
        fill="#C59C6C"
      />
    </svg>
  );
}

export default SortAssendingicon;
