import { types } from "./actionTypes";
const initialState = {
  getOrganisationDetails: null,
  updateOrganisationDetails: null,
  createOrganisationDetails: null,
  deleteOrganisationDetails: null,
  getAssociationDetails: null,
};
const organisationReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ORGANISATION_SUCCESS:
      state = {
        ...state,
        getOrganisationDetails: action.payload,
      };
      return state;
    case types.GET_ASSOCIATIONS_ORG_SUCCESS:
      state = {
        ...state,
        getAssociationDetails: action.payload,
      };
      return state;
    case types.CREATE_ORGANISATION_SUCCESS:
      state = {
        ...state,
        createOrganisationDetails: action.payload,
      };
      return state;
    case types.UPDATE_ORGANISATION_SUCCESS:
      state = {
        ...state,
        updateOrganisationDetails: action.payload,
      };
      return state;
    case types.DELETE_ORGANISATION_SUCCESS:
      state = {
        ...state,
        deleteOrganisationDetails: action.payload,
      };
      return state;

    default:
      return state;
  }
};
export default organisationReducer;
