import React from "react";
import { Container, Grid } from "@mui/material";
import HastakalaCard from "./HastakalaCard";
let cardData = [{
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Objective-verification-1.svg",
  content: "Objective verification of product"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Enhanced-transparency-1.svg",
  content: "Enhanced transparency and data integrity with blockchain"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Unique-identity-1.svg",
  content: "Unique digital identity"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Fraud-detection-1.svg",
  content: "Fraud detection with AI"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/product-journey-1.svg",
  content: "Trace product journey"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Enhanced-visibility-1.svg",
  content: "Enhanced visibility of creator"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Genuine-products-1.svg",
  content: "Buy genuine products with confidence"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/No-need-of-an-App-1.svg",
  content: "No need of an App"
}, {
  icon: "https://hastkala.fistbump.digital/wp-content/uploads/2023/10/Safe-guards-IPR-1.svg",
  content: "Safe guards IPR of creators"
}]
const WhatIsHastakalaPramank = (props:any) => {
  return (
    <div className="hastakala-paramank-div">
      <Container>
        <div className="header">
          <h2 className="header-title">What is <span>Hastkala</span>Pramanak?</h2>
          <p>HastKala Pramanak is a service dedicated to <b>promoting and authenticating
            handmade products</b>, particularly from the handloom and handicraft industry in India.</p>
        </div>
        <Grid container spacing={2}>
          {
            cardData.map((ele, i) => (
              <React.Fragment key={i}>
                <Grid item xs={12} sm={6} md={4}>
                  <HastakalaCard {...props} data={ele} />
                </Grid>

              </React.Fragment>
            ))
          }
        </Grid>

      </Container>
    </div>
  );
};

export default WhatIsHastakalaPramank;
