import React, { useState } from "react";
import ReactPlayer from "react-player/file";

const FilePreview = ({
  fileUrl,
  height,
  width,
  handleOpen,
  handleSetImage,
}: any) => {
  let preview;

  if (
    fileUrl.toLowerCase().endsWith(".jpg") ||
    fileUrl.toLowerCase().endsWith(".jpeg") ||
    fileUrl.toLowerCase().endsWith(".png")
  ) {
    preview = <img src={fileUrl} alt="Preview" height={height} width={width} />;
  } else if (fileUrl.toLowerCase().endsWith(".pdf")) {
    preview = (
      <div>
        {/* {handleDownload()} */}
        <iframe
          title={`${(new Date() as any) / 1000}_pdf`}
          src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
          height={height}
          width={width}
          frameBorder="0"
        ></iframe>
        {/* <embed
          src={fileUrl}
          type="application/pdf"
          height="100%"
          width="100%"
        /> */}
      </div>
    );
  } else if (
    fileUrl.toLowerCase().endsWith(".docx") ||
    fileUrl.toLowerCase().endsWith(".doc")
  ) {
    preview = (
      <div>
        {/* {handleDownload()} */}
        <iframe
          title="RulesDocx"
          src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
          height={height}
          width={width}
          frameBorder="0"
        ></iframe>
        {/* <embed src={fileUrl} type="application/msword" height="100%" width="100%" /> */}
      </div>
    );
  } else if (
    fileUrl.toLowerCase().endsWith(".mp4") ||
    fileUrl.toLowerCase().endsWith(".webm") ||
    fileUrl.toLowerCase().endsWith(".ogg")
  ) {
    preview = (
      <>
        {/* <video height="180px" width="210px" controls>
          <source src={fileUrl} type="video/mp4" />
        </video> */}
        <ReactPlayer
          playing={false}
          controls={true}
          url={fileUrl}
          muted={true}
        // light={true}
        // playsinline={true}
        /></>

    );
  } else {
    preview = (
      <div className="NoFileDiv">
        <p>Preview not available for this file type.</p>
      </div>
    );
  }
  return (
    <div
      onClick={() => {
        handleOpen();
        handleSetImage(fileUrl);
      }}
    >
      {preview}
    </div>
  );
};

export default FilePreview;
