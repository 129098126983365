import { types } from "./actionTypes";
const initialState = {
  getSubCategoryDetails: null,
  updateSubCategoryDetails: null,
  createSubCategoryDetails: null,
  deleteSubCategoryDetails: null,
  getSubCategoryFilterDetails: null,
};
const subCategoryReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_SUB_CATEGORY_SUCCESS:
      state = {
        ...state,
        getSubCategoryDetails: action.payload,
      };
      return state;
    case types.GET_SUB_CATEGORY_FILETRS_SUCCESS:
      state = {
        ...state,
        getSubCategoryFilterDetails: action.payload,
      };
      return state;
    case types.CREATE_SUB_CATEGORY_SUCCESS:
      state = {
        ...state,
        createSubCategoryDetails: action.payload,
      };
      return state;
    case types.UPDATE_SUB_CATEGORY_SUCCESS:
      state = {
        ...state,
        updateSubCategoryDetails: action.payload,
      };
      return state;
    case types.DELETE_SUB_CATEGORY_SUCCESS:
      state = {
        ...state,
        deleteSubCategoryDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default subCategoryReducer;
