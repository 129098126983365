export const types = {
  CREATE_WEAVE_TYPE:"CREATE_WEAVE_TYPE",
  CREATE_WEAVE_TYPE_SUCCESS:"CREATE_WEAVE_TYPE_SUCCESS",
  GET_WEAVE_TYPE:"GET_WEAVE_TYPE",
  GET_WEAVE_TYPE_SUCCESS:"GET_WEAVE_TYPE_SUCCESS",
  GET_WEAVE_TYPE_FILTER:"GET_WEAVE_TYPE_FILTER",
  GET_WEAVE_TYPE_FILTER_SUCCESS:"GET_WEAVE_TYPE_FILTER_SUCCESS",
  UPDATE_WEAVE_TYPE:"UPDATE_WEAVE_TYPE",
  UPDATE_WEAVE_TYPE_SUCCESS:"UPDATE_WEAVE_TYPE_SUCCESS",
  DELETE_WEAVE_TYPE:"DELETE_WEAVE_TYPE",
  DELETE_WEAVE_TYPE_SUCCESS:"DELETE_WEAVE_TYPE_SUCCESS"
    
}