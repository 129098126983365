import { Redirect } from "react-router-dom";
import Login from "../pages/login/Login";
import Products from "../pages/products/Products";
import EditProduct from "../pages/products/EditProduct";
import ViewProduct from "../pages/products/ViewProduct";
import CreateProduct from "../pages/products/CreateProduct";
import Category from "../pages/category/Category";
import Dashboard from "../pages/dashboard/Dashboard";
import User from "../pages/users/User";
import Artform from "../pages/artform/Artform";
import Type from "../pages/type/Type";
import SubCategory from "../pages/sub-category/SubCategory";
import WeaveType from "../pages/weave-type/WeaveType";
import CreateUserWithRoute from "../pages/users/CreateUserWithRoute";
import EditUser from "../pages/users/EditUser";
import ViewUser from "../pages/users/ViewUser";
import OrganizationProfile from "../pages/profile/OrganizationProfile";
import CompanyProfile from "../pages/profile/CompanyProfile";
import PreviewProduct from "../pages/products/PreviewProduct";
import ProductVerification from "../pages/products/ProductVerification";
import ViewUserProfile from "../pages/profile/ViewUserProfile";
import ProductApiFailed from "../pages/products/ProductApiFailed";
import ProductSuccessFlow from "../pages/products/certification-page/ProductSuccessFlow";
// revamp pages
// import ProductNewCertification from "../pages/products/certification-page/ProductNewCertification";
import ProductFakeAlert from "../pages/products/certification-page/ProductFakeAlert";
import ProductFakeAlertInfomation from "../pages/products/certification-page/ProductFakeAlertInfomation";
import ProductReportedPage from "../pages/products/certification-page/ProductReportedPage";
// import OtpVerify from "../pages/products/certification-page/OtpVerify";
import VerifyingProduct from "../pages/products/certification-page/VerifyingProduct";
import QRCodeProduct from "../pages/products/common components/QRCodeProduct";
import AuthenticatePage from "../pages/products/certification-page/AuthenticatePage";
import OrganizationParent from "../pages/organisations/OrganizationParent";
import ManufacturerParent from "../pages/organisations/ManufacturerParent";
// import DesignAgency from "../pages/organisations/DesignAgency";
import { ProductSomethingWentToWrong } from "../pages/products/certification-page/ProductSomethingWentToWrong";
// import ProductCertificationForDesignAgency from "../pages/products/certification-page/ProductCertificationForDesignAgency";
import ProductCertificationTantuvi from "../pages/products/ProductCertificationTantuvi";
import ProductCertificationTantuviPreview from "../pages/products/ProductCertificationTantuviPreview";
import EditProfileOrganizationDetails from "../pages/profile/EditProfileOrganizationDetails";
import ExpiryLinkPage from "../pages/products/ExpiryLinkPage";
import { UIDErrorPage } from "../pages/products/certification-page/UIDErrorPage";
import ForgetPassword from "../pages/login/ForgetPassword";
import ResetPassword from "../pages/login/ResetPassword";
import Home from "../pages/website/Home";
import TermsAndConditions from "../pages/website/components/TermsAndConditions";
import PrivacyPolicy from "../pages/website/components/PrivacyPolicy";
import Pricing from "../pages/website/components/pricing/Pricing";
const publicRoutes = [
  { path: "/home", exact: true, component: Home },
  { path: "/login", exact:true,component: Login },
  { path: "/forget-password",exact:true, component: ForgetPassword },
  { path: "/reset-password", exact:true,component: ResetPassword },
  // { path: "/registerUser", exact: true, component: CreateUser },
  {
    path: "/product-certification",
    exact: true,
    component: ProductVerification,
  },
  {
    path: "/product-verifing",
    exact: true,
    component: VerifyingProduct,
  },
  {
    path: "/authentication-page",
    exact: true,
    component: AuthenticatePage,
  },
  { path: "/user-deatails", exact: true, component: ProductSuccessFlow },
  { path: "/terms-and-conditions", exact: true, component: TermsAndConditions },
  { path: "/privacy-policy", exact: true, component:PrivacyPolicy },
  { path: "/pricing", exact: true, component:Pricing },

  // {
  //   path: "/otp-verifying",
  //   exact: true,
  //   component: OtpVerify,
  // },

  { path: "/fake-alert", exact: true, component: ProductFakeAlert },
  {
    path: "/product-fake-alert-user-information",
    exact: true,
    component: ProductFakeAlertInfomation,
  },
  {
    path: "/product-reported",
    exact: true,
    component: ProductReportedPage,
  },
  {
    path: "/product-not-mapped-error",
    exact: true,
    component: UIDErrorPage,
  },

  {
    path: "/page-failed",
    exact: true,
    component: ProductApiFailed,
  },
  {
    path: "/page-error",
    exact: true,
    component: ProductSomethingWentToWrong,
  },
  //revamp pages
  {
    path: "/product-certification-new",
    exact: true,
    component: QRCodeProduct,
  },
  {
    path: "/product-certification-tantuvi-new",
    exact: true,
    component: ProductCertificationTantuvi,
  },
  {
    path: "/product-link-expired",
    exact: true,
    component: ExpiryLinkPage,
  },
  // {
  //   path: "/product-certification-new1",
  //   exact: true,
  //   component: ProductCertificationForDesignAgency,
  // },
];
const privateRoutes = [
  { path: "/products", exact: true, component: Products },
  { path: "/edit-product/:id", exact: true, component: EditProduct },
  { path: "/view-product/:id", exact: true, component: ViewProduct },
  { path: "/createProduct", exact: true, component: CreateProduct },
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/users", exact: true, component: User },
  { path: "/createUser", exact: true, component: CreateUserWithRoute },
  { path: "/editUser/:id", exact: true, component: EditUser },
  { path: "/viewUser/:id", exact: true, component: ViewUser },
  { path: "/category", exact: true, component: Category },
  { path: "/sub-category", exact: true, component: SubCategory },
  { path: "/type", exact: true, component: Type },
  { path: "/artform", exact: true, component: Artform },
  { path: "/weave-type", exact: true, component: WeaveType },
  { path: "/organisation", exact: true, component: OrganizationParent },
  { path: "/profile", exact: true, component: OrganizationProfile },
  {
    path: "/edit-profile-user-details",
    exact: true,
    component: CompanyProfile,
  },
  {
    path: "/edit-profile-organisation-details",
    exact: true,
    component: EditProfileOrganizationDetails,
  },

  { path: "/view-user-profile", exact: true, component: ViewUserProfile },
  { path: "/pre-viewProduct", exact: true, component: PreviewProduct },
  {
    path: "/pre-ViewProduct-tantuvi",
    exact: true,
    component: ProductCertificationTantuviPreview,
  },
  { path: "/party", exact: true, component: ManufacturerParent },
  // { path: "/manufactures", exact: true, component: DesignAgency },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];
export { publicRoutes, privateRoutes };
