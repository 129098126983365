import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import AddOrEditTypeForm from "./AddOrEditTypeForm";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
const TypeModal = (props: any) => {
  const { openModal, closeModal, editData, getAllData, handleName, form } =
    props;
  return (
    <Modal
      isOpen={openModal}
      toggle={closeModal}
      size="lg"
      fade={false}
      className="TypeModal"
    >
      <ModalHeader>
        <div className="Heading">
          <h3>{editData?._id ? "Edit Type" : "Add New Type"}</h3>
          <ModalCloseIcon {...props} closeModal={closeModal} />
        </div>
      </ModalHeader>
      <ModalBody>
        <AddOrEditTypeForm
          {...props}
          closeModal={closeModal}
          editData={editData}
          getAllData={getAllData}
          handleName={handleName}
          form={form}
        />
      </ModalBody>
    </Modal>
  );
};

export default TypeModal;
