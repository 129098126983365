import { all } from "redux-saga/effects";
// importing all sagas
import authLoginSaga from "./auth/login/saga";
import artformSaga from "./artform/saga";
import usersSaga from "./users/saga";
import typeSaga from "./type/saga";
import productSaga from "./products/saga";
import categorySaga from "./category/saga";
import subCategorySaga from "./sub-category/saga";
import weaveTypeSaga from "./weave-type/saga";
import organisationSaga from "./organisation/saga";
import uploadModule from "./uploadModule/saga";
import ApplicationSaga from "./application/saga";
import dashboardSaga from "./dashboard/saga";
export default function* rootSaga() {
  yield all([
    authLoginSaga(),
    artformSaga(),
    categorySaga(),
    typeSaga(),
    productSaga(),
    organisationSaga(),
    weaveTypeSaga(),
    subCategorySaga(),
    usersSaga(),
    uploadModule(),
    ApplicationSaga(),
    dashboardSaga(),
  ]);
}
