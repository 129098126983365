import Footer from "./Footer"
import Header from "./Header"
import { Container } from '@mui/material';

const TermsAndConditions = (props: any) => {
  return (
    <>
      <Header />
      <Container>
        <div className='Terms-conditions'>
          <h2>Terms and Conditions</h2>
          <div className='title'>Terms and Conditions</div>
          <div className='sub-element'>These terms and conditions (“Terms”) govern your use of the HastkalaPramanak website (“Website”). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Website.</div>
          <b>Use of the Website</b>
          <p>You may use the Website for lawful purposes only and in accordance with these Terms.</p>
          <p>You must not:</p>
          <div className='sub-element'>– Violate any applicable laws, regulations, or third-party rights.</div>
          <div className='sub-element'>– Use the Website for any fraudulent, harmful, or unlawful activity.</div>
          <div className='sub-element'>– Engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the Website.</div>
          <b>Intellectual Property</b>
          <p>The content on this Website, including text, graphics, images, logos, and software, is the property of HastkalaPramanak or its licensors and is protected by copyright and other intellectual property laws.</p>
          <p>You may not modify, reproduce, distribute, display, or create derivative works from any content on this Website without prior written consent from HastkalaPramanak.

            3. Privacy</p>
          <p>Your use of the Website is subject to our Privacy Policy, which can be found [here](insert link to Privacy Policy).</p>
          <b>Disclaimer</b>
          <p>The information provided on this Website is for general informational purposes only. HastkalaPramanak makes no representations or warranties regarding the accuracy or completeness of the information.</p>
          <p>HastkalaPramanak is not responsible for any errors or omissions or for the results obtained from the use of this information.</p>
          <b>Limitation of Liability</b>
          <p>To the fullest extent permitted by applicable law, HastkalaPramanak and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
          <div className='sub-element'>– Your use or inability to use the Website.</div>
          <div className='sub-element'>– Any unauthorized access to or use of our servers and/or any personal information stored therein.</div>
          <div className='sub-element'>– Any interruption or cessation of transmission to or from the Website.</div>
          <b>Changes to the Terms</b>
          <p>HastkalaPramanak reserves the right to revise and update these Terms at any time. All changes are effective immediately upon posting. It is your responsibility to review these Terms periodically for any updates.</p>
          <b>Governing Law</b>
          <p>These Terms are governed by and construed in accordance with the laws of Varanasi. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in [your jurisdiction].</p>
          <b>Contact Us</b>
          <p>If you have any questions or concerns about these Terms, please contact us at kunal@Hastkalapramanak.in</p>

        </div>
      </Container>
      <Footer />
    </>
  )
}

export default TermsAndConditions
