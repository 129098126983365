import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import Loader from "../../common/Components/Loader/Loader";
import ShowIcon from "../../common/Components/Icons/ShowIcon";
import HideIcon from "../../common/Components/Icons/HideIcon";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import notifications from "../../common/Components/Notifications/Notification";

// store
import {
  resetPasswordPostLogin,
  forgetPasswordPostLogin,
} from "../../Store/auth/login/actions";
// images
import LogoBanner from "../../assets/images/login.png";
const ForgetPassword = (props: any) => {
  const history: any = useHistory();
  console.log("hi", history?.location?.state?.emailOrPhone);
  const [loading, setLoading]: any = useState(false);
  const [form, setForm]: any = useState({
    emailOrPhone: history?.location?.state?.emailOrPhone,
    otp: "",
    newPswd: "",
  });
  const [showNewPswd, setShowNewPswd]: any = useState(false);

  const handleSetShowNewPswd = (value: any) => {
    setShowNewPswd(value);
  };
  const [error, setError]: any = useState("");
  console.log("histo", history);
  const validation = () => {
    let error = "";
    // if (emailOrPhone.trim() === "") {
    //   error = "required!";
    //   setEmailOrPhone(() => "EMail or phone is required!");
    // }
    // else if (emailOrPhone.test("^[w-.]+@([w-]+.)+[w-]{2,4}$")) {
    //   setEmailOrPhone(() => "Invalid email!");
    // } else if (emailOrPhone.test(/^[7-9][0-9]{9}$/)) {
    //   setEmailOrPhone(() => "Invalid phone!");
    // }
    return error;
  };
  const resendPassword = () => {
    props.forgetPasswordPostLogin(
      {
        emailorphone: form?.emailOrPhone,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const handleSubmit = () => {
    const result: any = validation();
    if (result?.length === 0) {
      // setLoading(true);
      props.resetPasswordPostLogin(
        {
          emailorphone: form?.emailOrPhone,
          verificationCode: form?.otp,
          newPassword: form?.newPswd,
        },
        (res: any) => {
          setLoading(false);
          if (res.success) {
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
            history.push("/login");
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        }
      );
    }
  };
  return (
    <>
      <Grid container spacing={0} className="LoginpageNew ">
        <Grid item xs={12} md={6} lg={6} className="LoginpageGrid ">
          <>
            {loading ? (
              <div className="LoaderDiv1">
                <Loader />
              </div>
            ) : (
              <div>
                <div className="parentForm resetPswd">
                  <div className="MainHeading">
                    <span className="sp1">Hastkala</span>{" "}
                    <span className="sp2">Paramanak</span>
                    <p>Supporting the makers, Protecting the buyers</p>
                  </div>

                  <h4>Reset Password</h4>
                  <div className="enterEmail">
                    Enter your email or phone to change the password.
                  </div>
                  {/* {response === "User does not   Exist!" ? (
                    <>
                      <div className="responseMsg">
                        Incorrect email or phone number or User does not exist's
                      </div>
                    </>
                  ) : response === "Authentication failed!" ? (
                    <>
                      <div className="responseMsg">Incorrect password</div>
                    </>
                  ) : (
                    <>
                      <div className="responseMsgSucces">{response}</div>
                    </>
                  )} */}
                  <form>
                    <label className="labelName">
                      Email Or Phone<span>*</span>
                    </label>
                    <br />
                    <input
                      placeholder="Enter Email Or Phone"
                      type="text"
                      id="emaillogin"
                      name="emaillogin"
                      className="inputField"
                      value={form?.emailOrPhone}
                      readOnly
                    // onChange={(e: any) => setEmailOrPhone(e.target.value)}
                    />
                    <br />
                    {/* {Errors.email && !value.email ? (
                    <p className="RequiredField">{Errors?.email} </p>
                  ) : (
                    ""
                  )} */}
                    <br />
                    <div className="formDiv">
                      <label className="labelName">
                        Otp<span>*</span>
                      </label>
                      <br />
                      <input
                        placeholder="Enter Otp"
                        type="text"
                        className="inputField"
                        value={form?.otp}
                        onChange={(e: any) =>
                          setForm(() => ({ ...form, otp: e.target.value }))
                        }
                      />
                      <br />
                      <span
                        className="resend"
                        onClick={() => {
                          resendPassword();
                        }}
                      >
                        {" "}
                        Resend
                      </span>
                      <br />
                    </div>
                    <div className="formDiv">
                      <br />
                      <label className="labelName">
                        New Password<span>*</span>
                      </label>
                      <br />
                      <input
                        name="newPswd"
                        placeholder="Enter new password"
                        type={showNewPswd ? "text" : "password"}
                        className="inputField"
                        value={form?.newPswd}
                        onChange={(e: any) =>
                          setForm(() => ({ ...form, newPswd: e.target.value }))
                        }
                      />
                      <div className="icon">
                        {showNewPswd ? (
                          <HideIcon
                            onClick={() => handleSetShowNewPswd(false)}
                          />
                        ) : (
                          <ShowIcon
                            onClick={() => handleSetShowNewPswd(true)}
                          />
                        )}
                      </div>
                    </div>
                    <br />

                    <Button className="loginBtn" onClick={() => handleSubmit()}>
                      {loading ? "Proccessing" : "Submit"}
                    </Button>
                  </form>
                </div>
              </div>
            )}
          </>
        </Grid>
        <Grid item xs={1} md={6} lg={6} className="displayDesktop">
          <img
            className="logoBanner"
            src={LogoBanner}
            width="100%"
            height={"900px"}
            alt="LogoBanner"
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  resetPasswordPostLogin,
  forgetPasswordPostLogin,
})(ForgetPassword);
