import { getHeaders } from "./global_headers";
import jwt from "jwt-decode";
import notifications from "../common/Components/Notifications/Notification";
import { useHistory } from "react-router-dom";
// Gets the logged in user data from local session

const getLoggedInUser: any = () => {
  const user = localStorage.getItem("authUser");
  if (user) return { user: JSON.parse(user) };
  return null;
};
const getUserToken = () => {
  let token = localStorage.getItem("token");
  token = token || null;
  return token;
};
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser().user !== null;
};
const postApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response.json();
    })
    .then((json) => {
      return json;
    });
};

const patchApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters: any = {
    method: "PATCH",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };
  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};
const putApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters: any = {
    method: "PUT",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response.json();
    })
    .then((json) => {
      return json;
    });
};
const getApi = (url: any) => {
  let headers = getHeaders();
  return fetch(url, { headers })
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response.json();
    })
    .then((json) => {
      return json;
    });
};
const getApiForProductCertification = (url: any) => {
  let token: any = localStorage.getItem("productVerificationToken");
  token = token || null;
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return fetch(url, { headers })
    .then((response) => {
      const result = tokenValidatorForProductCertification(response);
      return result;
    })
    .then((json) => {
      return json;
    });
};
const deleteApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters = {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify(values),
  };
  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // // if (result) return response.json();
      return result;
      // return response.json()
    })
    .then((json) => {
      return json;
    });
};
const putUploadImagesApi = (url: any, values: any, headers: any) => {
  const parameters = {
    method: "PUT",
    headers: headers,
    //  mode: "cors", // or without this line
    // redirect: "follow",
    body: values,
  };

  return fetch(url, parameters)
    .then((response) => {
      // const result = tokenValidator(response);
      // return result;
      return response;
    })
    .then((json) => {
      return json;
    });
};
const getApiForPhoneOtp = (url: any) => {
  // let headers = getHeaders();
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};
const postApiforOtp = (url: any, values?: any) => {
  // let headers = getHeaders();
  // const myHeaders = {
  //   authkey: "370481Aq8yhs5eJ7461b06d95P1"
  // };
  const parameters = {
    method: "POST",
    // headers: myHeaders,
    // body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      // const result = tokenValidator(response);
      // return result;
      return response.json();
    })
    .then((json) => {
      return json;
    });
};
const tokenValidatorForProductCertification = async (response: any) => {
  let responseJson = await response.json();
  // if (
  //   responseJson.status === 401 ||
  //   responseJson.message === "UnAuthorized!" ||
  //   responseJson.message === "Unauthorized!"
  // ) {
  //   // localStorage.setItem("responseForProduct", JSON.stringify(responseJson));
  //   // let newTokenResponse = await getNewToken();
  //   // // localStorage.removeItem("token");
  //   // if (newTokenResponse.success === true) {
  //   //   localStorage.setItem(
  //   //     "productVerificationToken",
  //   //     newTokenResponse.data.token
  //   //   );
  //   //   localStorage.setItem(
  //   //     "productVerificationrefreshToken",
  //   //     newTokenResponse.data.refreshToken
  //   //   );
  //   //   window.location.reload();
  //   // }
  // } else
  return responseJson;
};
const tokenValidator = async (response: any) => {
  let responseJson = await response.json();
  if (
    // responseJson.status === 403 ||
    responseJson.message === "UnAuthorized!" ||
    responseJson.message === "Unauthorized!"
  ) {
    localStorage.setItem("response", JSON.stringify(responseJson));
    notifications({
      title: "Error!",
      message: "Token expired!. You have to relogin again.",
      type: "danger",
    });
    // localStorage.clear()
    // let newTokenResponse = await getNewToken();
    // // localStorage.removeItem("token");
    // if (newTokenResponse.success === true) {
    //   let authUser: any = jwt(newTokenResponse.data.token);
    //   localStorage.setItem("authUser", JSON.stringify(authUser));
    //   localStorage.setItem("token", newTokenResponse.data.token);
    //   localStorage.setItem("refreshToken", newTokenResponse.data.refreshToken);
    // }
    // localStorage.removeItem("authUser");
    // window.location.href = "/login";
  } else if (response.status == 403 || response.statusText == "Forbidden") {
    localStorage.setItem("response", JSON.stringify(responseJson));
    window.location.href = "/pages-401";
    // localStorage.clear()
  } else return responseJson;
};

const getNewToken = async () => {
  let refreshToken = localStorage.getItem("productVerificationrefreshToken");
  let uri =
    process.env.REACT_APP_SERVERURL + `/auth/accessTokenUsingRefreshToken`;
  let refreshTokenPayload = {
    refreshToken: refreshToken,
  };
  let response = await postApi(uri, refreshTokenPayload);
  return response;
};

export {
  postApi,
  patchApi,
  deleteApi,
  getApi,
  putApi,
  putUploadImagesApi,
  getUserToken,
  isUserAuthenticated,
  getApiForPhoneOtp,
  postApiforOtp,
  getApiForProductCertification,
};
