import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import { postApi, getApi, deleteApi, putApi } from "../../helpers/auth_helpers";

// workers
function* workerGetusers({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let userId = filters?.userId ? filters?.userId : "";
  let usersName = filters?.usersName ? filters?.usersName : "";
  let role = filters?.role ? filters?.role : "";
  let phone = filters?.phone ? filters?.phone : "";
  let email = filters?.email ? filters?.email : "";
  let sortBy = filters?.sortBy ? filters?.sortBy : "createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy : "-1";
  let organizationId = filters?.organizationId ? filters?.organizationId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/user/users?offset=" +
      offset +
      "&limit=" +
      limit +
      "&id=" +
      userId +
      "&name=" +
      usersName +
      "&role=" +
      role +
      "&phone=" +
      phone +
      "&email=" +
      email +
      "&OrganizationId=" +
      organizationId +
      "&sortBy=" +
      sortBy +
      "&orderBy=" +
      orderBy;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetusersLogoProfile({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let userId = filters?.userId ? filters?.userId : "";
  let role = filters?.role ? filters?.role : "";

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/user/users?offset=" +
      offset +
      "&limit=" +
      limit +
      "&id=" +
      userId +
      "&role=" +
      role;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_USERS_LOGO_PROFILE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateusers({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerRegisterusers({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/register";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.REGISTER_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateusers({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateCompanyProfile({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/user/updateCompanyDetails";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_COMPANY_PROFILE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteusers({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    yield put({
      type: types.DELETE_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetuserRoles({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let roleId = filters?.roleId ? filters?.roleId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/user/roles?offset=" +
      offset +
      "&limit=" +
      limit +
      "&id=" +
      roleId;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_ROLES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerChangeUserStatus({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/user/toggleActiveStatus";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.CHANGE_USER_STATUS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateuserRole({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/createRole";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_ROLE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchGetusers() {
  yield takeEvery(types.GET_USERS, workerGetusers);
}
export function* watchGetusersLogoProfile() {
  yield takeEvery(types.GET_USERS_LOGO_PROFILE, workerGetusersLogoProfile);
}
export function* watchCreateusers() {
  yield takeEvery(types.CREATE_USERS, workerCreateusers);
}
export function* watchRegisteruser() {
  yield takeEvery(types.REGISTER_USERS, workerRegisterusers);
}
export function* watchUpdateusers() {
  yield takeEvery(types.UPDATE_USERS, workerUpdateusers);
}
export function* watchUpdateCompanyProfile() {
  yield takeEvery(types.UPDATE_COMPANY_PROFILE, workerUpdateCompanyProfile);
}
export function* watchDeleteusers() {
  yield takeEvery(types.DELETE_USERS, workerDeleteusers);
}
export function* watchGetuserRoles() {
  yield takeEvery(types.GET_ROLES, workerGetuserRoles);
}
export function* watchChangeUserStatus() {
  yield takeEvery(types.CHANGE_USER_STATUS, workerChangeUserStatus);
}
export function* watchCreateRole() {
  yield takeEvery(types.CREATE_ROLE, workerCreateuserRole);
}
function* usersSaga() {
  yield all([fork(watchGetusers)]);
  yield all([fork(watchGetusersLogoProfile)]);
  yield all([fork(watchCreateusers)]);
  yield all([fork(watchRegisteruser)]);
  yield all([fork(watchUpdateusers)]);
  yield all([fork(watchDeleteusers)]);
  yield all([fork(watchGetuserRoles)]);
  yield all([fork(watchUpdateCompanyProfile)]);
  yield all([fork(watchChangeUserStatus)]);
  yield all([fork(watchCreateRole)]);
}
export default usersSaga;
