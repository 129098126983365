import React from "react";

const QRDesignIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30"
        cy="30"
        r="30"
        transform="rotate(90 30 30)"
        fill="#C59C6C"
      />
      <path
        d="M29.2344 43.0001C28.8011 42.9324 28.3679 42.885 27.9347 42.8037C25.3218 42.3162 23.1422 41.0569 21.4025 39.0595C19.8118 37.2313 18.9048 35.0985 18.6408 32.6949C18.5189 31.5709 18.5663 30.447 18.7965 29.3366C19.0605 28.0704 20.2653 27.373 21.6395 27.6303C22.6887 27.8334 23.677 27.6912 24.5434 27.0209C24.9969 26.6688 25.3083 26.2152 25.5655 25.7074C25.6264 25.5855 25.6806 25.4636 25.7415 25.335C26.9193 25.3688 28.0904 25.3959 29.2817 25.4298C29.2479 26.2084 29.4984 26.872 30.1008 27.3798C30.5476 27.759 31.062 27.9282 31.6442 27.9147C32.9167 27.8876 33.6952 27.0006 33.8576 25.4027C35.5973 25.2673 37.3437 25.1251 39.1578 24.9829C39.3947 25.3756 39.679 25.8225 39.9295 26.2897C40.8907 28.0636 41.4661 29.9527 41.4458 31.984C41.4187 34.4824 40.5387 36.6761 38.9818 38.6058C37.2896 40.7116 35.1235 42.0928 32.4835 42.709C31.9758 42.8241 31.4479 42.8715 30.9334 42.9527C30.8657 42.9662 30.8048 42.9798 30.7371 43.0001C30.2497 43.0001 29.742 43.0001 29.2344 43.0001ZM33.6004 29.9527C33.6072 31.2256 34.6361 32.2345 35.9019 32.2277C37.1542 32.2209 38.1763 31.1715 38.1628 29.9053C38.1492 28.6595 37.1203 27.6506 35.8545 27.6574C34.609 27.6709 33.5936 28.7001 33.6004 29.9527ZM28.4085 35.1256C27.1563 35.1256 26.1409 36.1548 26.1409 37.4074C26.1409 38.6735 27.1698 39.6959 28.4356 39.6959C29.6947 39.6892 30.7168 38.6532 30.71 37.3871C30.6965 36.1345 29.6676 35.1256 28.4085 35.1256ZM24.1102 35.3829C25.3692 35.3829 26.3981 34.3673 26.3981 33.1215C26.3981 31.8485 25.3828 30.8194 24.1237 30.8194C22.8647 30.8194 21.829 31.8485 21.829 33.1079C21.829 34.3605 22.8511 35.3829 24.1102 35.3829ZM34.3112 33.5548C33.0521 33.5548 32.03 34.5569 32.0232 35.8162C32.0165 37.0892 33.0318 38.1183 34.2976 38.1183C35.5567 38.1251 36.5923 37.0824 36.5923 35.823C36.5856 34.5704 35.5702 33.5548 34.3112 33.5548Z"
        fill="white"
      />
      <path
        d="M17.0022 17C17.5031 17 17.9972 17 18.4981 17C18.532 17.1625 18.5523 17.3115 18.5997 17.4536C18.8095 18.1172 19.4255 18.5641 20.1295 18.5776C20.7861 18.5911 21.4562 18.5437 22.106 18.6318C23.6697 18.8417 24.7866 20.2839 24.6647 21.8615C24.5497 23.4188 23.2229 24.6578 21.6593 24.6714C19.351 24.6917 17.3812 22.9922 17.0495 20.7036C17.0428 20.6562 17.0225 20.6089 17.0022 20.5615C17.0022 19.3698 17.0022 18.1849 17.0022 17Z"
        fill="white"
      />
      <path
        d="M29.2478 23.9063C29.2478 22.3761 29.2478 20.873 29.2478 19.3563C29.3426 19.3495 29.4238 19.3428 29.505 19.3428C31.8606 19.4105 34.2231 19.4443 36.5787 19.5662C38.183 19.6475 39.7805 19.8303 41.3238 20.3178C41.7232 20.4396 42.1226 20.6157 42.4678 20.8391C43.1785 21.2792 43.1718 21.9428 42.4813 22.4167C41.8315 22.8568 41.0801 23.0396 40.3288 23.1954C38.5553 23.5745 36.7479 23.7167 34.9406 23.7912C33.14 23.8657 31.3394 23.8725 29.5389 23.9063C29.4441 23.9063 29.3493 23.9063 29.2478 23.9063Z"
        fill="white"
      />
      <path
        d="M25.6327 23.8316C26.3841 21.6717 26.3841 21.4686 25.6327 19.4238C26.3299 19.4238 27.0068 19.4238 27.6973 19.4238C27.6973 20.8796 27.6973 22.3624 27.6973 23.8316C27.0136 23.8316 26.3367 23.8316 25.6327 23.8316Z"
        fill="white"
      />
      <path
        d="M30.859 25.4297C31.3261 25.4297 31.7932 25.4297 32.2535 25.4297C32.3821 25.687 32.3279 25.9646 32.1113 26.1813C31.8676 26.4182 31.4953 26.4656 31.2043 26.2964C30.8997 26.1271 30.771 25.8089 30.859 25.4297Z"
        fill="white"
      />
      <path
        d="M35.1233 29.9527C35.1233 29.5329 35.455 29.1944 35.8679 29.1876C36.2808 29.1808 36.6328 29.5194 36.6396 29.9256C36.6463 30.3454 36.2943 30.711 35.8746 30.7043C35.4617 30.7043 35.1233 30.3657 35.1233 29.9527Z"
        fill="white"
      />
      <path
        d="M28.4147 38.1725C28.0085 38.1725 27.6633 37.8272 27.6633 37.4142C27.6633 37.0012 27.995 36.6626 28.4147 36.6559C28.8411 36.6491 29.1864 37.0012 29.1796 37.4277C29.1796 37.8272 28.8276 38.1725 28.4147 38.1725Z"
        fill="white"
      />
      <path
        d="M24.8681 33.1011C24.8681 33.5209 24.5296 33.8595 24.1167 33.8595C23.697 33.8595 23.3518 33.5209 23.3518 33.1079C23.3518 32.6813 23.7038 32.3293 24.1235 32.336C24.5431 32.3496 24.8748 32.6881 24.8681 33.1011Z"
        fill="white"
      />
      <path
        d="M34.3179 35.0782C34.7376 35.0782 35.076 35.4235 35.0692 35.8366C35.0692 36.2496 34.724 36.5949 34.3111 36.5949C33.8846 36.5949 33.5462 36.2496 33.553 35.8162C33.553 35.3965 33.8914 35.0715 34.3179 35.0782Z"
        fill="white"
      />
    </svg>
  );
};

export default QRDesignIcon;
