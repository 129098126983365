import React from 'react'
import { Grid } from "@mui/material";
import CountUp from 'react-countup';
const ImpactStackHolderCardDetails = (props:any) => {
    const { data } = props
    return (
        <Grid container spacing={4}>
            {data.map((ele:any, i:any) => (
                <Grid item xs={6} key={i}>
                    <div className='Polymaker-Number'>
                        {
                            (ele?.content === "Counterfeit sold in India" ||
                                ele?.content === "Weavers" ||
                                ele?.content === "Women weavers") ?
                                <CountUp
                                    start={ele?.starts}
                                    end={ele?.value}
                                    duration={2.75}
                                    // separator="."
                                    decimals={1}
                                    decimal={"."}
                                    prefix={ele?.currency + " "}
                                    suffix={" " + ele?.end}

                                /> : <CountUp
                                    start={ele?.starts}
                                    end={ele?.value}
                                    duration={2.75}
                                    prefix={ele?.currency + " "}
                                    suffix={" " + ele?.end}

                                />

                        }


                    </div>

                    <p className='Polymaker-content'>{ele?.content} </p>

                </Grid>
            ))}
        </Grid>
    )
}

export default ImpactStackHolderCardDetails