import { types } from "./actionTypes";

// creating action
export const getProducts = (filters: any, callback: any) => {
  return {
    type: types.GET_PRODUCTS,
    filters,
    callback,
  };
};
export const getProductById = (filters: any, callback: any) => {
  return {
    type: types.GET_PRODUCTS_BY_ID,
    filters,
    callback,
  };
};
export const createProduct = (payload: any, callback: any) => {
  return {
    type: types.CREATE_PRODUCTS,
    payload,
    callback,
  };
};
export const updateProducts = (payload: any, callback: any) => {
  return {
    type: types.UPDATE_PRODUCTS,
    payload,
    callback,
  };
};
export const deleteProducts = (payload: any, callback: any) => {
  return {
    type: types.DELETE_PRODUCTS,
    payload,
    callback,
  };
};
export const productVerifyOtp = (payload: any, callback: any) => {
  return {
    type: types.PRODUCT_VERIFY_OTP,
    payload,
    callback,
  };
};
export const productSendOtp = (payload: any, callback: any) => {
  return {
    type: types.SEND_OTP,
    payload,
    callback,
  };
};
export const productReSendOtp = (payload: any, callback: any) => {
  return {
    type: types.PRODUCT_RESEND_OTP,
    payload,
    callback,
  };
};
export const productKyc = (payload: any, callback: any) => {
  return {
    type: types.PRODUCT_KYC,
    payload,
    callback,
  };
};
export const createVariantForProduct = (payload: any, callback: any) => {
  return {
    type: types.CREATE_PRODUCT_VARIANT,
    payload,
    callback,
  };
};
export const changeProductStatus = (payload: any, callback: any) => {
  return {
    type: types.CHANGE_PRODUCT_STATUS,
    payload,
    callback,
  };
};
export const getProductByIdProductCertification = (
  filters: any,
  callback: any
) => {
  return {
    type: types.GET_PRODUCTS_BY_ID_PRODUCT_CERTIFICATION,
    filters,
    callback,
  };
};
export const getProductByIdAfterKycToken = (filters: any, callback: any) => {
  return {
    type: types.GET_PRODUCT_BY_ID_AFTER_KYC_TOKEN,
    filters,
    callback,
  };
};
export const createFakeAlertDetails = (payload: any, callback: any) => {
  return {
    type: types.COLLECT_FAKE_ALERT_DETAILS,
    payload,
    callback,
  };
};
export const productTransfer = (payload: any, callback: any) => {
  return {
    type: types.TRANSFERR_PRODUCT,
    payload,
    callback,
  };
};
export const addNfcTagForProduct = (payload: any, callback: any) => {
  return {
    type: types.ADD_NFC_TAG,
    payload,
    callback,
  };
};
export const undoTransferProduct = (payload: any, callback: any) => {
  return {
    type: types.UNDO_TRANSFERED_PRODUCT,
    payload,
    callback,
  };
};
