import React from "react";

const CardIcon = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.25 5.00195H3.75C3.05964 5.00195 2.5 5.5616 2.5 6.25195V23.752C2.5 24.4423 3.05964 25.002 3.75 25.002H26.25C26.9404 25.002 27.5 24.4423 27.5 23.752V6.25195C27.5 5.5616 26.9404 5.00195 26.25 5.00195Z"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 15.627C12.0057 15.627 13.125 14.5076 13.125 13.127C13.125 11.7463 12.0057 10.627 10.625 10.627C9.24431 10.627 8.125 11.7463 8.125 13.127C8.125 14.5076 9.24431 15.627 10.625 15.627Z"
        fill="#C59C6C"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 19.377C14.375 17.3059 12.6961 15.627 10.625 15.627C8.55394 15.627 6.875 17.3059 6.875 19.377"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 12.502H22.5"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 17.502H22.5"
        stroke="#404B3B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
