import React from 'react'
import { Grid } from '@mui/material';
import ReactPlayer from "react-player/file";
const WhyHastakalaRight = (props: any) => {
    const { data,value } = props;
    return (
        <div className='why-hastakala-right' key={value}>
            <Grid container>
                <Grid xs={12} sm={6} item>
                    {
                        data?.type == "image" ?
                            <img src={data?.image}
                                style={{
                                    borderRadius: data?.borderRadius
                                }}
                                width="100%" height="100%" alt="why hastakala" /> :
                            <ReactPlayer
                                 className="react-player-div"
                                url={data?.image}
                                playing={true}
                                controls={true}
                                loop={true}
                                muted={true}

                                width={"100%"}
                                height={"500px"}
                            />
                    }

                </Grid>
                <Grid item xs={12} sm={6} className='Content'>
                    <h2>{data?.title}</h2>
                    <p>{data?.des}</p>
                </Grid>

            </Grid>
        </div>
    )
}

export default WhyHastakalaRight