export const types = {
  CREATE_USERS: "CREATE_USERS",
  CREATE_USERS_SUCCESS: "CREATE_USERS_SUCCESS",
  GET_USERS: "GET_USERS",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  UPDATE_USERS: "UPDATE_USERS",
  UPDATE_USERS_SUCCESS: "UPDATE_USERS_SUCCESS",
  UPDATE_COMPANY_PROFILE: "UPDATE_COMPANY_PROFILE",
  UPDATE_COMPANY_PROFILE_SUCCESS: "UPDATE_COMPANY_PROFILE_SUCCESS",
  DELETE_USERS: "DELETE_USERS",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  GET_ROLES: "GET_ROLES",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  REGISTER_USERS: "REGISTER_USERS",
  REGISTER_USERS_SUCCESS: "REGISTER_USERS_SUCCESS",
  GET_USERS_LOGO_PROFILE: "GET_USERS_LOGO_PROFILE",
  GET_USERS_LOGO_PROFILE_SUCCESS: "GET_USERS_LOGO_PROFILE_SUCCESS",
  CHANGE_USER_STATUS: "CHANGE_USER_STATUS",
  CHANGE_USER_STATUS_SUCCESS: "CHANGE_USER_STATUS_SUCCESS",
  CREATE_ROLE: "CREATE_ROLE",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
};
