import React, { useState, useEffect } from "react";
import { Label, Input, Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
//components
import Loader from "../../common/Components/Loader/Loader";
// actions
import { getUsers } from "../../Store/users/actions";

var organisationOption: any;
var userRolesOptions: any;
var artformOptions: any;
const ViewUser = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const UserId = location?.pathname.split("/")[2];
  const [loading, setLoading]: any = useState(false);
  const [form, setForm]: any = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    state: "",
    pinCode: "",
    adharCard: "",
    panCard: "",
    artform: "",
    role: "",
    organization: "",
    domain: "",
    artisanCard: "",
    profileURL: "",
  });

  useEffect(() => {
    getUserDataById();
  }, []);
  const getUserDataById = () => {
    setLoading(true);
    props.getUsers(
      {
        userId: UserId,
      },
      (res: any) => {
        setLoading(false);
        let editDetails: any = res?.data?.docs[0];
        let formOrganisationDetails: any = [];
        if (editDetails?.businessRoleDetails?.length) {
          if (editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND") {
            editDetails?.organizationDetails.forEach((ele: any) => {
              formOrganisationDetails.push(ele?.name);
            });
          }
        }
        setForm({
          name: editDetails?.name,
          phone: editDetails?.phone,
          email: editDetails?.email,
          address: editDetails?.address?.addressLane,
          state: editDetails?.address?.state,
          pinCode: editDetails?.address?.pinCode,
          adharCard: editDetails?.aadharCard,
          panCard: editDetails?.panCard,
          artform: editDetails?.businessDetails?.length
            ? editDetails?.businessDetails?.art
            : "",
          role: editDetails?.businessRoleDetails?.length
            ? editDetails?.businessRoleDetails[0]?.role
            : "",
          organization: editDetails?.businessRoleDetails?.length
            ? editDetails?.businessRoleDetails[0]?.role === "NAQSEBAND"
              ? formOrganisationDetails
              : editDetails?.organizationDetails[0]?.name
            : "",
          domain: editDetails?.businessDetails?.domain,
          artisanCard: editDetails?.businessDetails?.artisanCard,
          profileURL: editDetails?.profileURL,
        });
      }
    );
  };
  return (
    <>
      {loading ? (
        <div className="LoaderDiv">
          <Loader />
        </div>
      ) : (
        <div className="createUser">
          <div className="form">
            <div className="UserDetailsDiv">
              <div className="UserHeader">User Details</div>
              <div className="userPd">
                <div className="d-flex justify-content-center">
                  <div className="imageDiv">
                    {form?.profileURL?.length ? (
                      <img
                        src={form?.profileURL}
                        alt="profile"
                        width="200px"
                        height={"200px"}
                      />
                    ) : (
                      <div>No Preview Available</div>
                    )}
                  </div>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Name</Label>
                      <Input
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Enter Name"
                        value={form?.name}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Phone</Label>
                      <Input
                        name="Phone"
                        id="Phone"
                        className="form-control"
                        placeholder="Enter Phone"
                        value={form?.phone}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Enter Email"
                        value={form?.email}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={8}>
                      <Label>Address</Label>
                      <Input
                        name="address"
                        id="address"
                        className="form-control"
                        placeholder="Enter Company Address"
                        value={form?.address}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>State</Label>
                      <Input
                        name="state"
                        id="state"
                        className="form-control"
                        placeholder="Enter Company Address"
                        value={form?.state}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Pin Code</Label>
                      <Input
                        name="pincode"
                        id="pincode"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.pincode}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Aadhaar Card</Label>
                      <Input
                        name="AdharCard"
                        id="AdharCard"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.adharCard}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Pan Card</Label>
                      <Input
                        name="PanCard"
                        id="PanCard"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.panCard}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="UserDetailsDiv mb-5">
              <div className="UserHeader">Business Details</div>
              <div className="userPd">
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Artform</Label>
                      <Input
                        name="artform"
                        id="artform"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.artform}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Role</Label>
                      <Input
                        className="form-control"
                        placeholder="Enter Number"
                        name="Role"
                        id="Role"
                        value={form?.role}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Organization</Label>
                      <Input
                        className="form-control"
                        placeholder="Enter Number"
                        name="Organization"
                        id="Organization"
                        value={
                          form?.role === "NAQSEBAND"
                            ? form?.organization.join(",")
                            : form?.organization
                        }
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
                <div className="formDiv">
                  <Row>
                    <Col xs={12} md={4}>
                      <Label>Domain</Label>
                      <Input
                        className="form-control"
                        placeholder="Enter Number"
                        name="Domain"
                        id="Domain"
                        value={form?.domain}
                        readOnly
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Label>Artisan Card</Label>
                      <Input
                        name="artisanCard"
                        id="artisanCard"
                        className="form-control"
                        placeholder="Enter Number"
                        value={form?.artisanCard}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    userDetails: state?.usersReducer?.getUsersDetails,
  };
};
export default connect(mapStateToProps, {
  getUsers,
})(ViewUser);
