import { types } from "./actionTypes";

// creating action
export const getOrganisation = (filters: any, callback: any) => {
  return {
    type: types.GET_ORGANISATION,
    filters,
    callback,
  };
};

export const createOrganisation = (payload: any, callback: any) => {
  return {
    type: types.CREATE_ORGANISATION,
    payload,
    callback,
  };
};
export const updateOrganisation = (payload: any, callback: any) => {
  return {
    type: types.UPDATE_ORGANISATION,
    payload,
    callback,
  };
};
export const deleteOrganisation = (payload: any, callback: any) => {
  return {
    type: types.DELETE_ORGANISATION,
    payload,
    callback,
  };
};
export const changeOrganisationStatus = (payload: any, callback: any) => {
  return {
    type: types.CHANGE_ORGANISATION_STATUS,
    payload,
    callback,
  };
};
export const connectOrg = (payload: any, callback: any) => {
  return {
    type: types.CONNECT_ORG,
    payload,
    callback,
  };
};
export const updateParty = (payload: any, callback: any) => {
  return {
    type: types.UPDATE_PARTY,
    payload,
    callback,
  };
};
export const getAssocciationsOrg = (filters: any, callback: any) => {
  return {
    type: types.GET_ASSOCIATIONS_ORG,
    filters,
    callback,
  };
};
export const activeOrDeactiveAss = (filters: any, callback: any) => {
  return {
    type: types.ACTIVE_DEACTIVE_ASSOCIATION,
    filters,
    callback,
  };
};
