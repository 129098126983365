export const types = {
  CREATE_ORGANISATION: "CREATE_ORGANISATION",
  CREATE_ORGANISATION_SUCCESS: "CREATE_ORGANISATION_SUCCESS",
  GET_ORGANISATION: "GET_ORGANISATION",
  GET_ORGANISATION_SUCCESS: "GET_ORGANISATION_SUCCESS",
  UPDATE_ORGANISATION: "UPDATE_ORGANISATION",
  UPDATE_ORGANISATION_SUCCESS: "UPDATE_ORGANISATION_SUCCESS",
  DELETE_ORGANISATION: "DELETE_ORGANISATION",
  DELETE_ORGANISATION_SUCCESS: "DELETE_ORGANISATION_SUCCESS",
  CHANGE_ORGANISATION_STATUS: "CHANGE_ORGANISATION_STATUS",
  CHANGE_ORGANISATION_STATUS_SUCCESS: "CHANGE_ORGANISATION_STATUS_SUCCESS",
  CONNECT_ORG: "CONNECT_ORG",
  CONNECT_ORG_SUCCESS: "CONNECT_ORG_SUCCESS",
  GET_ASSOCIATIONS_ORG: "GET_ASSOCIATIONS_ORG",
  GET_ASSOCIATIONS_ORG_SUCCESS: "GET_ASSOCIATIONS_ORG_SUCCESS",
  ACTIVE_DEACTIVE_ASSOCIATION: "ACTIVE_DEACTIVE_ASSOCIATION",
  ACTIVE_DEACTIVE_ASSOCIATION_SUCCESS: "ACTIVE_DEACTIVE_ASSOCIATION_SUCCESS",
  UPDATE_PARTY: "UPDATE_PARTY",
  UPDATE_PARTY_SUCCESS: "UPDATE_PARTY_SUCCESS",
};
