import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import { postApi, getApi, deleteApi, putApi } from "../../helpers/auth_helpers";

// workers
function* workerGetWeaveType({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let weaveTypeId = filters?.weaveTypeId ? filters?.weaveTypeId : "";
  let weaveTypeName = filters?.weaveTypeName ? filters?.weaveTypeName : "";
  let categoryId = filters?.categoryId ? filters?.categoryId : "";
  let subCategoryId = filters?.subCategoryId ? filters?.subCategoryId : "";
  let artformId = filters?.artformId ? filters?.artformId : "";
  let typeId = filters?.typeId ? filters?.typeId : "";
  let startDate = filters?.startDate ? filters?.startDate : "";
  let endDate = filters?.endDate ? filters?.endDate :"";
  let sortBy = filters?.sortBy ? filters?.sortBy :"createdAt";
  let orderBy = filters?.orderBy ? filters?.orderBy :"-1";
  let createdBy = filters?.createdBy ? filters?.createdBy :""

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/weaveType/weaveTypes?offset=" +
      offset +
      "&limit=" +
      limit +
      "&artFormId=" +
      artformId +
      "&typeId=" +
      typeId +
      "&categoryId=" +
      categoryId +
      "&weaveTypeId=" +
      weaveTypeId +
      "&label=" +
      weaveTypeName +
      "&subCategoryId=" +
      subCategoryId+
      "&sortBy="+
      sortBy+
      "&orderBy="+
      orderBy+
      "&startDate="+
      startDate+
      "&endDate="+
      endDate+
      "&createdBy="+
      createdBy;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_WEAVE_TYPE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetWeaveTypeFilter({ filters, callback }: any) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let weaveTypeName = filters?.weaveTypeName ? filters?.weaveTypeName : "";
  let categoryId = filters?.categoryId ? filters?.categoryId : "";
  let subCategoryId = filters?.subCategoryId ? filters?.subCategoryId : "";
  let artformId = filters?.artformId ? filters?.artformId : "";
  let typeId = filters?.typeId ? filters?.typeId : "";


  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/weaveType/weaveTypes?offset=" +
      offset +
      "&limit=" +
      limit +
      "&artFormId=" +
      artformId +
      "&typeId=" +
      typeId +
      "&categoryId=" +
      categoryId +
      "&label=" +
      weaveTypeName +
      "&subCategoryId=" +
      subCategoryId;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_WEAVE_TYPE_FILTER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateWeaveType({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/weaveType/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_WEAVE_TYPE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateWeaveType({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/weaveType/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_WEAVE_TYPE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteWeaveType({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/weaveType/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    yield put({
      type: types.DELETE_WEAVE_TYPE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchGetWeaveType() {
  yield takeEvery(types.GET_WEAVE_TYPE, workerGetWeaveType);
}
export function* watchGetWeaveTypeFilter() {
  yield takeEvery(types.GET_WEAVE_TYPE_FILTER, workerGetWeaveTypeFilter);
}
export function* watchCreateWeaveType() {
  yield takeEvery(types.CREATE_WEAVE_TYPE, workerCreateWeaveType);
}
export function* watchUpdateWeaveType() {
  yield takeEvery(types.UPDATE_WEAVE_TYPE, workerUpdateWeaveType);
}
export function* watchDeleteWeaveType() {
  yield takeEvery(types.DELETE_WEAVE_TYPE, workerDeleteWeaveType);
}

function* weaveTypeSaga() {
  yield all([fork(watchGetWeaveType)]);
  yield all([fork(watchGetWeaveTypeFilter)]);
  yield all([fork(watchCreateWeaveType)]);
  yield all([fork(watchUpdateWeaveType)]);
  yield all([fork(watchDeleteWeaveType)]);
}
export default weaveTypeSaga;
