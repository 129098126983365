import React from "react";
import CreateUser from "./CreateUser";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ModalCloseIcon from "../../common/Components/Icons/ModalCloseIcon";
const CreateUserModal = (props: any) => {
  const { openModal, closeModal, handleName, modalName } = props;

  return (
    <Modal
      isOpen={openModal}
      toggle={closeModal}
      size="lg"
      style={{
        maxWidth: "95vw",
        width: "100%",
      }}
      className="CreateUserModal"
      fade={false}
    >
      <ModalHeader>
        <div className="Heading">
          <h3>{"Add New User"}</h3>
          <ModalCloseIcon {...props} closeModal={closeModal} />
        </div>
      </ModalHeader>
      <ModalBody>
        <CreateUser
          {...props}
          closeModal={closeModal}
          handleName={handleName}
          modalName={modalName}
        />
      </ModalBody>
    </Modal>
  );
};

export default CreateUserModal;
