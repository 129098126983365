import React from "react";
import { ColorRing } from "react-loader-spinner";
const CircleLoader = () => {
  return (
    <ColorRing
      visible={true}
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={["#404b3b", "#c69c6d", "#404b3b", "#c69c6d", "#404b3b"]}
    />
  );
};

export default CircleLoader;
