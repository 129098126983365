import React, { useState } from 'react'
import { Container, Grid, Card, Button } from "@mui/material";
import Slider from "react-slick";
import ReactPlayer from "react-player/file";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//images
import first_video3 from "../assets/videos/front page 3_3.mp4";
import first_image1 from "../assets/images/front_page 1_3.jpg";
import first_image2 from "../assets/images/front_page 2_3.jpg";
const items = [
  {
    src: first_image1,
    altText: '',
    caption: '',
    key: 1,
    type: "image"
  },
  {
    src: first_image2,
    altText: '',
    caption: '',
    key: 2,
    type: "image"
  },
  {
    src: first_video3,
    altText: '',
    caption: '',
    key: 3,
    type: "video"
  },
];
const SupportingMakers = (props: any) => {
  var settings: any = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className='suporting-makers'>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className='supporting-header'>
                <h2>
                  Supporting the Makers,
                  Protecting the Buyers
                </h2>
                <p>An end to end solutions for objective verification of handmade products leveraging blockchain and artificial intelligence</p>
                <Button>Learn more</Button>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Slider {...props} {...settings}>
                {items.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      {item?.type === "image" ?
                        <img
                          style={{
                            padding: "10px"
                          }}
                          src={item.src} alt={item.altText} />
                        : <ReactPlayer
                          className="react-player-div"
                          url={item?.src}
                          playing={true}
                          loop={true}
                          controls={true}
                          muted={true}

                          width={"100%"}
                          height={"500px"}
                        />

                      }
                    </React.Fragment>
                  );
                })}
              </Slider>
            </Grid>

          </Grid>
        </Container>
      </div>
    </>
  )
}

export default SupportingMakers